// UTILS
// API
import BookingAPI from 'api/bookings'
import _ from 'lodash'
// ACTIONS
import { updateLocation } from './bookingsActionCreators'
// COMPONENTS
// CONSTANTS
import { LONG_HAUL } from 'constants/bookingConstants'
import { bookingsActionsCreator } from 'store/toolkit/bookings/bookings.reducer'
// ASSETS

const setUpForExtraRequirementPerLocation = (booking, extraRequirements) => (dispatch) => {
  const {
    locations_attributes: locations,
  } = booking

  const extraRequirementIds = _.map(extraRequirements, 'id')
  locations.forEach((location) => {
    let data = []
    const { extra_requirement_locations: extraRequirementLocations } = location

    if (!_.isEmpty(extraRequirementLocations)
      && _.includes(extraRequirementIds, extraRequirementLocations[0].extra_requirement_id)
    ) {
      data = extraRequirementLocations
    } else {
      extraRequirements.forEach((extraRequirement) => {
        const checkByDefault = extraRequirement.check_by_default
        const selectedAmount = checkByDefault ? 1 : extraRequirement.selected_amount

        if (extraRequirement.is_flat_per_location) {
          data.push({
            extra_requirement_id: extraRequirement.id,
            is_flat_per_location: extraRequirement.is_flat_per_location,
            name: extraRequirement.name,
            position: extraRequirement.position,
            selected_amount: selectedAmount,
            unit_price: extraRequirement.unit_price
          })
        }
      })
    }
    dispatch(updateLocation(
      booking.id,
      location.id,
      { extra_requirement_locations: data }
    ))
  })
}

export const setUpBookingLocationForExtraRequirementPerLocation = (booking, results) => (dispatch) => {
  const {
    locations_attributes: locations,
    time_type: timeType,
  } = booking
  const {
    extra_requirements: { data: extraRequirements = [] } = {},
  } = results


  if (timeType !== LONG_HAUL) {
    _.forEach(locations, (location) => {
      dispatch(updateLocation(
        booking.id,
        location.id,
        { extra_requirement_locations: [] }
      ))
    })
  } else if (!_.isEmpty(extraRequirements)) {
    dispatch(setUpForExtraRequirementPerLocation(booking, extraRequirements))
  }
}

const setPreVehicleTypeId = (bookingID, vehicleTypeId) => bookingsActionsCreator.setPrevSelectedServiceTypeIdAndVehicleTypeId({
  bookingID,
  vehicleTypeId
})

export const getExtraServices = booking => (dispatch, getState) => {
  const { currentCustomer, extraInfos, bookings: originalBookings } = getState()

  const preVehicleTypeId = booking.preVehicleTypeId || null
  const vehicleTypeId = booking.vehicle_type_id || null
  const validLocations = booking.locations_attributes.filter(l => !_.isUndefined(l.lat) || !_.isUndefined(l.lng))
  const isVehicleTypeChanged = vehicleTypeId !== preVehicleTypeId
  // keep data
  const preExtraServices = booking.extraServices

  if (isVehicleTypeChanged) {
    dispatch(setPreVehicleTypeId(booking.id, booking.vehicle_type_id))
  }

  return new Promise((resolve) => {
    BookingAPI.getExtraServices(
      currentCustomer.authentication_token,
      currentCustomer.currentCompanyId,
      booking.time_type,
      booking.service_type_id,
      booking.vehicle_type_id,
      validLocations.length,
      (results) => {
        _.assign(results, {
          preExtraServices,
          originalES: results.extra_requirements?.data?.map(obj => ({ ...obj })),
          originalBookings,
          isVehicleTypeChanged
        })
        Promise.all([
          dispatch(bookingsActionsCreator.receiveExtraServices({
            bookingID: booking.id,
            extraServices: results,
            bookAgain: extraInfos.use_data_again,
            timeType: booking.time_type,
            useDraftData: booking.useDraftData,
          })),
          dispatch(setUpBookingLocationForExtraRequirementPerLocation(booking, results)),
        ]).then(() => {
          resolve()
        })
      }
    )
  })
      
}
