import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import get from 'lodash/get'
import firebaseInit from '../../utils/firebaseInit'
import _ from 'lodash'
import $ from 'jquery'
// CONTANSTS
import {
  STATUS_DRIVER_ACCEPT_BOOKING,
  STATUS_CANCELLED,
  STATUS_CS_FINDING_DRIVER,
} from '../../constants/bookingConstants'
import I18n from 'i18n/i18n'
// ASSETS
import {
  DEVINA_ICON, ICON_WARNING_DEVINA, ICON_DONE_DEVINA, ICON_PARTIAL_LOAD_WHITE
} from '../../constants/imageConstants'
// COMPONENTS
import { Utils } from '../../utils/Utils'
import CountDown from './CountDown'

class DevinaMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      bookingsFindDriver: []
    }
    this.intervalListenFB = null
  }

  componentDidMount() {
    const accessToken = window.localStorage.getItem('access_token') || ''
    if (_.isEmpty(accessToken)) {
      const self = this
      let countLoop = 0
      this.intervalListenFB = setInterval(() => {
        const hasToken = !!window.localStorage.getItem('access_token')
        if (hasToken || countLoop > 10) {
          clearInterval(this.intervalListenFB)
          self.listenFirebase()
        }
        countLoop += 1
      }, 1000)
    } else {
      this.listenFirebase()
    }
  }

  componentWillUnmount() {
    const { firebaseClient } = this.state
    if (firebaseClient) {
      firebaseClient.off()
    }
    clearInterval(this.intervalListenFB)
  }

  getBooking(valFirebase) {
    const isBookingOfAccount = this.checkIsBookingOfAccount(valFirebase)

    if (!isBookingOfAccount) return
    this.updateNewBooking(valFirebase)
  }

  updateNewBooking(bookingFromFirebase) {
    const valFirebase = { ...bookingFromFirebase }
    this.setState((prevState) => {
      const listOldBooking = prevState.bookingsFindDriver
      const newBookings = [...listOldBooking]
      const index = listOldBooking.findIndex(booking => booking.id === valFirebase.id)
      valFirebase.status = STATUS_CS_FINDING_DRIVER
      if (index !== -1) {
        newBookings[index] = { ...valFirebase }
        return { bookingsFindDriver: newBookings }
      }
      return { bookingsFindDriver: [...newBookings, valFirebase] }
    })
  }

  paramToFTL(booking) {
    const { currentCustomer } = this.props
    const countryCodeOfBooking = _.get(booking, 'country_code', '').toLowerCase()
    const areaId = _.get(booking, 'area_id', '')
    const currentCompanyId = currentCustomer.currentCompanyId
    const idUser = currentCustomer.id
    const companyId = currentCompanyId || idUser
    return Utils.buildParamToFTL({
      countryCode: countryCodeOfBooking,
      areaId,
      companyId,
    })
  }

  checkIsBookingOfAccount(fbData) {
    const { currentCustomer } = this.props
    const currentCompanyId = currentCustomer.currentCompanyId
    const bookingCompanyId = fbData.company_id
    return (
      (!currentCompanyId && !bookingCompanyId)
      || (currentCompanyId && bookingCompanyId && currentCompanyId === bookingCompanyId)
    )
  }

  goToBookingDetail(booking) {
    this.funcName = 'goToBookingDetail'
    $('#loading-modal').addClass('visible')
    window.location = `/bookings/${booking.id}`
  }

  async listenFirebase() {
    const { currentCustomer } = this.props
    const firebaseChannel = `customers/${currentCustomer.id}/cs_finding_driver_bookings`
    await firebaseInit.load()
    const firebaseClient = firebaseInit.listen(firebaseChannel)
    this.setState({ firebaseClient })
    firebaseClient.on('child_removed', (data) => {
      const fbData = data.val()
      const isBookingOfAccount = this.checkIsBookingOfAccount(fbData)
      if (isBookingOfAccount) {
        this.setState((prevState) => {
          const listOldBooking = prevState.bookingsFindDriver
          const newList = _.filter(listOldBooking, booking => booking.id !== fbData.id)
          return { bookingsFindDriver: newList }
        })
      }
    })
    firebaseClient.on('child_added', (data) => {
      this.getBooking(data.val())
    })

    firebaseClient.on('child_changed', (data) => {
      this.getBooking(data.val())
    })
  }

  renderCountDown(booking) {
    this.funcName = 'renderCountDown'
    switch (booking.status) {
      case STATUS_CS_FINDING_DRIVER: {
        let timeout = booking.cs_finding_driver_timeout_at
          ? booking.cs_finding_driver_timeout_at
          : 0
        timeout = /^\d+$/.test(timeout) ? timeout : moment(timeout).unix()
        return (
          <div className="Dropdown-Devina-Time text-center">
            <CountDown eventTime={timeout} />
            <span>
              {I18n.t('webapp.devina.popup.time_left')}
            </span>
          </div>
        )
      }
      case STATUS_DRIVER_ACCEPT_BOOKING: {
        return (
          <div className="Dropdown-Devina-Time">
            <img src={ICON_DONE_DEVINA} alt="" />
          </div>
        )
      }
      case STATUS_CANCELLED: {
        return (
          <div className="Dropdown-Devina-Time">
            <img src={ICON_WARNING_DEVINA} alt="" />
          </div>
        )
      }
      default:
        return (
          <div />
        )
    }
  }

  render() {
    const { bookingsFindDriver } = this.state
    const { devinaName } = this.props
    if (bookingsFindDriver.length === 0) {
      return (<div />)
    }
    return (
      <div className="Dropdown">
        <div className="Dropdown-Close" />
        <div className="Dropdown-Head">
          <div className="Dropdown-Head-Item mr5">
            <div className="circle Avatar">
              <img src={DEVINA_ICON} alt="Devina Icon" role="Devina Icon" />
            </div>
          </div>
          <div className="TitleSubtitle">
            <div className="TitleSubtitle-title">
              {devinaName}
            </div>
            <div className="TitleSubtitle-subtitle">
              {I18n.t('webapp.devina.yellow_bar.finding_drivers')}
            </div>
          </div>
          <div className="Dropdown-Head-Caret">
            <i className="xb material-icons Icon">
              arrow_drop_down
            </i>
          </div>
        </div>
        <div className="right top Dropdown-Menu">
          {bookingsFindDriver.map((booking) => {
            const bookingId = booking.id
            const activeIcon = booking.service_type_icon
            const showBookingId = bookingId
            return (
              <div className="Dropdown-Menu-Item" key={showBookingId} onClick={() => this.goToBookingDetail(booking)}>
                <img src={activeIcon} alt="role" role="role" />
                <div className="Dropdown-Devina-Group">
                  <b>
                    {I18n.t('webapp.tracking_sharing.comming_notify_to_recipient_push_title', { booking_id: showBookingId })}
                  </b>
                  <span>
                    {booking.pickup_location}
                  </span>
                </div>
                {this.renderCountDown(booking)}
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

DevinaMenu.propTypes = {
  currentCustomer: PropTypes.shape({}).isRequired,
  devinaName: PropTypes.string
}

export default DevinaMenu
