import get from 'lodash/get';
import { Client } from '@twilio/conversations';
import { getDeviceId } from './cookie';
import CustomerAPI from 'api/customers';
import { SUPPORTED_MEDIA_TYPES, MAX_FILE_SIZE } from 'constants/chatConstants'

const setupTwilioChat = {
  getChatClientPromise: null,
  chatClient: null,

  async setupChatClient() {
    try {
      const deviceId = getDeviceId();
      const data = await CustomerAPI.getTwilioChatToken(deviceId);
      const token = get(data, 'data.object.token');
      const chatClient = await Client.create(token);

      // Save to properties to use later
      this.chatClient = chatClient;

      // Make sure to re-new token when it's about to expire
      chatClient.on('tokenAboutToExpire', async function () {
        const updatedToken = await CustomerAPI.getTwilioChatToken(deviceId);
        chatClient.updateToken(updatedToken);
      });

      return chatClient;
    } catch (error) {
      console.log('Failed to init chat client', error);
      throw error;
    }
  },

  async initialize() {
    // Race condition here
    // Only allow the first request to call setupChatClient
    // For subsequent requests, just wait until first request resolved
    // --> 7 requests come at the same time
    // --> 1 request call API, 6 requests wait for result
    // --> when resolved --> 7 requests continue to run
    try {
      this.getChatClientPromise = this.getChatClientPromise || this.setupChatClient();
      return await this.getChatClientPromise;
    } catch (error) {
      this.getChatClientPromise = null;
    }
  },

  async getChatClient() {
    if (this.chatClient) return this.chatClient;

    // Waiting until the initialization finished to get chat client
    const chatClient = await this.initialize();
    return chatClient;
  },

  verifyMediaFileSupported(type, size) {
    return SUPPORTED_MEDIA_TYPES.includes(type) && size <= MAX_FILE_SIZE;
  },
};

export default setupTwilioChat;
