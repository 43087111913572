// API
import BookingAPI from 'api/bookings'
// UTILS
import { Utils } from 'utils/Utils'
// ACTIONS
import _ from 'lodash'
// CONSTANT
import {
  FULL_DAY,
  LONG_HAUL
} from 'constants/bookingConstants'
import {
  EXTRA_REQUIREMENT_VEHICLE_TYPE_BY_OPTIONS
} from 'constants/extraServiceConstants'
import { isMarketingPage } from 'utils/booking/common'
import { updateLocation } from './locationActionCreators'

import { extraServicesActionsCreator } from 'store/toolkit/extraServices/extraServices.reducer'



const receiveExtraServices = (extraServices, timeType) => extraServicesActionsCreator.receiveExtraServices({
  extraServices,
  timeType
})

export const updateExtraServicesTimeType = timeType => extraServicesActionsCreator.updateExtraServicesTimeType(timeType)

export const setPreVehicleTypeId = preVehicleTypeId => extraServicesActionsCreator.setPreVehicleTypeId(preVehicleTypeId)

export const updateExtraRequirement = (id, attrs) => extraServicesActionsCreator.updateExtraRequirement({
  id,
  attrs
})

export const updateSelectedExtraRequirementForPerLocation = extraRequirement => extraServicesActionsCreator.updateSelectedExtraRequirementForPerLocation(extraRequirement)

export const updateExtraRequirementNegativePosition = (id, attrs) => extraServicesActionsCreator.updateExtraRequirementNegativePosition({
  id,
  attrs
})

export const updateFullDayPricing = attrs => dispatch => dispatch(extraServicesActionsCreator.updateFullDayPricing(attrs))

export const updateBadge = (id, attrs) => dispatch => dispatch(extraServicesActionsCreator.updateBadge({
  id,
  attrs
}))

export const updateCompanyBadge = (id, attrs) => dispatch => dispatch(extraServicesActionsCreator.updateCompanyBadge({
  id,
  attrs
}))

export const updateCompanySetting = attrs => dispatch => dispatch(extraServicesActionsCreator.updateCompanySetting(attrs))

export const clearVehicleTypeSettings = () => dispatch => dispatch(extraServicesActionsCreator.clearVehicleTypeSettings())

export const updateCustomReimbursement = (id, attrs) => dispatch => dispatch(extraServicesActionsCreator.updateCustomReimbursement({
  id,
  attrs
}))

/**
 * I think a better approach for  the edit booking flow as below
 *  - copy all data from bookAgainDetails to booking and treat edit booking as normal booking,
 *  - identify what is actual different between edit booking and booking
 *      and write specific logic to handle some exception case such as stage or temp data
 */
 export const setUpBookAgainFullDay = (shouldSetupForBookAgain) => (dispatch, getState) => {
  if (getState().timeType === FULL_DAY) {
    const bookingDetails = getState().bookAgainDetails
    const extraRequirementsNegativePosition = getState().extraServices.extraRequirementsNegativePosition
    if (shouldSetupForBookAgain) {
      extraRequirementsNegativePosition.forEach((extra) => {
        const oldExtra = _.find(
          bookingDetails.booking_extra_requirements_negative_position,
          { extra_requirement_id: extra.id }
        )
        if (_.isEmpty(oldExtra)) {
          dispatch(updateExtraRequirementNegativePosition(extra.id, { selected: false, selected_amount: 0 }))
        } else if (extra.pricing_method === EXTRA_REQUIREMENT_VEHICLE_TYPE_BY_OPTIONS) {
          const pricing = _.find(
            extra.pricings, { id: oldExtra.extra_requirement_pricing_id }
          )
          dispatch(updateExtraRequirementNegativePosition(extra.id, {
            selectedPricing: pricing, selected: true, selected_amount: oldExtra.selected_amount
          }))
        } else {
          dispatch(updateExtraRequirementNegativePosition(
            extra.id,
            { selected: true, selected_amount: oldExtra.selected_amount }
          ))
        }
      })
    }
  }
}

export const setUpBookAgainBadges = (shouldSetupForBookAgain) => (dispatch, getState) => {
  const bookingDetails = getState().bookAgainDetails
  const vehicleTypeBadges = getState().extraServices.vehicleTypeBadges
  const companyBadges = getState().extraServices.companyBadges
  if (shouldSetupForBookAgain ) {
    vehicleTypeBadges.forEach((badge) => {
      const badgeBookingDetails = _.find(bookingDetails.booking_badges,
        { badgeable_relation_id: badge.id, badgeable_relation_type: 'VehicleTypeBadge' })
      if (!_.isEmpty(badgeBookingDetails)) {
        dispatch(updateBadge(badge.id, { selected: true, selected_amount: badgeBookingDetails.selected_amount }))
      }
    })
    companyBadges.forEach((badge) => {
      const badgeBookingDetails = _.find(bookingDetails.booking_badges,
        { badgeable_relation_id: badge.id, badgeable_relation_type: 'CompanyBadge' })
      if (!_.isEmpty(badgeBookingDetails)) {
        dispatch(updateCompanyBadge(badge.id, { selected: true, selected_amount: badgeBookingDetails.selected_amount }))
      } else if (badge.badgeable_relation_type === 'CompanyBadge') {
        dispatch(updateCompanyBadge(badge.id, { selected: false, selected_amount: badge.selected_amount }))
      }
    })
  }
}

export const setUpBookAgainFullDayPricing = (shouldSetupForBookAgain) => (dispatch, getState) => {
  const bookingDetails = getState().bookAgainDetails

  const needToFillWithBookingDetail = shouldSetupForBookAgain
    && bookingDetails.full_day_selected_amount
  if (needToFillWithBookingDetail) {
    dispatch(updateFullDayPricing({
      selected: (bookingDetails.full_day_selected_amount >= 0),
      selected_amount: parseInt(bookingDetails.full_day_selected_amount, 10)
    }))
  }
}

const setUpBookAgainForExtraRequirementPerLocation = () => (dispatch, getState) => {
  const {
    bookAgainDetails, extraServices, locations
  } = getState()
  const extraRequirements = extraServices.extraRequirements
  const bookAgainLocations = bookAgainDetails.locations
  const extraRequirementIds = _.map(extraRequirements, 'id')
  locations.forEach((location, index) => {
    let data = []
    if (!_.isEmpty(bookAgainLocations) && bookAgainLocations[index]
      && !_.isEmpty(bookAgainLocations[index].extra_requirement_locations)) {
      if (_.includes(extraRequirementIds,
        bookAgainLocations[index].extra_requirement_locations[0].extra_requirement_id)) {
        data = bookAgainLocations[index].extra_requirement_locations
      }
    }
    dispatch(updateLocation(
      location.id, { extra_requirement_locations: data }
    ))
  })
}

const setUpForExtraRequirementPerLocation = () => (dispatch, getState) => {
  const {
    extraServices, locations
  } = getState()
  const extraRequirements = extraServices.extraRequirements
  const extraRequirementIds = _.map(extraRequirements, 'id')
  locations.forEach((location) => {
    let data = []
    if (!_.isEmpty(location.extra_requirement_locations)
      && _.includes(extraRequirementIds, location.extra_requirement_locations[0].extra_requirement_id)) {
      data = location.extra_requirement_locations
    } else {
      extraRequirements.forEach((extraRequirement) => {
        const checkByDefault = extraRequirement.check_by_default
        const selectedAmount = checkByDefault ? 1 : extraRequirement.selected_amount
        if (extraRequirement.is_flat_per_location) {
          data.push({
            extra_requirement_id: extraRequirement.id,
            is_flat_per_location: extraRequirement.is_flat_per_location,
            name: extraRequirement.name,
            position: extraRequirement.position,
            selected_amount: selectedAmount,
            unit_price: extraRequirement.unit_price
          })
        }
      })
    }
    dispatch(updateLocation(
      location.id, { extra_requirement_locations: data }
    ))
  })
}

export const setUpBookingLocationForExtraRequirementPerLocation = () => (dispatch, getState) => {
  const {
    bookAgainDetails, extraServices, locations, timeType
  } = getState()
  const extraRequirements = extraServices.extraRequirements
  if (timeType !== LONG_HAUL) {
    _.forEach(locations, (location) => {
      dispatch(updateLocation(
        location.id, { extra_requirement_locations: [] }
      ))
    })
  } else if (!_.isEmpty(extraRequirements)) {
    if (!_.isEmpty(bookAgainDetails) && !isMarketingPage) {
      dispatch(setUpBookAgainForExtraRequirementPerLocation())
    } else {
      dispatch(setUpForExtraRequirementPerLocation())
    }
  }
}

// setup reimbursement for edit booking and booking again
export const setUpBookAgainReimbursement = (shouldSetupForBookAgain) => (dispatch, getState) => {
  const bookingDetails = getState().bookAgainDetails
  const vehicleTypeSettings = _.get(getState(), 'extraServices.vehicleTypeSettings')
  if (
    bookingDetails.allow_tolls_fees !== ''
    && !_.isEmpty(vehicleTypeSettings)
    && shouldSetupForBookAgain
  ) {
    dispatch(updateCompanySetting({
      allow_tolls_fees: bookingDetails.allow_tolls_fees,
      allow_parking_fees: bookingDetails.allow_parking_fees,
      allow_waiting_time_fees: bookingDetails.allow_waiting_time_fees,
    }))
  }
}

// setup custom reimbursement for edit booking and booking again
export const setUpBookAgainCustomReimbursement = (shouldSetupForBookAgain) => (dispatch, getState) => {

  const customReimbursementsOfBookAgain = _.get(getState(), 'bookAgainDetails.custom_reimbursements')
  const customReimbursements = _.get(getState(), 'extraServices.customReimbursements')
  if (
    !_.isEmpty(customReimbursements) // have the custom reimbursement
    && shouldSetupForBookAgain
  ) {
    customReimbursements.forEach((reimbursement) => {
      const reimbursementOfBookAgain = customReimbursementsOfBookAgain.find(
        item => item.vehicle_type_reimbursement_id === reimbursement.id
      )

      const validReimbursement = !_.isEmpty(reimbursementOfBookAgain)
      const { oldSelectedAmount, oldUserInputAmount } = Utils.buildCustomReimbursementsOfBookAgain(
        customReimbursementsOfBookAgain,
        reimbursementOfBookAgain,
        reimbursement,
        validReimbursement
      )

      dispatch(updateCustomReimbursement(
        reimbursement.id,
        {
          ...(reimbursement.allow_user_to_enter_amount ? { user_input_amount: oldUserInputAmount } : {}),
          selected_amount: oldSelectedAmount
        }
      ))
    })
  }
}

export const getExtraServicesSingle = callback => async (dispatch, getState) => {
  const {
    currentCustomer, timeType, selectedServiceTypeID,
    selectedVehicleTypeID, locations, isLoginStep3,
    bookAgainDetails, extraServices
  } = getState()
  const validLocations = _.filter(locations, l => !_.isUndefined(l.lat) || !_.isUndefined(l.lng))
  const preExtraRequirements = getState().extraServices.extraRequirements
  const isVehicleTypeChanged = extraServices.preVehicleTypeId !== selectedVehicleTypeID
  const isTimeTypeChanged = extraServices.timeTypeOfExtraServices !== timeType
  if (isVehicleTypeChanged) {
    dispatch(setPreVehicleTypeId(selectedVehicleTypeID))
  }

  if (isTimeTypeChanged) {
    dispatch(updateExtraServicesTimeType(timeType))
  }
  await BookingAPI.getExtraServices(
    currentCustomer.authentication_token,
    currentCustomer.currentCompanyId,
    timeType,
    selectedServiceTypeID,
    selectedVehicleTypeID,
    validLocations.length,
    (results) => {
      const newResult = {
        ...results,
        preExtraRequirements,
        preExtraServices: extraServices,
        isLoginStep3,
        bookingDetails: bookAgainDetails,
        isVehicleTypeChanged,
        isTimeTypeChanged
      }
      const shouldSetupForBookAgain = !_.isEmpty(bookAgainDetails) 
        && !isMarketingPage && !isVehicleTypeChanged && !isTimeTypeChanged
        && !extraServices.isGetExtraService // only run once (init)
      Promise.resolve(
        dispatch(receiveExtraServices(newResult, timeType)),
      ).then(() => (
        Promise.all([
          dispatch(setUpBookAgainCustomReimbursement(shouldSetupForBookAgain)),
          dispatch(setUpBookAgainFullDay(shouldSetupForBookAgain)),
          dispatch(setUpBookAgainBadges(shouldSetupForBookAgain)),
          dispatch(setUpBookAgainFullDayPricing(shouldSetupForBookAgain)),
          dispatch(setUpBookAgainReimbursement(shouldSetupForBookAgain)),
          dispatch(setUpBookingLocationForExtraRequirementPerLocation()),
        ])
      )).then(() => (
        callback()
      ))
    }
  )
}
