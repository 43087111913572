import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
// UTILS
import {
  verifyTimeType,
  verifyPickupTime,
  vehicleTypes as vehicleTypesUtils,
  getVehicleType,
  getQuickChoice,
  getServiceType,
  isCustomerEditBooking,
} from 'utils/new_booking/common'
import { Utils } from 'utils/Utils'
// ACTIONS
import * as serviceTypeActionCreators from 'store/actions/new_booking/serviceTypeActionCreators'
import * as vehicleTypeActionCreators from 'store/actions/new_booking/vehicleTypeActionCreators'
// COMPONENTS
import DropdownServiceTypes from '../../common/DropdownServiceTypes'
// CONSTANTS
import {
  POPUP_ID_SERVICE_TYPE_COMING_SOON,
} from 'constants/common/popupConstants'
import {
  ID_NEW_SERVICE_TYPE,
} from 'constants/newBookingConstants'
import { FULL_DAY, NOW } from 'constants/bookingConstants'
import { quickChoiceActionsCreator } from 'store/toolkit/newBooking/quickChoice/quickChoice.reducer'
import { timeTypeActionsCreator } from 'store/toolkit/newBooking/timeType.reducer'
import { pickupTimeActionsCreator } from 'store/toolkit/newBooking/pickupTime.reducer'
import { showDatePickerActionsCreator } from 'store/toolkit/newBooking/showDatePicker.reducer'
import { timeTypeUIActionsCreator } from 'store/toolkit/newBooking/timeTypeUI.reducer'
import { showQuickChoiceActionsCreator } from 'store/toolkit/newBooking/quickChoice/showQuickChoice.reducer'
import { currentPopupIDActionsCreator } from 'store/toolkit/currentPopupID/currentPopupID.reducer'
import { infoAssignDriverPopupActionsCreator } from 'store/toolkit/infoAssignDriverPopup/infoAssignDriverPopup.reducer'
import { extraInfosActionsCreator } from 'store/toolkit/extraInfos/extraInfos.reducer'
// ASSETS

const mapStateToProps = state => ({
  assignedDriver: state.assignedDriver,
  serviceTypes: state.serviceTypes,
  vehicleTypes: vehicleTypesUtils(state),
  selectedServiceTypeID: state.selectedServiceTypeID,
  selectedVehicleTypeID: state.selectedVehicleTypeID,
  timeType: state.timeType,
  quickChoiceID: state.quickChoiceID,
  pickupTime: state.pickupTime,
  currentCustomer: state.currentCustomer,
  extraInfos: state.extraInfos,
  booking: state.booking,
})

function mapDispatchToProps(dispatch) {
  return {
    timeTypeActions: bindActionCreators(timeTypeActionsCreator, dispatch),
    pickupTimeActions: bindActionCreators(pickupTimeActionsCreator, dispatch),
    serviceTypeActions: bindActionCreators(serviceTypeActionCreators, dispatch),
    quickChoiceActions: bindActionCreators(quickChoiceActionsCreator, dispatch),
    datePickerActions: bindActionCreators(showDatePickerActionsCreator, dispatch),
    timeTypeUIActions: bindActionCreators(timeTypeUIActionsCreator, dispatch),
    showQuickChoiceActions: bindActionCreators(showQuickChoiceActionsCreator, dispatch),
    currentPopupIDActions: bindActionCreators(currentPopupIDActionsCreator, dispatch),
    infoAssignDriverPopupActions: bindActionCreators(infoAssignDriverPopupActionsCreator, dispatch),
    vehicleTypeActions: bindActionCreators(vehicleTypeActionCreators, dispatch),
    extraInfoActions: bindActionCreators(extraInfosActionsCreator, dispatch),
  }
}

class ServiceType extends React.Component {
  static checkHeightPopup() {
    const element = document.querySelector(`#${POPUP_ID_SERVICE_TYPE_COMING_SOON} .Normal-Booking`)
    if (element.clientHeight + 120 > element.parentElement.clientHeight) {
      element.classList.add('No-Seft')
    } else {
      element.classList.remove('No-Seft')
    }
  }

  constructor(props) {
    super(props)
    this.state = {}

    this.onChangeServiceType = this.onChangeServiceType.bind(this)
    this.onClickComingServiceTypeHandler = this.onClickComingServiceTypeHandler.bind(this)
  }

  /* eslint-disable-next-line */
  onClickComingServiceTypeHandler(serviceType, isDisabledClick) {
    const { setComingSoonServiceTypeName, setShowComingSoonService, setIconComingSoonService } = this.props
    if (!isDisabledClick) {
      if (serviceType.is_coming) {
        setComingSoonServiceTypeName(serviceType.name)
        setShowComingSoonService(true, () => ServiceType.checkHeightPopup())
        setIconComingSoonService(serviceType.inactive_icon_url)
      }
    }
  }

  onChangeServiceType(serviceTypeID, isDisabledClick) {
    if (!isDisabledClick) {
      const {
        assignedDriver, selectedServiceTypeID, currentPopupIDActions, infoAssignDriverPopupActions, extraInfoActions
      } = this.props
      if (assignedDriver && selectedServiceTypeID !== serviceTypeID) {
        Utils.updateInfoAssignDriverPopupActions(
          { func: this.onChangeServiceTypeAfterCheckBookingDetails.bind(this, serviceTypeID) },
          currentPopupIDActions,
          infoAssignDriverPopupActions
        )
      } else {
        extraInfoActions.resetCashbackWhenUpdateService()
        this.onChangeServiceTypeAfterCheckBookingDetails(serviceTypeID)
      }
    }
  }

  onChangeServiceTypeAfterCheckBookingDetails(serviceTypeID) {
    const {
      selectedServiceTypeID,
      serviceTypeActions,
      serviceTypes,
      quickChoiceID,
      selectedVehicleTypeID,
      vehicleTypes,
      datePickerActions,
      quickChoiceActions,
      timeTypeActions,
      showQuickChoiceActions,
      timeType,
      vehicleTypeActions,
      setIsShowPickupTimePopup,
      timeTypeUIActions,
    } = this.props
    const vehicleType = getVehicleType(vehicleTypes, selectedVehicleTypeID)
    const newServiceType = getServiceType(serviceTypes, serviceTypeID)
    const newVehicleType = newServiceType.vehicle_types[0]
    if (serviceTypeID !== selectedServiceTypeID) {
      const isValidTimeType = this.verifyTimeTypeWhenChangeService(newVehicleType)
      vehicleTypeActions.getNumberOfOnlineDrivers(newVehicleType.id)
      if (isValidTimeType) {
        if (quickChoiceID > 0) {
          const currentQuickChoice = getQuickChoice(vehicleType.quick_choices, quickChoiceID)
          const newQuickchoices = newVehicleType.quick_choices
          const quickChoices = _.filter(newQuickchoices, {
            time_type: currentQuickChoice?.time_type,
            schedule_time: currentQuickChoice?.schedule_time
          })
          if (quickChoices.length > 0) {
            serviceTypeActions.changeServiceType(serviceTypeID)
            quickChoiceActions.changeQuickChoice(quickChoices[0].id)
          } else {
            window.localStorage.setItem(ID_NEW_SERVICE_TYPE, serviceTypeID)
            showQuickChoiceActions.changeShowQuickChoices(true)
          }
        } else {
          const isValidPickupTime = this.verifyPickupTimeWhenChangeService(newVehicleType)
          if (isValidPickupTime) {
            serviceTypeActions.changeServiceType(serviceTypeID)
          } else {
            window.localStorage.setItem(ID_NEW_SERVICE_TYPE, serviceTypeID)
            datePickerActions.showDatePicker(true)
          }

          // For case switching from 'quick' (no quick choice) to 'quick choice'
          if (timeType === NOW && newVehicleType.quick_choices && newVehicleType.quick_choices.length) {
            timeTypeActions.changeTimeType('')
            timeTypeUIActions.changeTimeTypeUI('')
          }

          this.clearDataServiceType()
        }
      } else {
        window.localStorage.setItem(ID_NEW_SERVICE_TYPE, serviceTypeID)
        setIsShowPickupTimePopup(true)
      }
    }
    window.Moengage.track_event('Vehicle Type Selected', {
      ID: newServiceType.id,
      Name: newServiceType.name
    })
  }

  clearDataServiceType() {
    const { timeTypeActions, timeType, pickupTimeActions, datePickerActions, timeTypeUIActions } = this.props
    if (timeType === FULL_DAY) {
      timeTypeActions.changeTimeType('')
      timeTypeUIActions.changeTimeTypeUI('')
      datePickerActions.showDatePicker(false)
      pickupTimeActions.changePickupTime('')
    }
  }

  verifyPickupTimeWhenChangeService(vehicleType) {
    const {
      pickupTime,
      timeType,
    } = this.props
    if (_.isEmpty(vehicleType)) {
      return true
    }
    const settings = vehicleType?.settings
    return verifyPickupTime(settings, pickupTime, timeType)
  }

  verifyTimeTypeWhenChangeService(vehicleType) {
    const {
      timeType,
      quickChoiceID,
    } = this.props
    if (_.isEmpty(vehicleType)) {
      return true
    }
    return verifyTimeType(vehicleType, timeType, quickChoiceID)
  }

  render() {
    const {
      currentCustomer, defaultValue, serviceTypes
    } = this.props
    const isDisabledClick = isCustomerEditBooking()
    if (!_.size(serviceTypes)) return null
    return (
      <DropdownServiceTypes
        currentCustomer={currentCustomer}
        isDisabled={isDisabledClick}
        onChangeSelectService={this.onChangeServiceType}
        onClickComingService={this.onClickComingServiceTypeHandler}
        serviceTypes={serviceTypes}
        defaultServiceType={defaultValue}
      />
    )
  }
}

ServiceType.defaultProps = {
  assignedDriver: undefined,
  defaultValue: {},
  timeType: undefined,
  setComingSoonServiceTypeName: () => undefined,
  setIconComingSoonService: () => undefined
}

ServiceType.propTypes = {
  assignedDriver: PropTypes.shape({}),
  extraInfoActions: PropTypes.shape({}).isRequired,
  serviceTypes: PropTypes.instanceOf(Array).isRequired,
  vehicleTypes: PropTypes.instanceOf(Array).isRequired,
  selectedServiceTypeID: PropTypes.number.isRequired,
  selectedVehicleTypeID: PropTypes.number,
  timeType: PropTypes.string,
  quickChoiceID: PropTypes.number.isRequired,
  pickupTime: PropTypes.string.isRequired,
  currentCustomer: PropTypes.shape({}).isRequired,
  currentPopupIDActions: PropTypes.shape({}).isRequired,
  infoAssignDriverPopupActions: PropTypes.shape({}).isRequired,
  serviceTypeActions: PropTypes.shape({}).isRequired,
  timeTypeActions: PropTypes.shape({}).isRequired,
  quickChoiceActions: PropTypes.shape({}).isRequired,
  defaultValue: PropTypes.shape({}),
  vehicleTypeActions: PropTypes.shape({}).isRequired,
  setShowComingSoonService: PropTypes.func.isRequired,
  setIsShowPickupTimePopup: PropTypes.func,
  setComingSoonServiceTypeName: PropTypes.func,
  setIconComingSoonService: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceType)
