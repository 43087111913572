import apiClient from 'services/axiosApp'
import CommonUtils from 'utils/common'
import { getAppsFlyerUID, getDeviceId } from 'utils/cookie'


const updateCustomerSettingAPI = async ({ authenToken, queryString }) => {
  try {
    const res = await apiClient.put(`/api/v3/settings/customers?${queryString}`, null, {
      headers: {
        'Appsflyer-Uid': getAppsFlyerUID(),
        'App-Name': CommonUtils.appName(),
        'Device-Id': getDeviceId(),
      },
    })
    return res
  } catch (err) {
    throw new Error(err)
  }
}

export { updateCustomerSettingAPI }
