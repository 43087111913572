import { PinpointClient, PutEventsCommand } from '@aws-sdk/client-pinpoint'
import {
  AWS_ACCESS_KEY_ID,
  AWS_SECRET_ACCESS_KEY,
  AWS_PINPOINT_ID,
  AWS_MAX_EVENT,
  AWS_MAX_EVENT_TIME,
} from 'constants/appConstants'
import store from 'store/store'

const LIST_EVENTS = 'list_events'

export const COUNTRY_CODE_ISO3 = {
  ID: 'IDN',
  VN: 'VNM',
  PH: 'PHL',
  TH: 'THA',
}

export const generateUUID = () => {
  let e = new Date().getTime()
  let t = (performance && performance.now && 1e3 * performance.now()) || 0
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function random(n) {
    let a = 16 * Math.random()
    // eslint-disable-next-line no-return-assign
    return (
      e > 0 ? ((a = (e + a) % 16 | 0), (e = Math.floor(e / 16))) : ((a = (t + a) % 16 | 0), (t = Math.floor(t / 16))),
      // eslint-disable-next-line yoda
      ('x' === n ? a : (7 & a) | 8).toString(16)
    )
  })
}

const formatDataEndpoint = (user) => {
  const { extraInfos } = store.getState()
  return {
    ChannelType: 'GCM',
    Address: user.email,
    EffectiveDate: new Date().toISOString(),
    User: {
      UserId: user.id,
    },
    Demographic: {
      Platform: 'Web',
      Locale: localStorage.getItem('lang') || 'en',
      Make: 'Browser',
    },
    Location: {
      Country: extraInfos.country_code,
    },
  }
}

const pinpointService = {
  client: null,

  setClient() {
    this.client = new PinpointClient({
      region: 'ap-southeast-1',
      maxAttempts: 3,
      credentials: {
        accessKeyId: AWS_ACCESS_KEY_ID,
        secretAccessKey: AWS_SECRET_ACCESS_KEY,
      },
    })
  },
  async recordEvent(dataEvents) {
    if (!this.client) this.setClient()
    if (!this.client) return
    const { currentCustomer } = store.getState()
    const userId = currentCustomer.id

    const params = {
      ApplicationId: AWS_PINPOINT_ID,
      EventsRequest: {
        BatchItem: {
          [userId]: {
            Endpoint: formatDataEndpoint(currentCustomer),
            Events: dataEvents,
          },
        },
      },
    }

    try {
      const command = new PutEventsCommand(params)
      await this.client.send(command)
    } catch (error) {
      console.error('Error recording event:', error)
    }
  },
  formatDataEvent(eventName, attributes = {}) {
    const { currentCustomer } = store.getState()
    const companyField = currentCustomer.currentCompanyId
      ? { company_id: currentCustomer.currentCompanyId }
      : {}
    return {
      EventType: eventName,
      Timestamp: new Date().toISOString(),
      Attributes: {
        ...attributes,
        ...companyField,
        user_id: currentCustomer.id
      },
    }
  },
  trackEvent(eventname, attributes = {}) {
    const dataEvent = this.formatDataEvent(eventname, attributes)
    this.checkStorageDataToSendEvent(dataEvent)
  },
  checkStorageDataToSendEvent(dataEvent) {
    const listEvents = JSON.parse(localStorage.getItem(LIST_EVENTS)) || {}
    listEvents[generateUUID()] = dataEvent
    if (Object.keys(listEvents).length >= AWS_MAX_EVENT) {
      localStorage.removeItem(LIST_EVENTS)
      this.recordEvent(listEvents)
    } else {
      localStorage.setItem(LIST_EVENTS, JSON.stringify(listEvents))
    }
  },
  checkTimeToSendEvent() {
    setInterval(() => {
      const listEvents = JSON.parse(localStorage.getItem(LIST_EVENTS)) || {}
      if (Object.keys(listEvents).length > 0) {
        localStorage.removeItem(LIST_EVENTS)
        this.recordEvent(listEvents)
      }
    }, AWS_MAX_EVENT_TIME)
  },
}

export default pinpointService
