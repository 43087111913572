import moment from 'moment'
import _ from 'lodash'
import * as BookingHelpers from '../multiple_bookings/common'
import { minuMumBufferMinutes, miniMumMinutes } from '../new_booking/common'
import {
  BATCH_STATUS_CONFIRMED,
  BATCH_STATUS_UNCONFIRMED,
  BATCH_STATUS_ARRANGING,
  BATCH_STATUS_PARTIALLY_CONFIRMED,
  BATCH_STATUS_COMPLETED,
  BATCH_STATUS_CANCELED,
  STEP_UPLOAD_TAB_ADVANCED,
  EZ_COLUMNS,
  EXTRA_REQUIREMENT_TYPE
} from 'constants/ezSpreadSheetConstants'
import {
  ICON_BATCH_ARRANGING,
  ICON_BATCH_CANCELED,
  ICON_BATCH_COMPLETED,
  ICON_BATCH_COMFIRMED,
  ICON_BATCH_DRAFT,
  ICON_BATCH_INPROGRESS,
  ICON_BATCH_UNCOMFIRMED
} from 'constants/imageConstants'
import {
  TYPE_HEADER_NAME,
  TYPE_HEADER_DESCRIPTION,
} from 'constants/common/variables/batchConstants'
import {
  STATUS_LOCATING_DRIVER_TIMEOUT,
  STATUS_ASSIGNING_DRIVER_TIMEOUT,
  STATUS_DRIVER_DECLINED_BOOKING,
  SCHEDULE,
  STATUS_DRIVER_ON_THE_WAY,
  STATUS_AT_PICKUP,
  STATUS_CONFIRMED,
  STATUS_DELIVERY_IN_PROGRESS,
  STATUS_AT_DESTINATION,
  STATUS_GOING_TO_DESTINATION,
  STATUS_CANCELLED,
  STATUS_REIMBURSE,
  STATUS_DELIVERY_COMPLETED,
  STATUS_DRIVER_ACCEPT_BOOKING,
  STATUS_FLEET_ACCEPT_BOOKING,
  STATUS_FLEET_TIMEOUT,
  STATUS_TO_AT_PICKUP,
  SCHEDULE_TIME_TYPE,
  STATUS_CS_FINDING_DRIVER
} from 'constants/bookingConstants'
import { EXTRA_NORMAL } from 'constants/extraServiceConstants'
import I18n from 'i18n/i18n'
import { getCompanyBadgesAction, getCustomReimbursementsForEzSheetAction, getExtraRequirementVehicleTypesAction, getVehicleTypeBadgesAction, getVehicleTypesAction } from 'store/actions/ez_spread_sheet/serviceTypesActionCreators'

/* ==== CONTACT ======== */
export const findContact = (contacts, id) => _.find(contacts, { id })

export const currentContact = state => findContact(state.contacts, state.batchTemplate.contact_id)

export const findPickupContact = (pickupContacts, contactId) => {
  const pickupContact = _.find(pickupContacts, { id: contactId })
  return pickupContact
}

export const currentPickupContact = (state) => {
  const pickupContact = findPickupContact(state.pickupContacts, state.batchTemplate.contact_id)
  return pickupContact
}

export const setDefaultPickupContact = () => {

}

export const currentCurrency = (serviceTypes, selectedServiceTypeID) => {
  const selectedServiceType = _.find(serviceTypes, { id: selectedServiceTypeID })
  return selectedServiceType.currency
}

export const currentVehicleType = (serviceTypes, serviceTypeId, vehicleTypeId) => {
  const vehicleTypes = BookingHelpers.vehicleTypes(serviceTypes, _.toInteger(serviceTypeId))
  return BookingHelpers.vehicleType(vehicleTypes, _.toInteger(vehicleTypeId))
}

export const STATUS_MAP = {
  locating_driver: 'arranging',
  cs_finding_driver: 'arranging',
  draft_pending: 'draft',
  locating_driver_timeout: 'unconfirmed',
  driver_accept_booking: 'confirmed',
  driver_on_the_way: 'driver_on_the_way',
  delivery_in_progress: 'in_progress',
  delivery_completed: 'completed',
  canceled: 'canceled',
  fleet_accept_booking: 'confirmed',
  fleet_timeout: 'unconfirmed',
  failed: 'failed',
  assigning_driver: 'arranging',
  assigning_driver_timeout: 'unconfirmed',
  driver_declined_booking: 'unconfirmed',
  canceled_rebook: 'canceled',
  recovering: 'unconfirmed',
  to_at_pickup: 'to_at_pickup',
  to_at_destination: 'to_at_destination',
  confirmed: 'confirmed',
  at_pickup: 'at_pickup',
  at_destination: 'at_destination',
  going_to_destination: 'going_to_destination',
  reimburse: 'reimburse',
}

const ICON_MAP = {
  arranging: { text: ICON_BATCH_ARRANGING, color: 'Orange-text' },
  draft: { text: ICON_BATCH_DRAFT, color: 'gray' },
  unconfirmed: { text: ICON_BATCH_UNCOMFIRMED, color: 'red' },
  confirmed: { text: ICON_BATCH_COMFIRMED, color: 'green' },
  in_progress: { text: ICON_BATCH_INPROGRESS, color: 'green' },
  completed: { text: ICON_BATCH_COMPLETED, color: '' },
  canceled: { text: ICON_BATCH_CANCELED, color: 'violet' },
  failed: { text: ICON_BATCH_UNCOMFIRMED, color: 'red' },
  at_pickup: { text: '', color: 'green' },
  at_destination: { text: '', color: 'green' },
  going_to_destination: { text: '', color: 'green' },
  driver_on_the_way: { text: '', color: 'green' },
}

export const BADGE_COLOR_MAP = {
  arranging: 'Status-UI-pending-group',
  confirmed: 'Status-UI-pending-group',
  partial: 'Status-UI-pending-group',
  partially_confirmed: 'Red-bg',
  unconfirmed: 'Status-UI-pending-group',
  completed: 'Status-UI-completed-group',
  canceled: 'Status-UI-canceled-group',
  draft: 'Gray-bg'
}

export const getPeriodTime = (booking, serviceTypes) => {
  let serviceTypeId = 0
  if (booking.service_type_id) {
    serviceTypeId = booking.service_type_id
  } else if (booking.service_type && booking.service_type.id) {
    serviceTypeId = booking.service_type.id
  }
  const vehicleTypeId = booking.vehicle_type_id || booking.vehicle_type?.id
  const getServiceType = serviceTypes.filter(serviceType => serviceType.id === +serviceTypeId)
  const getVehicleType = !_.isEmpty(getServiceType)
    && getServiceType[0].vehicle_types.filter(vehicleType => vehicleType.id === +vehicleTypeId)
  return (!_.isEmpty(getVehicleType) && getVehicleType[0].settings.sending_eta_sms_period)
    ? getVehicleType[0].settings.sending_eta_sms_period : 2
}

export const mapStatusBooking = (booking, periodTimeSetting) => {
  let newStatus = ''
  let newText = ''
  const timeType = booking.time_type
  const periodTime = (_.toFinite(periodTimeSetting) * 3600 * 1000)
  const atPickup = _.get(booking, 'locations[0].arrived_at')
  const arrLocations = _.get(booking, 'locations', [])
  const needConfirmation = booking.need_confirmation
  const totalFees = booking.total_fees
  const companyID = booking.company_id
  const reimbursementStatus = booking.reimbursement_status
  const chatEnable = booking.chat_enable
  switch (booking.status) {
    case STATUS_CS_FINDING_DRIVER:
      newStatus = booking.status
      newText = I18n.t('batches.status.arranging')
      break
    case 'driver_accept_booking':
    case 'fleet_accept_booking':
    case 'fleet_timeout':
    case 'booked':
      if (timeType === 'now') {
        newStatus = STATUS_DRIVER_ON_THE_WAY
        newText = I18n.t('closures.status.driver_on_the_way')
      } else if (timeType === 'full_day') {
        newStatus = STATUS_CONFIRMED
        newText = I18n.t('closures.status.fullday_confirm')
      } else if (atPickup) {
        newStatus = STATUS_AT_PICKUP
        newText = I18n.t('closures.status.at_pickup')
      } else if (periodTime && (periodTime + _.toInteger(moment()) > booking.pickup_time * 1000)) {
        newStatus = STATUS_DRIVER_ON_THE_WAY
        newText = I18n.t('closures.status.driver_on_the_way')
      } else {
        newStatus = STATUS_CONFIRMED
        newText = I18n.t('closures.status.schedule_confirm')
      }
      break
    case 'delivery_in_progress':
    case 'in_progress':
      if (!arrLocations.length) break
      if (arrLocations.length > 2) {
        for (let i = 1; i < arrLocations.length; i += 1) {
          if (!arrLocations[i].has_signature) {
            if (arrLocations[i].arrived_at) {
              newStatus = STATUS_AT_DESTINATION
              newText = `${I18n.t('closures.status.at_destination')} ${i}`
              break
            }
            newStatus = STATUS_GOING_TO_DESTINATION
            newText = `${I18n.t('closures.status.going_to_destination')} ${i}`
            break
          }
        }
      } else if (arrLocations[1]?.arrived_at) {
        newStatus = STATUS_AT_DESTINATION
        newText = I18n.t('closures.status.at_destination')
      } else {
        newStatus = STATUS_GOING_TO_DESTINATION
        newText = I18n.t('closures.status.going_to_destination')
      }
      break
    case 'delivery_completed':
    case 'completed':
      // Case BP and Non-BP full credit booking
      if ((!companyID && (totalFees === 0)) || companyID) {
        newStatus = needConfirmation ? STATUS_REIMBURSE : STATUS_DELIVERY_COMPLETED
        newText = I18n.t(`closures.status.${needConfirmation && 'reimburse'}`)
        break
      }
      // Case Non-BP NOT full credit booking
      if (!companyID && totalFees > 0) {
        if ((reimbursementStatus === 'driver_submitted_claim' && !needConfirmation && chatEnable)
          || (needConfirmation)) {
          newStatus = STATUS_REIMBURSE
          newText = I18n.t('closures.status.reimburse')
          break
        } else {
          newStatus = STATUS_DELIVERY_COMPLETED
          newText = I18n.t('closures.status.completed')
          break
        }
      }
      newStatus = needConfirmation && STATUS_REIMBURSE
      newText = I18n.t(`closures.status.${needConfirmation ? 'reimburse' : 'completed'}`)
      break
    case 'canceled':
      if (booking.canceled_by_customer) {
        newStatus = STATUS_CANCELLED
        newText = I18n.t('closures.status.you_canceled')
      } else if (booking.canceled_by_driver) {
        newStatus = STATUS_CANCELLED
        newText = I18n.t('closures.status.driver_canceled')
      } else {
        newStatus = STATUS_CANCELLED
        newText = I18n.t('closures.status.canceled')
      }
      break
    default:
      newStatus = booking.status
      newText = I18n.t(`batches.status.${booking.status}`)
  }
  return { status: newStatus, text: newText }
}

export const statusReport = (bookings, serviceTypes) => {
  const result = {
    arranging: 0,
    draft: 0,
    unconfirmed: 0,
    confirmed: 0,
    to_at_pickup: 0,
    to_at_destination: 0,
    reimburse: 0,
    completed: 0,
    canceled: 0,
  }

  bookings.forEach((booking) => {
    const periodTime = getPeriodTime(booking, serviceTypes)
    const { status } = mapStatusBooking(booking, periodTime)
    if (status === STATUS_DRIVER_ON_THE_WAY || status === STATUS_AT_PICKUP) {
      result.to_at_pickup += 1
    } else if (booking.status === STATUS_DELIVERY_IN_PROGRESS || booking.status === 'in_progress') {
      result.to_at_destination += 1
    } else {
      result[STATUS_MAP[status]] += 1
    }
  })
  return result
}

export const showStatusBatch = (batch) => {
  const bookings = batch.batch_bookings || batch.bookings
  const statusList = [
    STATUS_LOCATING_DRIVER_TIMEOUT,
    STATUS_ASSIGNING_DRIVER_TIMEOUT,
    STATUS_DRIVER_DECLINED_BOOKING
  ]
  const unconfirmedBooking = _.find(bookings, booking => _.includes(statusList, booking.status))
  return {
    icon: unconfirmedBooking ? ICON_MAP[STATUS_MAP[unconfirmedBooking.status]] : ''
  }
}

export const showStatus = (booking, periodTime = 2) => {
  const { status, text } = mapStatusBooking(booking, periodTime)
  const result = {
    text: text || '',
    icon: ICON_MAP[STATUS_MAP[status]],
  }
  return result
}

export const batchStatus = (batch, serviceTypes) => {
  const bookingStatus = statusReport(batch.bookings, serviceTypes)
  const totalBookings = _.filter(
    batch.bookings, b => b.status !== 'draft_pending'
  ).length
  let status = ''
  switch (true) {
    case (bookingStatus.arranging > 0): {
      status = BATCH_STATUS_ARRANGING
      break
    }
    case (bookingStatus.canceled === totalBookings): {
      status = BATCH_STATUS_CANCELED
      break
    }
    case (bookingStatus.unconfirmed + bookingStatus.canceled === totalBookings): {
      status = BATCH_STATUS_UNCONFIRMED
      break
    }
    case (bookingStatus.completed + bookingStatus.canceled === totalBookings): {
      status = BATCH_STATUS_COMPLETED
      break
    }
    case (bookingStatus.confirmed
      + bookingStatus.to_at_pickup
      + bookingStatus.to_at_destination
      + bookingStatus.canceled
      === totalBookings): {
        status = BATCH_STATUS_CONFIRMED
        break
      }
    case (bookingStatus.unconfirmed > 0): {
      status = BATCH_STATUS_PARTIALLY_CONFIRMED
      break
    }
    default: {
      status = BATCH_STATUS_CONFIRMED
      break
    }
  }
  return status
}

export const showConsolidator = tab => tab === STEP_UPLOAD_TAB_ADVANCED

export const replaceSpecialCharacters = (inputText, textReplace = undefined, keyName = undefined) => {
  // Don't replace leading by `+` for only recipient_phone
  if (keyName === 'recipient_phone' && !_.isUndefined(inputText) && inputText.charAt(0) === '+') {
    return inputText
  }
  if (!_.isUndefined(inputText)) {
    const specialCharacters = /[`~!@#$%^&*|+÷¿?<>\{\}\[\]]/gi
    if (textReplace === undefined) {
      return inputText.replace(specialCharacters, '')
    }
    return inputText.replace(specialCharacters, textReplace)
  }
  return inputText
}

export const verifyPickupTime = (pickupTime) => {
  const now = new Date()
  return (now > (new Date(pickupTime)))
}

export const matchedSearchConditions = (booking, serviceTypes, serviceTypeId, vehicleTypeId, searchText) => {
  if (_.isEmpty(searchText)) {
    return true
  }
  const text = _.toLower(searchText)
  const vehicleType = currentVehicleType(
    serviceTypes,
    serviceTypeId,
    vehicleTypeId
  )
  return _.toString(booking.id).indexOf(text) !== -1
    || _.toLower(booking.name).indexOf(text) !== -1
    || (!_.isUndefined(booking.driver) && (_.toLower(booking.driver.name).indexOf(text) !== -1))
    || (!_.isUndefined(vehicleType) && (_.toLower(vehicleType.name).indexOf(text) !== -1))
}

export const changeCurrentDateTimeToSeconds = () => {
  const now = new Date()
  return _.round(now.getTime() / 1000)
}

const bookingTimeType = (booking, key) => {
  if (!_.isEmpty(booking.time_type_option)) {
    const optionKey = key === 'time' ? '' : key
    return booking.time_type_option[`${optionKey}type_key`] || SCHEDULE
  }

  if (!_.isUndefined(booking.time_type)) {
    return booking[`${key}_type`] || SCHEDULE
  }

  return undefined
}

const generateTimeType = (booking, isBookAgain) => {
  
  const isShowLH = booking?.vehicle_type?.settings.long_haul_enabled && booking?.vehicle_type?.settings.show_long_haul_time_type
  let key = 'time'

  if (isBookAgain && !(isShowLH)) {
    key = 'display_time_'
  }

  return bookingTimeType(booking, key)
}

export const miniMumMinutesEZ = (booking, extraInfos) => {
  const timeType = bookingTimeType(booking)
  const areaMinimumMinutes = _.toInteger(extraInfos.minimum_pickup_time_hours) * 60
  return miniMumMinutes(booking.vehicle_type, timeType, areaMinimumMinutes)
}

export const minuMumBufferMinutesEZ = (booking, extraInfos, isBookAgain = false) => {
  const timeType = generateTimeType(booking, isBookAgain)
  const areaMinimumMinutes = _.toInteger(extraInfos.minimum_pickup_time_hours) * 60
  return minuMumBufferMinutes(booking.vehicle_type, timeType, areaMinimumMinutes, false)
}

export const checkMaxLengthOfInput = (keyName, keyValue) => {
  let maxLengthText
  switch (keyName) {
    case TYPE_HEADER_NAME:
      maxLengthText = 120
      break
    case TYPE_HEADER_DESCRIPTION:
      maxLengthText = 150
      break
    default:
      maxLengthText = 400
  }
  return keyValue.length <= maxLengthText
}

export const ezSheetColumns = ({
  currentCustomer,
  isConsolidate,
  extraInfos,
  byOptionsColumns = []
}) => {
  const isCompany = currentCustomer.currentCompanyId
  const hasFavoriteDrivers = currentCustomer.have_favorite_drivers
  const allColumns = _.values(EZ_COLUMNS)
  const individualColumns = [
    EZ_COLUMNS.CashPayor
  ]

  // add dynamic column into table
  if (_.size(byOptionsColumns) > 0) {
    const extraIndex = _.indexOf(allColumns, EZ_COLUMNS.ExtraRequirement) + 1
    for (let i = 0; i < _.size(byOptionsColumns); i += 1) {
      const item = byOptionsColumns[i]
      allColumns.splice(extraIndex + i, 0, item)
    }
  }

  // verify column account bussiness
  const exceptColumns = isCompany ? individualColumns : []
  if (!isCompany || !extraInfos.enable_parking_tolls_feature) {
    exceptColumns.push(EZ_COLUMNS.ReimbursementToll)
    exceptColumns.push(EZ_COLUMNS.ReimbursementParking)
    exceptColumns.push(EZ_COLUMNS.ReimbursementWaiting)
  }

  if (!isConsolidate) { exceptColumns.push(EZ_COLUMNS.Consolidate) }
  if (!hasFavoriteDrivers && currentCustomer.authentication_token) { exceptColumns.push(EZ_COLUMNS.FavoriteDriver) }

  const colums = _.difference(allColumns, exceptColumns)
  return colums
}

const errorMapping = (key, gridColumns) => {
  const ezColumns = _.values(gridColumns)
  const mapping = {
    vehicle: _.indexOf(ezColumns, EZ_COLUMNS.VehicleType),
    timeType: _.indexOf(ezColumns, EZ_COLUMNS.TimeType),
    extraRequirement: _.indexOf(ezColumns, EZ_COLUMNS.ExtraRequirement),
    pickupTime: _.indexOf(ezColumns, EZ_COLUMNS.PickupDateTime),
    name: _.indexOf(ezColumns, EZ_COLUMNS.LocationAddress),
    recipient_name: _.indexOf(ezColumns, EZ_COLUMNS.LocationContactName),
    recipient_phone: _.indexOf(ezColumns, EZ_COLUMNS.LocationContactPhone),
    locationCodPod: _.indexOf(ezColumns, EZ_COLUMNS.LocationCODPOD),
    codLimitAmount: _.indexOf(ezColumns, EZ_COLUMNS.LocationCODAmount),
    cod_invoice_fees: _.indexOf(ezColumns, EZ_COLUMNS.LocationCODAmount),
    location_note: _.indexOf(ezColumns, EZ_COLUMNS.LocationNote),
  }
  return mapping[key]
}

const getCellOffset = (bookings, bookingIndex, locationIndex) => {
  let rowIndex = 0
  for (let i = 0; i < bookingIndex; i += 1) {
    rowIndex += bookings[i].locations.length
  }
  return rowIndex + locationIndex
}

export const getFirstErrorCell = (bookings, gridColumns) => {
  let bookingIndex
  let locationIndex = 0
  let errorKey
  let index = 0
  while (index < bookings.length && _.isUndefined(errorKey)) {
    const booking = bookings[index]
    bookingIndex = index
    errorKey = _.findKey(booking.verify, val => !val)
    if (_.isUndefined(errorKey)) {
      let lIndex = 0
      while (lIndex < booking.locations.length && _.isUndefined(errorKey)) {
        locationIndex = lIndex
        errorKey = _.findKey(booking.locations[lIndex].verify, val => !val)
        lIndex += 1
      }
    }
    index += 1
  }
  if (errorKey) {
    if (errorKey === 'pickupTime') { locationIndex = 0 }
    const rowIndex = getCellOffset(bookings, bookingIndex, locationIndex)
    return [rowIndex, errorMapping(errorKey, gridColumns)]
  }
  return [-1, -1]
}

export const filterEmptyLocations = locations => _.filter(
  locations, l => (!_.isUndefined(l.lat) && !_.isUndefined(l.lng)) || (_.size(l.name) > 0)
)

export const checkBatchNeedConfirm = batch => batch.need_confirmation === true

export const syncBookingsForAssignDriver = (bookings, batchBookings) => _.map(bookings, (booking) => {
  const batchBooking = _.find(batchBookings, { id: _.toInteger(booking.id) })
  if (_.isUndefined(batchBooking)) {
    return _.assign({}, booking)
  }
  const numberDriverTimeout = _.isUndefined(booking.number_driver_timeout)
    ? batchBooking.number_driver_timeout : booking.number_driver_timeout

  let tallyField = {}
  if (batchBooking.tally_enabled === true) {
    tallyField = {
      tally_enabled: batchBooking.tally_enabled,
      original_estimate_values: batchBooking.original_estimate_values,
      current_estimate_values: batchBooking.current_estimate_values,
      current_estimate_status: batchBooking.current_estimate_status,
      vehicle_type_threshold_early_delay_status: batchBooking.vehicle_type_threshold_early_delay_status,
      last_drop_off_arrived_at: batchBooking.last_drop_off_arrived_at,
    }
  }
  return _.assign({}, booking, {
    allow_customer_cancel: batchBooking.allow_customer_cancel,
    estimated_working_times: batchBooking.estimated_working_times,
    number_driver_timeout: numberDriverTimeout,
    full_day_selected_amount: batchBooking.full_day_selected_amount,
    sub_account_tag: booking.id
      ? batchBooking.sub_account_tag
      : booking.sub_account_tag,
    ...tallyField
  })
})

export const addMoreColumnFreeze = (defaultColumn, gridColumns, exceptColumns) => {
  const colums = _.difference(gridColumns, exceptColumns)
  return defaultColumn + (_.size(gridColumns) - _.size(colums))
}

export const omitUnnessaryBookingKey = bookings => _.map(bookings, booking => _.omit(booking, 'use_credit'))

export const calcPriceRegularBooking = (numDropOff, extraService) => _.map(extraService, (extra) => {
  let price = 0
  const { extra_requirement_vehicle_type_normal_pricings: extraNormalPricing, pricing_method: pricingMethod } = extra
  if (!_.isEmpty(extraNormalPricing) && pricingMethod === EXTRA_NORMAL) {
    _.forEach(extraNormalPricing, (item) => {
      if (item.dropoff_number <= numDropOff) {
        price += item.fees
      }
    })
    return {
      ...extra,
      unit_price: price,
    }
  }
  return extra
})

export const getUrlSearchParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  return Object.fromEntries(urlSearchParams.entries())
}

export const updateBookingTimeTypeToSchedule = (bookings, longHaulList) => {
  const newBookings = [...bookings]
  longHaulList.forEach((item) => {
    const booking = newBookings.find(i => i.temp_id === item.temp_id)
    if (booking) {
      booking.time_type_option = {
        ...booking.time_type_option,
        ...SCHEDULE_TIME_TYPE
      }
    }
  })
  return newBookings
}

export const filterBookingByCheckbox = (bookings, filterBy) => _.filter(bookings, (booking) => {
  const acceptStatuses = [STATUS_DRIVER_ACCEPT_BOOKING, STATUS_FLEET_ACCEPT_BOOKING, STATUS_FLEET_TIMEOUT]
  const timeType = booking.time_type
  const vehicleType = booking.vehicle_type
  const periodTime = !_.isEmpty(vehicleType) && vehicleType?.settings?.sending_eta_sms_period
    ? (_.toFinite(vehicleType.settings.sending_eta_sms_period) * 3600 * 1000) : null
  const atPickup = _.get(booking, 'locations[0].arrived_at')
  if (acceptStatuses.includes(booking.status)
      && timeType !== 'full_day'
      && (timeType === 'now' || atPickup
      || (periodTime && (periodTime + _.toInteger(moment()) > booking.pickup_time * 1000)))
  ) {
    if (filterBy.includes(STATUS_TO_AT_PICKUP)) {
      return true
    }
    return false
  }
  if (booking.status === STATUS_DELIVERY_COMPLETED
    && booking.need_confirmation
  ) {
    if (filterBy.includes(STATUS_REIMBURSE)) {
      return true
    }
    return false
  }
  if (booking.status === STATUS_DELIVERY_COMPLETED
    && !booking.need_confirmation
    && filterBy.includes(STATUS_DELIVERY_COMPLETED)
  ) {
    return true
  }
  return filterBy.indexOf(booking.status) !== -1
})
export const checkCustomStatusFilter = (booking) => {
  const timeType = booking.time_type
  const vehicleType = booking.vehicle_type
  const periodTime = !_.isEmpty(vehicleType) && vehicleType?.settings?.sending_eta_sms_period
    ? (_.toFinite(vehicleType.settings.sending_eta_sms_period) * 3600 * 1000) : null
  const atPickup = _.get(booking, 'locations[0].arrived_at')
  const acceptStatuses = [STATUS_DRIVER_ACCEPT_BOOKING, STATUS_FLEET_ACCEPT_BOOKING, STATUS_FLEET_TIMEOUT]
  if (acceptStatuses.includes(booking.status) && timeType !== 'full_day'
  && (timeType === 'now' || atPickup
  || (periodTime && (periodTime + _.toInteger(moment()) > booking.pickup_time * 1000)))
  ) {
    return true
  }
  return false
}

const getAllVehicleTypeId = (vehicleTypesData) => {
  const finalList = []
  vehicleTypesData.forEach(item => finalList.push(item.id))
  return finalList
}

export const getExtraServicesData =  (
   currentCustomer, setStatusGetAllData, isSmartPlanner = false
) =>async dispatch=> {
  const areaId = localStorage.getItem('areaId')
  const companyId = currentCustomer.currentCompanyId
  const vehicleTypesData = await getVehicleTypesAction(areaId, companyId, isSmartPlanner)(dispatch)
  if (!_.isEmpty(vehicleTypesData)) {
    const arrPromise = []
    const vehicleTypeIds = getAllVehicleTypeId(vehicleTypesData)
    const params = {
      areaId, vehicleTypeIds, companyId, isFullDay: true, isLongHaul: true
    }
    if (isSmartPlanner) delete params.isLongHaul
    const extraRequirementsRegular = 
      getExtraRequirementVehicleTypesAction(params, EXTRA_REQUIREMENT_TYPE.EXTRA_REQUIREMENTS_REGULAR)(dispatch)
    const extraRequirementsLongHaul = 
     getExtraRequirementVehicleTypesAction(params, EXTRA_REQUIREMENT_TYPE.EXTRA_REQUIREMENTS_LONG_HAUL)(dispatch)
    const extraRequirementsFullDay = 
     getExtraRequirementVehicleTypesAction(params, EXTRA_REQUIREMENT_TYPE.EXTRA_REQUIREMENTS_FULL_DAY)(dispatch)
    const regularByOptionExtras = 
     getExtraRequirementVehicleTypesAction(params, EXTRA_REQUIREMENT_TYPE.REGULAR_BY_OPTION_EXTRAS)(dispatch)
    const fullDayByOptionExtras = 
     getExtraRequirementVehicleTypesAction(params, EXTRA_REQUIREMENT_TYPE.FULL_DAY_BY_OPTION_EXTRAS)(dispatch)
    const longHaulByOptionExtras = 
     getExtraRequirementVehicleTypesAction(params, EXTRA_REQUIREMENT_TYPE.LONG_HAUL_BY_OPTION_EXTRAS)(dispatch)
    const customReimbursementsEzSheet = 
     getCustomReimbursementsForEzSheetAction(vehicleTypeIds, companyId)(dispatch)
    arrPromise.push(extraRequirementsRegular)
    arrPromise.push(extraRequirementsLongHaul)
    arrPromise.push(extraRequirementsFullDay)
    arrPromise.push(regularByOptionExtras)
    arrPromise.push(fullDayByOptionExtras)
    arrPromise.push(longHaulByOptionExtras)
    arrPromise.push(customReimbursementsEzSheet)
    if (companyId) {
      const vehicleTypeBadges = getVehicleTypeBadgesAction(vehicleTypeIds, companyId)(dispatch)
      const companyBadgesData = getCompanyBadgesAction(companyId)(dispatch)
      arrPromise.push(vehicleTypeBadges)
      arrPromise.push(companyBadgesData)
    }
    Promise.all(arrPromise).then(() => setStatusGetAllData())
  }
}