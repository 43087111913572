import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { get } from 'lodash-es'
import Locations from '../locations/Locations'
import PickupTime from '../pickup_time/PickupTime'
import ServiceType from '../service_type/ServiceType'
import VehicleType from '../vehicle_type/VehicleType'
import I18n from 'i18n/i18n'
class StepOneContent extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      isShowTooltips: false,
      isShowTooltipsCalendar: false,
    }
    this.updateData = this.updateData.bind(this)
    this.validDateRange = this.validDateRange.bind(this)
  }

  getUrlByCountryCode() {
    const { extraInfos } = this.props
    const countryCode = get(extraInfos, 'country_code')
    const currentLang = I18n.language.toLowerCase()
    switch (countryCode.toLowerCase()) {
      case 'id':
        if (currentLang === 'en') {
          return 'https://www.deliveree.com/id/en/partial-load-faq/'
        }
        return 'https://www.deliveree.com/id/muat-sebagian-faq/'
      case 'th':
        if (currentLang === 'en') {
          return 'https://www.deliveree.com/th/en/partial-load-service/'
        }
        return 'https://www.deliveree.com/th/partial-load-service/'
      default:
        return 'https://www.transportify.com.ph/partial-load-faq/'
    }
  }
  /**
   * validate range date valid
   * @param {moment} currentDate value of each date on calendar
   */
  // eslint-disable-next-line class-methods-use-this
  validDateRange(currentDate) {
    const minDate = moment().subtract(1, 'days')
    const maxDate = moment(minDate).add(15, 'days')
    return currentDate.isBetween(minDate, maxDate)
  }

  /**
   * update data to avoid es-lint
   * @param {Array} newData list item after formated
   */
  updateData(newData) {
    const { data } = this.state
    if (newData.length !== data.length) {
      this.setState({ data: newData })
    }
  }

  render() {
    const {
      setIconComingSoonService,
      defaultValue,
      area,
      currentStep,
      isEditWebMultipleBooking,
      outsideList,
      setOutsideList,
      errorDistanceList,
      setListErrorDistance,
      isHaveLHAddress,
      isShowChangeTimeTypeClick,
      setIsShowChangeTimeTypeClick,
      isShowPopupChangePickupLocation,
      handleErrorPickupLocation,
      setShowComingSoonService,
      handleShowChangePickupLocation,
      extendedList,
      setComingSoonServiceTypeName,
      setIsShowPickupTimePopup,
      isEnableGoogleMap
    } = this.props
    return (
      <div className="Step-One-Content">
          <div>
            <ServiceType
              defaultValue={defaultValue}
              setComingSoonServiceTypeName={setComingSoonServiceTypeName}
              setShowComingSoonService={setShowComingSoonService}
              setIconComingSoonService={setIconComingSoonService}
              setIsShowPickupTimePopup={setIsShowPickupTimePopup}
            />
            <VehicleType setIsShowPickupTimePopup={setIsShowPickupTimePopup} />
            <PickupTime
              isEditWebMultipleBooking={isEditWebMultipleBooking}
              setIsShowChangeTimeTypeClick={setIsShowChangeTimeTypeClick}
              isShowChangeTimeTypeClick={isShowChangeTimeTypeClick}
              extendedList={extendedList}
            />
          </div>

        <Locations
          area={area}
          currentStep={currentStep}
          outsideList={outsideList}
          setOutsideList={setOutsideList}
          errorDistanceList={errorDistanceList}
          setListErrorDistance={setListErrorDistance}
          isHaveLHAddress={isHaveLHAddress}
          isShowPopupChangePickupLocation={isShowPopupChangePickupLocation}
          handleErrorPickupLocation={handleErrorPickupLocation}
          handleShowChangePickupLocation={handleShowChangePickupLocation}
          isEnableGoogleMap={isEnableGoogleMap}
        />
      </div>
    )
  }
}

StepOneContent.propTypes = {
  isHaveLHAddress: PropTypes.bool.isRequired,
  isEditWebMultipleBooking: PropTypes.bool,
  currentStep: PropTypes.number,
  area: PropTypes.shape({}).isRequired,
  defaultValue: PropTypes.shape({}),
  booking: PropTypes.shape({}),
  handleViewStepOne: PropTypes.func,
  currentCustomer: PropTypes.shape({}).isRequired,
  outsideList: PropTypes.instanceOf(Array),
  errorDistanceList: PropTypes.instanceOf(Array),
  setListErrorDistance: PropTypes.func,
  setOutsideList: PropTypes.func,
  countryCode: PropTypes.string,
  isShowChangeTimeTypeClick: PropTypes.bool.isRequired,
  setIsShowChangeTimeTypeClick: PropTypes.func.isRequired,
  setShowComingSoonService: PropTypes.func.isRequired,
  extraInfos: PropTypes.shape({
    country_code: PropTypes.string,
    area_id: PropTypes.number,
  }).isRequired,
  isShowPopupChangePickupLocation: PropTypes.bool,
  handleErrorPickupLocation: PropTypes.func,
  handleShowChangePickupLocation: PropTypes.func,
  extendedList: PropTypes.instanceOf(Array),
  setComingSoonServiceTypeName: PropTypes.func,
  setIconComingSoonService: PropTypes.func,
  setIsShowPickupTimePopup: PropTypes.func.isRequired,
  initDatePickup: PropTypes.shape({}),
}

StepOneContent.defaultProps = {
  isEditWebMultipleBooking: false,
  outsideList: [],
  errorDistanceList: [],
  currentStep: 0,
  defaultValue: {},
  booking: {},
  handleViewStepOne: () => undefined,
  setOutsideList: () => undefined,
  setListErrorDistance: () => undefined,
  countryCode: '',
  isShowPopupChangePickupLocation: false,
  handleErrorPickupLocation: () => undefined,
  handleShowChangePickupLocation: () => undefined,
  extendedList: [],
  setComingSoonServiceTypeName: () => undefined,
  setIconComingSoonService: '',
  initDatePickup: {},
}

export default StepOneContent
