import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ModalNormal from 'components/common/Modal/ModalNormal'
import { theme } from 'components/common/Styled/STheme'
import STypography from 'components/common/Styled/STypography'
import { BUSINESS_DEFAULT_LOGO, IMAGE_PLACE_HOLDER } from 'constants/imageConstants'
import { BlockCompanyWrapper, ContentWrapper, ModalWrapper } from './Sindex'
import { useAppDispatch, useAppSelector } from 'store/store'
import { BLOCKED_STATUS } from 'constants/companyConstants'
import SFlex from 'components/common/Styled/SLayout/SFlex'
import SButton from 'components/common/Styled/SButton'
import { accountManageActions } from 'store/toolkit/accountManage/accountManage.reducer'
import { GO_TO_STEP1_SWITCH_MODAL } from 'components/new_booking/guest_flow/constants'

const ChooseAccount: React.FC<{
  setSelectedEmployId: (companyId: number) => void
}> = ({ setSelectedEmployId }) => {
  const { t } = useTranslation()
  const [isShowBlockCompany, setIsShowBlockCompany] = useState(false)
  const currentCustomer = useAppSelector((s) => s.currentCustomer)
  const { employs, companies, currentCompanyId } = currentCustomer
  const dispatch = useAppDispatch()
  const placeTarget = document.getElementById('step-wrapper') || undefined

  const handleSelectedItem = (selectedEmployId: number) => {
    setSelectedEmployId(selectedEmployId);
    dispatch(accountManageActions.updateModalAccountManage(GO_TO_STEP1_SWITCH_MODAL))
  }

  return (
    <>
      <ModalNormal
        onClickClose={() => dispatch(accountManageActions.updateModalAccountManage(''))}
        placeTarget={placeTarget}
        targetParent={true}
        closeVariant="g1"
        customFixedWidth={'378px'}
        customMinWidth={378}
        backgroundColor={theme.colors.green[1]}
        isOpen
      >
        <ModalWrapper>
          <STypography fw={700} pt={5} pb={15} cVar="w1" fs={17}>
            {t('webapp.label.billing_account')}
          </STypography>
          <ContentWrapper>
            <STypography fw={700} pt={8} pb={8} pl={15} bgColor={theme.colors.gray[11]} fs={13}>
              {t('business.choose_account.personal_account')}
            </STypography>
            <SFlex
              onClick={() => currentCompanyId && handleSelectedItem(0)}
              className="account-container"
              icenter
              p={15}
            >
              <img width={50} height={50} src={currentCustomer.avatar_square === 'missing.png' ? IMAGE_PLACE_HOLDER : currentCustomer.avatar_square} alt="" />
              <div className="info">
                <STypography color={theme.colors.green[1]} fs={15}>
                  {currentCustomer.name}
                </STypography>
                <STypography fs={12}>{currentCustomer.email}</STypography>
              </div>
              <div className="Overlay-RadioBox Green-RadioBox With-Icon">
                <input type="radio" checked={currentCustomer.currentCompanyId === 0} />
                <label>
                  <i className="b.material-icons.Icon">fiber_manual_record</i>
                </label>
              </div>
            </SFlex>
            {!!employs?.length && (
              <div>
                <STypography fw={700} pt={8} pb={8} pl={15} bgColor={theme.colors.gray[11]} fs={13}>
                  {t('business.choose_account.business_accounts')}
                </STypography>
                {employs?.map((employ) => {
                  const companyOwn = companies?.find((c) => c.id === employ.company_id)
                  const activeCompany = companyOwn?.status !== BLOCKED_STATUS
                  const selected = companyOwn?.id === currentCompanyId
                  return (
                    <SFlex
                      icenter
                      p={15}
                      className={`account-container ${companyOwn?.id === currentCompanyId && 'Selected'}`}
                      key={employ.id}
                      onClick={() => {
                        if (!selected) {
                          if (!activeCompany) setIsShowBlockCompany(true)
                          else handleSelectedItem(employ.company_id)
                        }
                      }}
                    >
                      <img
                        width={50}
                        height={50}
                        src={companyOwn?.photo_url || BUSINESS_DEFAULT_LOGO}
                        alt="business logo"
                      />

                      <div className="info">
                        <STypography fs={15}>{companyOwn?.name}</STypography>
                        <STypography fs={12}>{employ.invited_email}</STypography>
                      </div>
                      <div className="Overlay-RadioBox Green-RadioBox With-Icon">
                        <input type="radio" checked={selected} />
                        <label>
                          <i className="b.material-icons.Icon">fiber_manual_record</i>
                        </label>
                      </div>
                    </SFlex>
                  )
                })}
              </div>
            )}
          </ContentWrapper>
        </ModalWrapper>
      </ModalNormal>
      {isShowBlockCompany && (
        <ModalNormal
          targetParent={true}
          placeTarget={placeTarget}
          closeVariant="g1"
          customMinWidth={380}
          customFixedWidth={'380px'}
          isOpen
        >
          <BlockCompanyWrapper>
            <STypography align="center" fs={16}>
              {t('errors.company.blocked')}
            </STypography>
            <SButton h="36px" onClick={() => setIsShowBlockCompany(false)} mt={20} w="100%" variant="g2">
              {t('button.close')}
            </SButton>
          </BlockCompanyWrapper>
        </ModalNormal>
      )}
    </>
  )
}

export default ChooseAccount
