import { API_GATEWAY } from 'constants/appConstants'
import apiClient from 'services/axiosApp'
import store from 'store/store'

const baseUrl = '/api/v3/vehicle_types'

const vehicleTypeAPIs = {
  getVehicleTypeInfo: async (vehicleTypeId) => apiClient.get(`${baseUrl}/${vehicleTypeId}`),
  getNumberOfOnlineDrivers: async (vehicleTypeId, location, callback) => {
    const params = {
      'location[lat]': location.lat && location.lat.toString().trim(),
      'location[lng]': location.lng && location.lng.toString().trim(),
    }
    const { extraInfos } = store.getState()
    const url = extraInfos?.enable_catalog_service
      ? `${API_GATEWAY}/v1/catalog/vehicle_types/${vehicleTypeId}/driver_count`
      : `/api/v3/vehicle_types/${vehicleTypeId}/count_number_of_driver`
    try {
      const res = await apiClient.get(url, { params })
      return callback(res?.data)
    } catch (err) {
      throw new Error(err)
    }
  },
}

export default vehicleTypeAPIs
