import { API_GATEWAY } from 'constants/appConstants'
import apiClient from 'services/axiosApp'
import store from 'store/store'

const SubAccountAPI = {
  getSubAccountTagList: async (params, callback) => {
    try {
      const { extraInfos } = store.getState()
      const url = extraInfos?.enable_catalog_service
        ? `${API_GATEWAY}/v1/catalog/customers/me/sub_accounts`
        : '/api/v3/sub_accounts'

      const res = await apiClient.get(url, {
        params,
        headers: {
          'Device-Type': 'Web',
        },
      })
      return callback(res || {})
    } catch (err) {
      throw new Error(err)
    }
  },
  getSubAccountTags: (params) => {
    const { extraInfos } = store.getState()
    const url = extraInfos?.enable_catalog_service
      ? `${API_GATEWAY}/v1/catalog/customers/me/sub_accounts`
      : '/api/v3/sub_accounts'
    return apiClient.get(url, {
      params,
      headers: {
        'Device-Type': 'Web',
      },
    })
  },
}

export default SubAccountAPI
