import { PUBLIC_URL } from 'constants/appConstants'
import StorageKeys from 'constants/storage-keys'
import { CrossStorageClient } from 'cross-storage'
import { IDecodeToken } from 'interface/IAccountManage'
import jwt_decode from 'jwt-decode'
import { initApp } from './initApp'

let crossStorageClient: CrossStorageClient | null = null

const getCrossStorageClient = () => {
  if (!crossStorageClient) {
    crossStorageClient = new CrossStorageClient(`${PUBLIC_URL}/hub.html`, { timeout: 10000 })
  }
  return crossStorageClient
}

const handleInitToken = async () => {
  const accessTokenLocal = localStorage.getItem(StorageKeys.ACCESS_TOKEN) || ''
  try {
    const storage = getCrossStorageClient()
    await storage.onConnect()
    const accessTokenStorage = await storage.get(StorageKeys.ACCESS_TOKEN)
    let token = accessTokenLocal

    if (accessTokenStorage && accessTokenLocal && accessTokenStorage !== accessTokenLocal) {
      const accessTokenStorageParse = jwt_decode<IDecodeToken>(accessTokenStorage)
      const accessTokenLocalParse = jwt_decode<IDecodeToken>(accessTokenLocal)
      if (accessTokenStorageParse.iat > accessTokenLocalParse.iat) token = accessTokenStorage
    }
    crossStorageUtils.setAccessToken(token)
  } catch (error) {
    console.debug(error)
  }
}

const handleConnectCrossStorage = async () => {
  try {
    const storage = getCrossStorageClient()
    await storage.onConnect()
    const accessToken = await storage.get(StorageKeys.ACCESS_TOKEN)
    crossStorageUtils.setAccessToken(accessToken || '')
  } catch (error) {
    console.debug(error)
  }
}

export const crossStorageUtils = {
  setAccessToken: async (accessToken: string) => {
    localStorage.setItem(StorageKeys.ACCESS_TOKEN, accessToken)
    if (accessToken) {
      const tokenInfo = jwt_decode<IDecodeToken>(accessToken)
      initApp.updateCompanyFromToken(tokenInfo.company_id)
    }
    try {
      const storage = getCrossStorageClient()
      await storage.onConnect()
      return storage.set(StorageKeys.ACCESS_TOKEN, accessToken)
    } catch (error) {
      console.debug(error)
    }
  },
  handleListenMessage: (event: { origin: any; data: string }) => {
    const authenticationToken = window.localStorage.getItem(StorageKeys.ACCESS_TOKEN) || ''
    if (event.origin === PUBLIC_URL) {
      let accessToken: any = typeof event.data === 'string' && event.data?.split(':')
      if (accessToken && accessToken?.length && accessToken[0] === 'auth') {
        accessToken = accessToken[1] || ''
        if (accessToken !== authenticationToken) {
          crossStorageUtils.setAccessToken(accessToken)
          window.location.reload()
        }
        if (document.hidden) window.location.reload()
      }
    }
  },
  connectToken: (authenticationToken: string) => {
    if (authenticationToken) {
      handleInitToken()
    } else {
      handleConnectCrossStorage()
    }
  },
}
