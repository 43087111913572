import _ from 'lodash'
// UTILS
import locationUtils from 'utils/common/location'
import { Utils } from 'utils/Utils'
import roundTripDiscountUtils from 'utils/common/booking/roundTripDiscount'
import { vehicleTypes as vehicleTypesUtils } from 'utils/new_booking/common'
// API
// ACTIONS
import * as locationActions from './locationActionCreators'
// CONSTANTS
import * as IMAGES from 'constants/imageConstants'
import { setDefaultMaxLocation } from 'utils/booking/common'
import { roundTripDiscountActionsCreator } from 'store/toolkit/newBooking/roundTripDiscount.reducer'
import { locationsActionsCreator } from 'store/toolkit/locations/locations.reducer'
// ASSETS

export const updateRoundTripValue = (value) => (dispatch, getState) => {
  const { isOptimized, beforeOptimizedLocations } = getState()
  const tempLocationsBeforeOptimize = [...beforeOptimizedLocations]
  Promise.resolve(dispatch(roundTripDiscountActionsCreator.updateRoundTripDiscount(value))).then(() => {
    if (isOptimized) {
      dispatch(locationActions.optimizeLocations(tempLocationsBeforeOptimize))
    }
    dispatch(locationActions.drawBookingLocationRoute())
  })
}

//Helper for function update
const createLastLegMarker = (pickup, getState) => {
  if (window?.google?.maps) {
    return new window.google.maps.Marker({
      position: new window.google.maps.LatLng(pickup.lat, pickup.lng),
      map: getState().googleMap.map,
      draggable: true,
      optimized: false,
      icon: IMAGES.ROUND_TRIP_DISCOUNT_PIN_NONE_ICON,
    })
  }
  return null
}

const handleAddOrUpdateLocation = ({
  dispatch,
  totalDropOffs,
  destination,
  isDesEqualPickup,
  attrs,
  currentCustomer,
  extraInfos
}) => {
  if (totalDropOffs === 2 && isDesEqualPickup) {
    dispatch(locationActions.addLocation(attrs))
  } else if (
    totalDropOffs === setDefaultMaxLocation(currentCustomer, extraInfos) &&
    _.isUndefined(destination.lat) &&
    _.isUndefined(destination.lng)
  ) {
    dispatch(locationsActionsCreator.updateLocation({ id: destination.id, locationAttrs: attrs }))
  } else if (!isDesEqualPickup) {
    dispatch(locationActions.addLocation(attrs))
  }
}

const handleOptimization = ({
  isOptimized,
  isDesEqualPickup,
  tempLocationsBeforeOptimize,
  pickup,
  extraInfos,
  lastLegMarker
}) => {
  const newTempLocationsBeforeOptimize = [...tempLocationsBeforeOptimize]

  if (isOptimized && !isDesEqualPickup) {
    const lastLeg = {
      ...pickup,
      id: Utils.uniqueId(),
      is_payer: extraInfos.default_payer_is_destination,
      marker: lastLegMarker,
    }
    newTempLocationsBeforeOptimize.push(lastLeg)
  }

  return newTempLocationsBeforeOptimize
}

const handleRemoveLocation = ({ dispatch, totalDropOffs, pickup, locations, tempLocationsBeforeOptimize, isOptimized }) => {
  const newTempLocationsBeforeOptimize = [...tempLocationsBeforeOptimize]

  if (isOptimized) {
    newTempLocationsBeforeOptimize.pop()
  }

  if (totalDropOffs === 2 && !_.isUndefined(pickup.lat)) {
    dispatch(locationActions.addLocation())
  }

  dispatch(locationActions.removeLocation(_.last(locations)))

  return newTempLocationsBeforeOptimize
}

const updateRoundTripAndOptimize = ({ dispatch, value, isOptimized, tempLocationsBeforeOptimize }) => {
  Promise.resolve(dispatch(roundTripDiscountActionsCreator.updateRoundTripDiscount(value))).then(() => {
    if (isOptimized) {
      dispatch(locationActions.optimizeLocations(tempLocationsBeforeOptimize))
    }
    dispatch(locationActions.drawBookingLocationRoute())
  })
}
//End Helper for function update

export const update =
  (value, updateValueOnly = false) =>
  (dispatch, getState) => {
    if (updateValueOnly) {
      dispatch(updateRoundTripValue(value))
      return
    }

    const { locations, currentCustomer, extraInfos, isOptimized, beforeOptimizedLocations } = getState()

    const pickup = _.first(locations)
    const destination = _.last(locations)
    let tempLocationsBeforeOptimize = [...beforeOptimizedLocations]

    const locationPickup = locationUtils.locationPosition(pickup)
    const totalDropOffs = _.size(locations)

    const lastLegMarker = createLastLegMarker(pickup, getState)
    const attrs = {
      description: pickup.description,
      lat: locationPickup.lat,
      lng: locationPickup.lng,
      name: pickup.name,
      recipient_name: pickup.recipient_name,
      recipient_phone: pickup.recipient_phone,
      marker: lastLegMarker,
      phone_mask: pickup.phone_mask,
      address_components: pickup.address_components,
    }

    if (value) {
      const isDesEqualPickup =
        locationUtils.locationPosition(destination).lat === attrs.lat &&
        locationUtils.locationPosition(destination).lng === attrs.lng
      handleAddOrUpdateLocation({
        dispatch,
        totalDropOffs,
        destination,
        isDesEqualPickup,
        attrs,
        currentCustomer,
        extraInfos
      })
      tempLocationsBeforeOptimize = handleOptimization({
        isOptimized,
        isDesEqualPickup,
        tempLocationsBeforeOptimize,
        pickup,
        extraInfos,
        lastLegMarker
      })
    } else {
      tempLocationsBeforeOptimize = handleRemoveLocation({
        dispatch, 
        totalDropOffs, 
        pickup, 
        locations, 
        tempLocationsBeforeOptimize, 
        isOptimized
      })
    }

    updateRoundTripAndOptimize({
      dispatch, 
      value, 
      isOptimized, 
      tempLocationsBeforeOptimize
    })
  }

export const updateIfRoundTripDiscountShouldShowChanged = (vehicleTypeID) => (dispatch, getState) => {
  const { locations, roundTripDiscount, timeType, currentCustomer, extraInfos } = getState()
  if (!roundTripDiscount) {
    return
  }
  const vehicleType = _.find(vehicleTypesUtils(getState()), { id: vehicleTypeID })
  const enableRoundTrip = _.isUndefined(vehicleType) ? [] : vehicleType.settings.enable_round_trip
  const shouldShowRT = roundTripDiscountUtils.shouldShow(
    locations,
    roundTripDiscount,
    timeType,
    currentCustomer.currentCompanyId,
    enableRoundTrip,
    setDefaultMaxLocation(currentCustomer, extraInfos),
    extraInfos
  )
  if (!shouldShowRT && roundTripDiscount) {
    dispatch(update(false))
  }
}
