import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import SButton from 'components/common/Styled/SButton'
import STypography from 'components/common/Styled/STypography'
import { AccountWrapper, ContentWrapper } from './Sindex'
import { UtilsHTML } from 'utils/html'
import { isEditBooking } from 'utils/booking/common'
import { useAppSelector } from 'store/store'
import accountAction from 'store/actions/common/accountAction'
import { useDispatch } from 'react-redux'
import { checkSubAccountActionsCreator } from 'store/toolkit/checkSubAccount/checkSubAccount.reducer'
import { bookingAgainDetailsActionsCreator } from 'store/toolkit/newBooking/bookingAgainDetails.reducer'
import { subAccountTagPickedActionsCreator } from 'store/toolkit/subAccountTagPicked/subAccountTagPicked.reducer'
import SFlex from 'components/common/Styled/SLayout/SFlex'
import { accountManageActions } from 'store/toolkit/accountManage/accountManage.reducer'
import { SWITCH_ACCOUNT_MODAL } from 'components/new_booking/guest_flow/constants'
import SubAccountTag from 'components/common/sub_account_tag/SubAccountTag'
import SCommon from 'components/common/Styled/SCommon/SCommon'
import { isEmpty } from 'lodash'
import { theme } from 'components/common/Styled/STheme'
import { IMAGE_PLACE_HOLDER } from 'constants/imageConstants'

const SwitchAccount: React.FC<{
  isCheckedSubAccount?: boolean
  subAccountTagPicked: { sub_account_id: number; sub_account_name: string }
}> = ({ subAccountTagPicked, isCheckedSubAccount }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const bookAgainDetails = useAppSelector((s) => s.bookAgainDetails)
  const subAccountTagList = useAppSelector((s) => s.subAccountTagList)
  const currentCustomer = useAppSelector((s) => s.currentCustomer)
  const isBusiness = !!currentCustomer?.currentCompanyId
  const selectedCompany = currentCustomer?.companies?.find((item) => item?.id === currentCustomer?.currentCompanyId)
  const selectedEmploy = currentCustomer.employs?.find(
    (item) => item?.company_id === currentCustomer?.currentCompanyId
  )
  const icon = isBusiness ? selectedCompany?.photo_url : currentCustomer?.avatar_square
  const email = isBusiness ? selectedEmploy?.invited_email : currentCustomer?.email
  const accountName = isBusiness ? selectedCompany?.name : 'Personal'
  const isRenderAccount = !isEditBooking() && !!currentCustomer?.companies?.length

  const hasSubAccountTagInBookAgain = !!(
    bookAgainDetails?.sub_account_tag && bookAgainDetails?.sub_account_tag.sub_account_id
  )
  const hasListSubAccountTag = !!(subAccountTagList && subAccountTagList.length)
  const isHideBlock =
    isEmpty(currentCustomer) ||
    !currentCustomer.authentication_token ||
    (isEditBooking() && !hasSubAccountTagInBookAgain && !hasListSubAccountTag)

  const onCheckSubAccountTag = (value: any) => {
    dispatch(checkSubAccountActionsCreator.updateSubAccountCheckBox({ value }))
    if (!value) {
      if (bookAgainDetails)
        dispatch(bookingAgainDetailsActionsCreator.updateBookAgainDetails({ sub_account_tag: null }))
      subAccountTagPickedActionsCreator.subAccountTagPicked({})
    }
  }

  useEffect(() => {
    accountAction.getSubAccountTags(currentCustomer?.currentCompanyId || 0)(dispatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {!isHideBlock && (
        <ContentWrapper>
          <STypography align="center" fw={700} fs={14}>
            {subAccountTagList.length > 0 || hasSubAccountTagInBookAgain
              ? !isEditBooking()
                ? UtilsHTML.renderSectionTitle(t('webapp.label.billing_and_sub_account'))
                : UtilsHTML.renderSectionTitle(t('webapp.label.sub_account_tag'))
              : UtilsHTML.renderSectionTitle(t('webapp.label.billing_account'))}
          </STypography>
          <AccountWrapper>
            {isRenderAccount && (
              <>
                <SFlex icenter fStart>
                  <img
                    src={icon === 'missing.png' ? IMAGE_PLACE_HOLDER : icon} width={52} alt={'avatar'}
                  />
                  <div className="info-account"> 
                    <STypography wordBreak>{accountName}</STypography>
                    <STypography wordBreak>{email}</STypography>
                  </div>
                  <SButton
                    fs={12}
                    bgColor={theme.colors.gray[1]}
                    onClick={() => {
                      dispatch(accountManageActions.updateModalAccountManage(SWITCH_ACCOUNT_MODAL))
                    }}
                  >
                    {t('webapp.long_haul.change')}
                  </SButton>
                </SFlex>
                <SCommon.Hr mt={15} mb={15} bgColor={'#d8d8d8'} />
              </>
            )}
            <SubAccountTag
              subAccountTagList={subAccountTagList}
              onCheckSubAccountTag={onCheckSubAccountTag}
              booking={{
                id: null,
                isCheckSubAccount: isCheckedSubAccount,
                sub_account_tag: {
                  sub_account_id: subAccountTagPicked.sub_account_id,
                  sub_account_name: subAccountTagPicked.sub_account_name,
                },
              }}
            />
          </AccountWrapper>
        </ContentWrapper>
      )}
    </>
  )
}

export default SwitchAccount
