import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

// UTILS
import { useDispatch } from 'react-redux'
import DimensionPopup from './popup/DimensionPopup'
import {
  ICON_ARROW_DOWN_DARK_GREEN,
  ICON_INFO_UNACTIVE,
  ICON_INFO_ACTIVE,
} from 'constants/imageConstants'
import { POPUP_TYPE_SERVICE_TYPE_POPUP_EYE } from 'constants/common/popupConstants'
import { havePopup } from 'utils/common/popupable'
import useOutsideAlerter from 'hooks/commonHooks'
import I18n from 'i18n/i18n'
import { updateEditModeText } from 'store/actions/new_booking/editModeTextActionCreators'

const DropdownServiceTypes = (props) => {
  const {
    serviceTypes, defaultServiceType, onChangeSelectService, onClickComingService,
    isDisabled, currentCustomer, objectMultiple
  } = props
  const [expanded, setExpanded] = useState(false)
  const dispatch = useDispatch()

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, () => setExpanded(false))

  const handleExpanded = () => {
    if (!isDisabled) {
      setExpanded(prevState => !prevState)
    } else {
      dispatch(updateEditModeText(I18n.t('webapp.booking.service_type_edit_mode')))
    }
  }

  const isActive = value => value === defaultServiceType.id

  const onclickServiceType = (isServiceTypeOnList, serviceType) => {
    if (isServiceTypeOnList) {
      setExpanded(false)
      if (serviceType.is_coming) {
        onClickComingService(serviceType, isDisabled)
      } else {
        onChangeSelectService(serviceType.id, isDisabled)
      }
    }
  }

  const renderServiceType = (
    serviceType,
    selectedService = true,
    isServiceTypeOnList = true,
  ) => {
    const { vehicle_types: vehicleTypes } = serviceType
    if (_.isEmpty(vehicleTypes)) return null
    const selected = selectedService ? 'selected' : ''
    let objMultiple = ''
    const hideThisServiceType = isServiceTypeOnList && serviceType?.is_hide ? ' hidden' : ''
    if (_.size(objectMultiple)) {
      objMultiple = `${objectMultiple.bookingId}-service-type-${serviceType.id}`
    }

    return (
      <div
        key={`${serviceType.id}-box`}
        className={`${objMultiple} block-item ${isServiceTypeOnList ? selected : 'Services-Block flex-index'}${hideThisServiceType}`}
        onClick={() => onclickServiceType(isServiceTypeOnList, serviceType)}
      >
        <label className={`cur-pointer Services-Icon Services-Icon-${serviceType.id} ${!isServiceTypeOnList && 'Services-Icon-Image mr10'}`}>
          <img
            src={serviceType.is_coming ? serviceType.inactive_icon_url : serviceType.active_icon_url}
            alt={serviceType.name}
          />
        </label>
        <label className={`cur-pointer ${isDisabled ? 'White-text-important' : ''} ${!isServiceTypeOnList ? 'default-font default-color font-weight-500' : ''}`}>
          {serviceType.name}
        </label>
        {havePopup(serviceType, POPUP_TYPE_SERVICE_TYPE_POPUP_EYE) && isServiceTypeOnList && (
          <DimensionPopup
            serviceType={serviceType}
            currentCustomer={currentCustomer}
            objectID={serviceType.id}
            iconEye={selected ? ICON_INFO_ACTIVE : ICON_INFO_UNACTIVE}
            configStyle={_.size(objectMultiple) ? { id: objectMultiple.bookingId, scale: objectMultiple.scale } : {}}
          />
        )}
      </div>
    )
  }

  const renderListServiceType = () => (
    <div className={`Services Services-List Custom-Scroll-Group Default-Scroll-Services White-bg z-index-222 ${expanded ? 'block' : 'hidden'} list-view ${isDisabled ? 'Ceb-disable-field' : ''}`}>
      {serviceTypes.map(serviceType => renderServiceType(serviceType, isActive(serviceType.id)))}
    </div>
  )

  return (
    <div
      className="Radius-default White-bg mt20 mar20 mr10 ml10 relative"
      ref={wrapperRef}
    >
      <div
        className={`${expanded ? 'Services-List-Border' : ''} flex flex-center-algin cur-pointer min-h52px  ${isDisabled ? 'Ceb-disable-field Hidden-bg Radius-default' : ''}`}
        onClick={() => handleExpanded()}
      >
        {renderServiceType(defaultServiceType, false, false)}
        {!isDisabled && <img src={ICON_ARROW_DOWN_DARK_GREEN} className={`mr15 min-w-16 Services-Icon-Arrow ${expanded ? 'Up' : ''}`} />}
      </div>
      {renderListServiceType()}
    </div>
  )
}

DropdownServiceTypes.propTypes = {
  currentCustomer: PropTypes.shape({}).isRequired,
  defaultServiceType: PropTypes.shape({}).isRequired,
  isDisabled: PropTypes.bool,
  objectMultiple: PropTypes.shape({}),
  onChangeSelectService: PropTypes.func.isRequired,
  onClickComingService: PropTypes.func,
  serviceTypes: PropTypes.instanceOf(Array).isRequired,
}

DropdownServiceTypes.defaultProps = {
  isDisabled: false,
  objectMultiple: {},
  onClickComingService: undefined,
}

export default DropdownServiceTypes
