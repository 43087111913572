// Action list
export const UPDATE_BOOKING = 'UPDATE_BOOKING'
export const UPDATE_BOOKINGS = 'UPDATE_BOOKINGS'
export const UPDATE_CURRENT_PAGE = 'UPDATE_CURRENT_PAGE'
export const UPDATE_CURRENT_TAB = 'UPDATE_CURRENT_TAB'
export const UPDATE_CURRENT_VIEW_AS = 'UPDATE_CURRENT_VIEW_AS'
export const UPDATE_DRAFT_BOOKINGS = 'UPDATE_DRAFT_BOOKINGS'
export const UPDATE_TOTAL_BOOKINGS = 'UPDATE_TOTAL_BOOKINGS'
export const UPDATE_PAGINATE_VALUES = 'UPDATE_PAGINATE_VALUES'
export const DELETE_DRAFT_BOOKING = 'DELETE_DRAFT_BOOKING'

// Common
export const VIEW_AS = {
  card: 'card',
  list: 'list'
}

// Path list
export const NEW_BOOKING_PATH = 'bookings/new'
export const BOOKING_PATH = 'bookings'

// Search list
export const BOOKING_ID = { value: 'booking_id', placeholder: 'place_holder.id' }
export const DRIVER_NAME = { value: 'driver_name', placeholder: 'place_holder.name' }
export const VEHICLE_NAME = { value: 'vehicle_name', placeholder: 'place_holder.name' }
export const JOB_ORDER_NUMBER = { value: 'job_order_number', placeholder: 'place_holder.number' }
export const BOOKING_TYPE = { value: 'booking_type', placeholder: 'place_holder.type' }
export const SEARCH_KEYS = [BOOKING_ID, DRIVER_NAME, VEHICLE_NAME, JOB_ORDER_NUMBER, BOOKING_TYPE]
export const TYPE_ALL = { value: 'webapp.booking.all' }
export const TYPE_WHOLE_VEHICLE = { value: 'webapp.new_booking.vehicle' }
export const TYPE_PARTIAL_LOAD = { value: 'webapp.new_booking.load' }
export const TYPE_CONTAINER_LOAD = { value: 'webapp.new_booking.lcl' }
export const BOOKING_TYPE_SEARCH = [TYPE_ALL, TYPE_WHOLE_VEHICLE, TYPE_CONTAINER_LOAD]
export const ALL_TIME = 'all_time'
export const SELECT_DATES = 'select_dates'
export const LAST_DATE_SEARCH = 'lastDateSearch'


// Scope list
export const UPCOMING = { label: 'closures.label.on_going', scope: 'upcoming' }
export const COMPLETE = { label: 'closures.label.complete', scope: 'delivery_completed' }
export const CANCELED = { label: 'closures.status.canceled', scope: 'canceled' }
export const DRAFT = { label: 'batches.status.draft', scope: 'draft' }
export const SAVED = { label: 'closures.label.saved', scope: 'favorited' }
export const TABS = [
  UPCOMING,
  COMPLETE,
  CANCELED,
  DRAFT,
  SAVED,
]

// Status list
export const STATUSES = {
  canceled: 'canceled'
}

export const SCOPE_SORT_BY = {
  upcoming: 'pickup_time',
  delivery_completed: 'completed_at',
  canceled: 'updated_at',
  favorited: 'updated_at'
}
