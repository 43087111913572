import { API_GATEWAY } from 'constants/appConstants'
import apiClient from 'services/axiosApp'
import store from 'store/store'

const EventNotificationAPI = {
  getCurrentEvent: async (areaId, currentCustomer) => {
    const { extraInfos } = store.getState()
      const url = extraInfos?.enable_catalog_service
        ? `${API_GATEWAY}/v1/catalog/areas/${areaId}/event_notifications`
        : '/api/v3/event_notifications'
    const queryParam = {
      params: {
        company_id: currentCustomer ? currentCustomer.currentCompanyId : 0
      },
      headers: {
        'Device-Type': 'Web'
      }
    }
    const res = await apiClient.get(url, queryParam,)
    return res?.data
  }
}

export default EventNotificationAPI
