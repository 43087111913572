import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'

// UTILS
import * as utilBookingExtraServices from '../ez_spread_sheet/bookingExtraServices'
import I18n from 'i18n/i18n'
// COMPONENTS
import DynamicPopup from '../../components/common/DynamicPopup'

// CONSTANTS
import { NEW_GEN_POD, SCHEDULE, LONG_HAUL, CPOD } from 'constants/bookingConstants'
import { POPUP_ICON_ASSIGNMENT } from 'constants/imageConstants'
import {
  ITEM_TYPE_VEHICLE_TYPE_LH_DOC_RETURN_CONTENT,
  ITEM_TYPE_VEHICLE_TYPE_LH_DOC_RETURN_TITLE,
  ITEM_TYPE_VEHICLE_TYPE_SFPOD_CONTENT,
  ITEM_TYPE_VEHICLE_TYPE_SFPOD_TITLE,
} from 'constants/common/popupConstants'
import { checkIsValidAddress } from './common'
import BatchesAPI from 'api/batches'
import { batchActionsCreator } from 'store/toolkit/batch/batch.reducer'
import store from 'store/store'

export const CPODUtils = {
  keys() {
    return [
      'recipient_name',
      'recipient_phone',
      'address_1',
      'city',
      'state',
      'postal_code',
      'latitude',
      'longitude',
      'address_components',
      'notes',
    ]
  },
  showPopup: (
    options = {
      icon: '',
      content: undefined,
      buttons: undefined,
      renderNode: '',
      configStyle: {},
    }
  ) => {
    const { icon, content, buttons, renderNode, configStyle } = options
    return (
      // eslint-disable-next-line react/no-render-return-value
      ReactDOM.render(
        <DynamicPopup
          icon={icon}
          renderNode={renderNode}
          renderDynamicContent={content}
          renderDynamicButton={buttons}
          configStyle={configStyle}
          maxHeightIcon
        />,
        renderNode
      )
    )
  },
  showPersonalCOD({ icon, renderContent, renderButtons }, renderNode, configStyle = {}) {
    let dynamicContent
    if (renderContent) {
      dynamicContent = () => <div className="mt15">{renderContent}</div>
    }

    let dynamicButtons
    if (renderButtons) {
      dynamicButtons = () => <div className="flex mt15">{renderButtons}</div>
    }

    const options = {
      icon,
      content: dynamicContent,
      buttons: dynamicButtons,
      renderNode,
      configStyle,
    }
    CPODUtils.showPopup(options)
  },
  showDocumentReturnPopup(docReturnPopup, renderNode) {
    function renderPopupItem(item, index) {
      switch (item.item_type) {
        case ITEM_TYPE_VEHICLE_TYPE_LH_DOC_RETURN_TITLE: {
          return (
            <div key={index}>
              {index > 0 && <div className="Normal-Booking-Devide" />}
              <h5 className="Normal-Booking-Title" dangerouslySetInnerHTML={{ __html: item.formated_content }} />
            </div>
          )
        }
        case ITEM_TYPE_VEHICLE_TYPE_LH_DOC_RETURN_CONTENT: {
          return <p key={index} className="center" dangerouslySetInnerHTML={{ __html: item.formated_content }} />
        }
        default:
          return <span />
      }
    }

    const dynamicContent = () => (
      <div className="mt15">{docReturnPopup.popup_items.map((item, index) => renderPopupItem(item, index))}</div>
    )

    const options = {
      icon: docReturnPopup.icon,
      content: dynamicContent,
      renderNode,
    }
    CPODUtils.showPopup(options)
  },
  showSFPODPopup(sfpodPopup, renderNode) {
    function renderPopupItem(item, index) {
      switch (item.item_type) {
        case ITEM_TYPE_VEHICLE_TYPE_SFPOD_TITLE: {
          return (
            <div key={index}>
              {index > 0 && <div className="Normal-Booking-Devide" />}
              {/* eslint-disable-next-line react/no-danger */}
              <h5 className="Normal-Booking-Title" dangerouslySetInnerHTML={{ __html: item.formated_content }} />
            </div>
          )
        }
        case ITEM_TYPE_VEHICLE_TYPE_SFPOD_CONTENT: {
          return (
            /* eslint-disable-next-line react/no-danger */
            <p key={index} className="center" dangerouslySetInnerHTML={{ __html: item.formated_content }} />
          )
        }
        default:
          return <span />
      }
    }
    const dynamicContent = () => (
      <div className="mt15">{sfpodPopup.popup_items.map((item, index) => renderPopupItem(item, index))}</div>
    )
    const options = {
      icon: sfpodPopup.icon,
      content: dynamicContent,
      renderNode,
    }
    CPODUtils.showPopup(options)
  },
  showEnoughDestination(renderNode, configStyle = {}) {
    const dynamicContent = () => (
      <div className="mt15">
        <p className="center">{I18n.t('webapp.new_booking.step_2.not_enough_destinations')}</p>
      </div>
    )

    const options = {
      icon: POPUP_ICON_ASSIGNMENT,
      content: dynamicContent,
      renderNode,
      configStyle,
    }
    CPODUtils.showPopup(options)
  },
  invalidDocumentReturnAddress(documentReturn, keys, callback) {
    const invalid = []
    Object.keys(documentReturn).forEach((key) => {
      if (!documentReturn[key] && key !== 'address_components' && key !== 'notes') {
        invalid.push(key)
      }
    })
    return callback ? callback(invalid) : invalid
  },
  validateParamsCalculateFees({
    areaID = '',
    locations = [],
    companyTypeID = undefined,
    vehicleTypeID = undefined,
    timeType = '',
    cloneDocumentReturn = {},
    newGenPOD = false,
  }) {
    const keys = CPODUtils.keys()
    const params = {
      company_type_id: companyTypeID,
      time_type: timeType,
    }

    const documentReturn = {
      recipient_name: cloneDocumentReturn.recipient_name,
      recipient_phone: cloneDocumentReturn.recipient_phone,
      address_1: cloneDocumentReturn.address_1,
      city: cloneDocumentReturn.city,
      state: cloneDocumentReturn.state,
      postal_code: cloneDocumentReturn.postal_code,
      latitude: cloneDocumentReturn.latitude,
      longitude: cloneDocumentReturn.longitude,
      address_components: cloneDocumentReturn.address_components,
    }
    const validLocations = _.filter(
      locations,
      (location) => !_.isUndefined(location.lat) && !_.isUndefined(location.lng)
    )

    CPODUtils.invalidDocumentReturnAddress(documentReturn, keys, (invalidKeys) => {
      if (!_.size(invalidKeys) && newGenPOD) {
        return _.assign(params, {
          document_handling: {
            area_id: areaID,
            vehicle_type_id: vehicleTypeID,
            latitude: documentReturn.latitude,
            longitude: documentReturn.longitude,
            last_drop_off_latitude: validLocations[validLocations.length - 1].lat,
            last_drop_off_longitude: validLocations[validLocations.length - 1].lng,
          },
        })
      }

      return _.assign(params, {
        locations: validLocations.map((location) => {
          const result = {
            need_cod: location.need_cod || false,
            need_pod: location.need_pod || false,
            cod_invoice_fees: location.cod_invoice_fees,
          }
          return result
        }),
      })
    })

    return params
  },
  cloneDocumentReturn({
    recipient_name: recipientName,
    recipient_phone: recipientPhone,
    address_1: address1,
    city,
    state,
    postal_code: postalCode,
    latitude,
    longitude,
    address_components: addressComponents,
    notes: optionalNotes,
  }) {
    let newNotes = { ...optionalNotes }
    if (!optionalNotes?.value) {
      newNotes = { value: '' }
    }
    const arr = [
      recipientName,
      recipientPhone,
      address1,
      city,
      state,
      postalCode,
      latitude,
      longitude,
      addressComponents,
      newNotes,
    ]
    const keys = CPODUtils.keys()
    const params = {}

    Object.keys(arr).forEach((index) => {
      _.assign(params, {
        [keys[index]]:
          typeof arr[index] === 'object' && !(arr[index] instanceof Array) && arr[index]
            ? arr[index].value
            : arr[index],
      })
    })

    return params
  },
  verifyNewGenCPOD({ bookAgainDetails = {}, checkLocations = {} }) {
    if (checkLocations && checkLocations.cod_pod === NEW_GEN_POD) {
      return true
    }

    if (
      bookAgainDetails &&
      bookAgainDetails.new_gen_pod &&
      ((checkLocations && checkLocations.cod_pod !== NEW_GEN_POD) || !checkLocations)
    ) {
      return false
    }

    return bookAgainDetails && bookAgainDetails.new_gen_pod
  },
  validateParamsDocumentReturn(locations, checkLocations) {
    const validLocationsPOD = !!_.find(locations, ['need_pod', true])
    const isShowCPOD = CPODUtils.verifyNewGenCPOD({ checkLocations, bookAgainDetails: {} })

    return validLocationsPOD && isShowCPOD
  },
  verifyDataBatchBookings(booking) {
    const { time_type_option: timeTypeOption = {}, locations, vehicle_type: vehicleType = {} } = booking
    return _.size(timeTypeOption) && _.size(vehicleType) && _.size(locations) ? booking : {}
  },
  calculateCheckLocations: async ({ booking, isPickupTime = false }) => {
    if (!_.size(booking)) return
    const { currentCustomer } = store.getState()
    const { time_type_option: timeTypeOption = {}, locations, vehicle_type: vehicleType = {} } = booking
    const { country_code: countryCode = {}, currentCompanyId: companyId } = currentCustomer
    const { data: result } = await BatchesAPI.checkOutOfServiceBookingLocationsNew({
      locations,
      timeType: timeTypeOption.type_key,
      serviceTypeID: vehicleType.service_type_id,
      vehicleTypeID: vehicleType.id,
      countryCode,
      companyId,
    })
    // we only update fields which is really changed
    const tempBookingDocument = _.assign({ temp_id: booking.temp_id }, { checkLocations: result })
    let newLocations = locations
    if (!result.cod_pod) {
      newLocations = newLocations.map((location) => ({
        ...location,
        need_pod: false,
        need_cod: false,
        pod_note: '',
        cod_note: '',
      }))
    }
    if (result.cod_pod === NEW_GEN_POD && booking?.checkLocations?.cod_pod === CPOD) {
      newLocations = newLocations.map((location) => ({
        ...location,
        need_cod: false,
        cod_note: '',
      }))
    }
    const bk = isPickupTime
      ? {
          ...tempBookingDocument,
          locations: newLocations,
          pickup_date_time: booking.pickup_date_time,
          verify: { ...booking.verify, pickupTime: true },
          is_new_empty: false,
        }
      : { ...tempBookingDocument, locations: newLocations }
    store.dispatch(
      batchActionsCreator.updateBooking({
        booking: bk,
      })
    )
  },
  updateTimeTypeBaseOnLocation: async ({ booking }) => {
    if (!_.size(booking)) return
    const { currentCustomer, extraServices } = store.getState()
    const { time_type_option: timeTypeOption = {}, locations, vehicle_type: vehicleType = {} } = booking
    const { country_code: countryCode = {}, currentCompanyId: companyId } = currentCustomer
    let newTimeTypeOption = { ...timeTypeOption }

    const vaidLocations = _.filter(locations, (location) => location.name)

    if (vaidLocations.length > 1) {
      const { data: result } = await BatchesAPI.checkOutOfServiceBookingLocationsNew({
        locations,
        timeType: timeTypeOption.type_key,
        serviceTypeID: vehicleType.service_type_id,
        vehicleTypeID: vehicleType.id,
        countryCode,
        companyId,
      })

      const { outside_list_ids: outsideListIds } = result
      const tempBookingDocument = { temp_id: booking.temp_id, checkLocations: result }
      if (result.long_haul_address_valid && vehicleType.settings.long_haul_enabled) {
        newTimeTypeOption = {
          ...newTimeTypeOption,
          type_key: LONG_HAUL,
          type_value: 4,
        }
      } else if (newTimeTypeOption.type_key === LONG_HAUL) {
        newTimeTypeOption = {
          ...newTimeTypeOption,
          type_key: SCHEDULE,
          type_value: 2,
        }
      }
      const newLocations = locations.map((location) => {
        const { verify } = location
        const newVerify = {
          ...verify,
          name:
            !_.includes(outsideListIds, location.temp_id) &&
            checkIsValidAddress(location.name, location.lat, location.lng),
        }

        return {
          ...location,
          verify: newVerify,
        }
      })
      const newBooking = {
        ...booking,
        time_type_option: newTimeTypeOption,
        locations: newLocations,
      }
      const tempBooking = utilBookingExtraServices.buildByOptionExtras(newBooking, extraServices)
      store.dispatch(batchActionsCreator.updateBooking({ booking: { ...tempBooking, ...tempBookingDocument } }))
    }
  },
  getPramsCODPODNote(location, key, booking) {
    if (booking && booking.new_gen_pod && location.need_pod && key === 'pod_note') {
      return location.pod_note || ''
    }

    if (location.need_cod && location.need_pod && key === 'cod_note') {
      return location.cod_note || ''
    }

    if (!location.need_cod && location.need_pod && key === 'pod_note') {
      return location.pod_note || ''
    }

    return ''
  },
  enablePOD(booking, location) {
    const { checkLocations = {} } = booking
    return checkLocations && (!!checkLocations.cod_pod || !!location.need_pod)
  },
  enableCODPOD(booking) {
    const { checkLocations = {} } = booking
    return checkLocations && checkLocations.cod_pod === NEW_GEN_POD
  },
  enableCOD(booking) {
    const { checkLocations = {}, time_type_option: timeTypeOption } = booking
    const timeType = _.get(timeTypeOption, 'type_key')

    if (_.isEmpty(checkLocations)) return false
    if (timeType === LONG_HAUL) return false

    return !!checkLocations.cod_pod && checkLocations.cod_pod === CPOD
  },
  validParamsBeforeCallAPI(obj = {}) {
    const keys = CPODUtils.keys()
    const {
      recipient_name: recipientName,
      recipient_phone: recipientPhone,
      address_1: address,
      city,
      state,
      postal_code: postalCode,
      latitude,
      longitude,
    } = obj

    const documentReturn = obj
      ? {
          recipient_name: recipientName,
          recipient_phone: recipientPhone,
          address_1: address,
          city,
          state,
          postal_code: postalCode,
          latitude,
          longitude,
        }
      : {}

    let bookingTrackingAttr
    const invalidKeys = CPODUtils.invalidDocumentReturnAddress(documentReturn, keys)
    if (!_.size(invalidKeys)) {
      const attr = {
        latitude: documentReturn.latitude,
        longitude: documentReturn.longitude,
      }
      bookingTrackingAttr = attr
    }
    return bookingTrackingAttr
  },
  isDocumentReturnEmpty(documentReturn) {
    const keys = this.keys()
    let isEmpty = true

    if (documentReturn) {
      const invalidKeys = this.invalidDocumentReturnAddress(documentReturn, keys)
      if (!_.size(invalidKeys)) isEmpty = false
    }
    return isEmpty
  },
}

export { CPODUtils as default }
