import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import $ from 'jquery'
import _ from 'lodash'
// UTILS
import { Utils } from 'utils/Utils'
// ACTIONS
import * as bookingActionCreators from 'store/actions/common/bookingActionCreators'
import * as assignDriverActionCreators from 'store/actions/new_booking/assignDriverActionCreators'
// COMPONENTS
import ActionButtonsAssignDriver from '../../common/driver_preference/ActionButtonsAssignDriver'
import ConfirmPickupTimePopup from '../../common/driver_preference/ConfirmPickupTimePopup'
import ListAssignableDriversPopupContent from '../../common/driver_preference/ListAssignableDriversPopupContent'
// CONSTANTS
import {
  DRIVER_PREFERENCES_POPUP,
  DRIVER_PREFERENCES,
  FULL_DAY
} from 'constants/newBookingConstants'
import {
  POPUP_ID_ASSIGN_DRIVERS,
  POPUP_ID_YOUR_RECENT_DRIVERS,
  POPUP_ID_UNVAILABLE_DRIVERS
} from 'constants/common/popupConstants'
// API
import CustomerAPI from 'api/customers'
import PopupAPI from 'api/popups'
import { currentPopupIDActionsCreator } from 'store/toolkit/currentPopupID/currentPopupID.reducer'
import { accountManageActions } from 'store/toolkit/accountManage/accountManage.reducer'
import { SIGN_IN_MODAL } from '../guest_flow/constants'
import { appsFlyerTrackEvent } from 'utils/trackingAppsFlyer'
import { SEARCH_DRIVER_BY_REFERRAL_ID_CLICK } from 'constants/trackingAppsFlyer'
// ASSETS

const mapStateToProps = state => ({
  estimatedWorkingTime: state.estimatedWorkingTime,
  selectedAmountFullDay: state.extraServices.fullDayPricing.selected_amount,
  tmpSelectedTimeAssignDriver: state.tmpSelectedTimeAssignDriver,
  others: state.others,
  booking: state.booking,
  currentCustomer: state.currentCustomer
})

function mapDispatchToProps(dispatch) {
  return {
    assignDriverActions: bindActionCreators(assignDriverActionCreators, dispatch),
    bookingActions: bindActionCreators(bookingActionCreators, dispatch),
    currentPopupIDActions: bindActionCreators(currentPopupIDActionsCreator, dispatch),
    accountManageActionsProps: bindActionCreators(accountManageActions, dispatch),
  }
}
class ListAssignableDriversPopup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showChangePickupTimePopup: false,
      isUnavailable: false,
      blockedTimeInfos: null,
      assignDriver: props.assignedDriver,
      dataUnavailablePopupEye: {},
      showClearInput: false
    }
    this.tmpSearchValue = ''
    this.closePopup = this.closePopup.bind(this)
    this.handleShowChangePickupTimePopup = this.handleShowChangePickupTimePopup.bind(this)
    this.handleShowPopup = this.handleShowPopup.bind(this)
    this.handleShowPopupRecentDrivers = this.handleShowPopupRecentDrivers.bind(this)
    this.clearSearchDriverResult = this.clearSearchDriverResult.bind(this)
    this.generateQuerySearchDriver = this.generateQuerySearchDriver.bind(this)
    this.handleUpdateAssignDriver = this.handleUpdateAssignDriver.bind(this)
  }

  componentDidMount() {
    this.getUnavailablePopupEye()
  }

  componentWillReceiveProps(nextProps) {
    const {
      currentSelected,
      currentStep,
    } = this.props
    if (currentSelected === DRIVER_PREFERENCES.assign && currentSelected !== nextProps.currentSelected) {
      this.setState({
        assignDriver: null
      })
    }
    if (!_.isEmpty(nextProps.assignedPickupTime)) {
      this.setState({
        isUnavailable: true
      })
    } else if (nextProps.currentStep === 2 && currentStep === 3) {
      this.setState({
        isUnavailable: false
      })
    }

    if (nextProps.currentStep === 3 && currentStep === 2) {
      this.setState({ blockedTimeInfos: null })
    }

    if (window.addToFavoriteDrivers) {
      this.setState({ showSearchResult: false })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      currentStep,
      isShowPopup,
      drivers,
      booking
    } = this.props
    const {
      assignDriver,
      showChangePickupTimePopup,
      showSearchResult,
      driverFromSearch
    } = this.state
    return ((currentStep === 2 && (!assignDriver || !_.isEqual(assignDriver, nextState.assignDriver)))
      || showChangePickupTimePopup !== nextState.showChangePickupTimePopup
      || isShowPopup !== nextProps.isShowPopup
      || showSearchResult !== nextState.showSearchResult
      || (nextState.showSearchResult === true && driverFromSearch !== nextState.driverFromSearch)
      || !_.isEqual(drivers, nextProps.drivers)
      || !_.isEqual(booking, nextProps.booking))
  }

  componentDidUpdate() {
    const { currentCustomer, booking, calledAPIAfterSignIn } = this.props

    if (currentCustomer.authentication_token
      && currentCustomer.area_id
      && booking.isSignInFinished
      && !calledAPIAfterSignIn
      && this.tmpSearchValue) {
      this.searchDriverByReferralCode(this.tmpSearchValue)
    }
  }

  onChangeReferralCode(event) {
    if (event.target.value === '') {
      this.clearSearchDriverResult()
    } else {
      this.setState({ showClearInput: true })
    }
  }

  getUnavailablePopupEye() {
    const {
      authenticationToken,
      areaID,
    } = this.props
    const params = {
      area_id: areaID,
      popup_type: POPUP_ID_ASSIGN_DRIVERS.popup_eye.schedule_tool.item_type,
    }
    return new Promise((resolve) => {
      PopupAPI.getAreaTypePopup(authenticationToken, params, (result) => {
        const popupSchedule = result?.data?.object
        if (result?.status === 200 && !_.isEmpty(popupSchedule)) {
          this.setState({
            dataUnavailablePopupEye: popupSchedule
          })
        }
        resolve()
      })
    })
  }

  setSearchResult(data) {
    this.setState({
      showSearchResult: true,
      driverFromSearch: data[0]
    }, () => {
      if (_.isEmpty(data)) {
        this.resetDriverList(false, true)
      }
    })
  }

  handleKeyPressReferralCode(event, driverReferralCode) {
    const { currentCustomer, accountManageActionsProps } = this.props
    const key = event.which || event.keyCode || 0

    this.tmpSearchValue = driverReferralCode

    // press enter
    if (key === 13) {
      if (_.isEmpty(currentCustomer.authentication_token)) {
        accountManageActionsProps.updateModalAccountManage(SIGN_IN_MODAL)
      } else {
        this.searchDriverByReferralCode(driverReferralCode)
      }
      appsFlyerTrackEvent(SEARCH_DRIVER_BY_REFERRAL_ID_CLICK, { customer_id: currentCustomer.id, referral_id: driverReferralCode });
    }
  }

  generateQuerySearchDriver() {
    const {
      companyID,
      badgeIDs,
      areaID,
      countryCode,
      extraRequirementIDs,
      reimbursementIDs,
      timeType,
      selectedVehicleTypeID,
      extraRequirementLocationsIDs,
      isNeedCOD,
      isNeedPOD,
      isNewGenPod,
    } = this.props

    return {
      vehicle_type_id: selectedVehicleTypeID,
      time_type: timeType,
      company_id: companyID === 0 ? undefined : companyID,
      area_id: areaID,
      country_code: countryCode,
      extra_requirement_ids: extraRequirementIDs,
      extra_requirement_locations_ids: extraRequirementLocationsIDs,
      badges: badgeIDs,
      reimbursement_ids: reimbursementIDs,
      location_need_cod: isNeedCOD,
      location_need_pod: isNeedPOD,
      new_gen_pod: isNewGenPod,
    }
  }

  searchDriverByReferralCode(driverReferralCode) {
    const {
      drivers, authenticationToken, stepActions, bookingActions, timeType
    } = this.props
    const querySearchDriver = this.generateQuerySearchDriver()
    const data = {
      driverReferralCode,
      drivers,
      authenticationToken,
      include_fleet_driver: timeType !== FULL_DAY,
      ...querySearchDriver
    }
    bookingActions.searchDriverByReferralCode(data, stepActions, (dataResponse) => {
      this.setSearchResult(dataResponse)
    })
  }

  clearSearchDriverResult() {
    this.tmpSearchValue = ''
    this.setState({
      showSearchResult: false,
      driverFromSearch: null,
      showClearInput: false
    }, () => {
      this.resetDriverList(false, false, true)
    })
  }

  checkDriverBlockedTime(driver, dataResponse) {
    const {
      pickupTime,
      selectedAmountFullDay,
      assignDriverActions,
      tmpSelectedDateAssignDriver,
      estimatedWorkingTime,
      assignedDriver,
    } = this.props
    const isPickupTimeValid = Utils.checkPickupTimeValidAssignDriver(dataResponse.data, pickupTime,
      estimatedWorkingTime, selectedAmountFullDay)
    if (isPickupTimeValid) {
      this.setState({
        isUnavailable: false
      })
    } else {
      this.setState({
        isUnavailable: true,
        blockedTimeInfos: dataResponse,
      }, () => {
        if (_.isEmpty(tmpSelectedDateAssignDriver) || !_.isEqual(driver, assignedDriver)) {
          assignDriverActions.changeTmpSelectedDateAssignDriver(pickupTime)
        }
        this.handleShowUnvailableDriverPopup()
      })
    }
    this.setState({
      assignDriver: driver
    }, () => {
      this.closePopup(true)
    })
  }

  assignToDriver(driver, elementInvalidDriverPopup) {
    const { showSearchResult } = this.state
    const {
      areaID, authenticationToken, countryCode, stepActions, assignDriverActions,
      isNeedCOD, isNeedPOD, isNewGenPod,
    } = this.props
    if (elementInvalidDriverPopup === 'selectedDriver') {
      this.setState({ assignDriver: null })
      assignDriverActions.changeAssignDriver(null)
      return
    }
    if (showSearchResult) {
      const {
        companyID,
        selectedVehicleTypeID,
        timeType,
        extraRequirementIDs,
        extraRequirementLocationsIDs,
        badgeIDs,
        reimbursementIDs,
        bookingActions,
      } = this.props
      const data = {
        companyID,
        vehicleTypeId: selectedVehicleTypeID,
        timeType,
        extraRequirementIDs,
        extraRequirementLocationsIDs,
        badgeIDs,
        reimbursementIDs,
        driver,
        areaID,
        countryCode,
        authenticationToken,
        elementInvalidDriverPopup,
        isNeedCOD, isNeedPOD, isNewGenPod,
      }
      bookingActions.checkSearchDriverToAssign(data, stepActions, (dataResponse) => {
        this.checkDriverBlockedTime(driver, dataResponse)
      })
    } else if (driver.fleet_partner_id) {
      this.setState({
        assignDriver: driver
      }, () => {
        this.closePopup(true)
      })
    } else {
      const params = {
        driver_id: driver.id,
        area_id: areaID
      }
      stepActions.loading()
      assignDriverActions.changeTmpSelectedTimeAssignDriver('')
      CustomerAPI.getBlockedTimes(params, (result) => {
        Promise.resolve(
          this.checkDriverBlockedTime(driver, result.data)
        ).then(() => {
          stepActions.loaded()
        })
      })
    }
  }

  resetDriverList(closePopup, keepSearch, clear = false) {
    const {
      goBack, assignDriverActions, assignedDriver, assignedPickupTime
    } = this.props
    const { showSearchResult, showClearInput, driverFromSearch } = this.state
    let tmpSelectedDate = ''
    let tmpSelectedTime = ''
    if (clear) {
      tmpSelectedDate = assignedPickupTime
      tmpSelectedTime = moment(assignedPickupTime).format('HH:mm')
    }
    Promise.resolve(
      assignDriverActions.changeTmpSelectedDateAssignDriver(tmpSelectedDate),
      assignDriverActions.changeTmpSelectedTimeAssignDriver(tmpSelectedTime),
    ).then(() => {
      if (!keepSearch) {
        this.tmpSearchValue = ''
      }

      this.setState({
        assignDriver: assignedDriver,
        showSearchResult: keepSearch ? showSearchResult : false,
        showClearInput: keepSearch ? showClearInput : false,
        driverFromSearch: keepSearch ? driverFromSearch : null,
      })
      if (closePopup) {
        goBack()
      }
    })
    if (_.isEmpty(assignedPickupTime)) {
      this.setState({ isUnavailable: false })
    } else {
      this.setState({ isUnavailable: true })
    }
  }

  closePopup(confirm = false) {
    const {
      tmpSelectedTimeAssignDriver,
    } = this.props
    const { assignDriver, isUnavailable } = this.state
    if (confirm === true) {
      if (isUnavailable && !_.get(assignDriver, 'fleet_partner_id')) {
        if (!tmpSelectedTimeAssignDriver) {
          $('.Popover-Item-Driver-Item.Modal-Driver-Change-Date-Time').addClass('error')
          $('.Popover-Item-Driver-Item.Modal-Driver-Change-Date-Time .Item-Driver-Show').removeClass('hidden')
        } else if (assignDriver) {
          Utils.hideOverlayStep2()
          this.setState({
            showChangePickupTimePopup: true
          })
        } else {
          this.callbackAssignDriverActions()
        }
      } else {
        this.callbackAssignDriverActions()
      }
    } else {
      this.resetDriverList(true)
    }
    return false
  }

  callbackAssignDriverActions() {
    const { assignDriverActions, resetPopup } = this.props
    const { assignDriver } = this.state
    Promise.resolve(
      assignDriverActions.changeAssignDriver(assignDriver),
      assignDriverActions.changeAssignedPickupTime(''),
    ).then(() => {
      if (assignDriver) {
        this.tmpSearchValue = ''
        this.setState({
          showSearchResult: false,
          showClearInput: false,
          driverFromSearch: false,
        }, () => {
          resetPopup()
          window.Moengage.track_event('Assign Driver Selected')
        })
      } else {
        this.resetDriverList(true)
      }
    })
  }

  handleShowChangePickupTimePopup() {
    Utils.hideOverlayStep2(false)
    this.setState({
      showChangePickupTimePopup: false
    })
  }

  handleShowPopup() {
    const { resetPopup } = this.props
    resetPopup()
  }

  handleShowPopupRecentDrivers() {
    const { currentPopupIDActions, currentCustomer, accountManageActionsProps } = this.props

    Utils.hideOverlayStep2()
    if (_.isEmpty(currentCustomer.authentication_token)) {
      accountManageActionsProps.updateModalAccountManage(SIGN_IN_MODAL)
    } else {
      currentPopupIDActions.updateCurrentPopupID(POPUP_ID_YOUR_RECENT_DRIVERS)
    }
  }

  handleShowUnvailableDriverPopup() {
    const { currentPopupIDActions } = this.props

    Utils.hideOverlayStep2()
    currentPopupIDActions.updateCurrentPopupID(POPUP_ID_UNVAILABLE_DRIVERS)
  }

  handleUpdateAssignDriver(assignDriver) {
    this.setState({ assignDriver })
  }

  render() {
    const {
      pickupTime,
      countryCode,
      isShowPopup,
      optionRef,
      drivers,
      maxPickupTimeDays,
      assignedDriver,
      currentVehicleType,
      others,
      booking,
      recentDriver,
      getParamsForLoadFavorite
    } = this.props
    const {
      assignDriver,
      showChangePickupTimePopup,
      isUnavailable,
      dataUnavailablePopupEye,
      blockedTimeInfos,
      showSearchResult,
      driverFromSearch,
      showClearInput
    } = this.state
    if (!isShowPopup) {
      return (<span />)
    }
    const driversLength = drivers.length
    return (
      <div
        id={DRIVER_PREFERENCES_POPUP}
        className="Popover custom step-2 Popover-Item-Drivers Single visible"
      >
        <div
          ref={(arrow) => { this.PopoverArrow = arrow }}
          className="Popover-Arrow"
          style={{
            top: $(optionRef.driverContainer).offset().top - 80
          }}
        />
        <ListAssignableDriversPopupContent
          specialClass="Popover-Item Popover-Item-Driver flex flex-column h100"
          assignDriver={assignDriver}
          assignedDriver={assignedDriver}
          isUnavailable={isUnavailable}
          drivers={drivers}
          driverFromSearch={driverFromSearch}
          maxPickupTimeDays={maxPickupTimeDays}
          dataUnavailablePopupEye={dataUnavailablePopupEye}
          blockedTimeInfos={blockedTimeInfos}
          countryCode={countryCode}
          assignToDriver={(driver, elementInvalidDriverPopup) => this.assignToDriver(driver, elementInvalidDriverPopup)}
          onChangeReferralCode={e => this.onChangeReferralCode(e)}
          handleKeyPressReferralCode={(e, driverReferralCode) => this.handleKeyPressReferralCode(e, driverReferralCode)}
          searchDriverByReferralCode={driverReferralCode => this.searchDriverByReferralCode(driverReferralCode)}
          showSearchResult={showSearchResult}
          currentVehicleType={currentVehicleType}
          favoriteDriversAmount={others.favoriteDriversAmount}
          closePopup={this.closePopup}
          showClearInput={showClearInput}
          clearSearchDriverResult={this.clearSearchDriverResult}
          showPopupRecentDrivers={this.handleShowPopupRecentDrivers}
          generateQuery={this.generateQuerySearchDriver}
          reasonReqNotMet={booking.reasonReqNotMet}
          recentDriver={recentDriver}
          pickupTime={pickupTime}
          handleShowChangePickupTimePopup={this.handleShowChangePickupTimePopup}
          handleShowPopup={this.handleShowPopup}
          handleUpdateAssignDriver={this.handleUpdateAssignDriver}
          getParamsForLoadFavorite={getParamsForLoadFavorite}
          pagination={booking.pagination}
          renderActionButtons={() => (
            <div>
              <ActionButtonsAssignDriver
                showConfirmButton={driversLength > 0}
                assignDriver={assignDriver}
                isUnavailable={isUnavailable}
                closePopup={this.closePopup}
                showPopupRecentDrivers={this.handleShowPopupRecentDrivers}
                driversCount={driversLength}
                driverFromSearch={driverFromSearch}
                showSearchResult={showSearchResult}
                assignedDriver={assignedDriver}
              />
            </div>
          )}
        />
        {showChangePickupTimePopup && (
          <ConfirmPickupTimePopup
            countryCode={countryCode}
            assignDriver={assignDriver}
            pickupTime={pickupTime}
            handleShowChangePickupTimePopup={this.handleShowChangePickupTimePopup}
            handleShowPopup={this.handleShowPopup}
          />
        )}
        <div className="Modal-Driver-Change-Date-Time">
          <div id="CommonPopupDriver" className="Modal-Change-Data-Time" />
        </div>
      </div>
    )
  }
}

ListAssignableDriversPopup.propTypes = {
  assignDriverActions: PropTypes.shape({}).isRequired,
  stepActions: PropTypes.shape({}).isRequired,
  bookingActions: PropTypes.shape({}).isRequired,
  goBack: PropTypes.func.isRequired,
  resetPopup: PropTypes.func.isRequired,
  pickupTime: PropTypes.string.isRequired,
  currentStep: PropTypes.number.isRequired,
  maxPickupTimeDays: PropTypes.number.isRequired,
  authenticationToken: PropTypes.string.isRequired,
  areaID: PropTypes.number.isRequired,
  companyID: PropTypes.number.isRequired,
  extraRequirementIDs: PropTypes.instanceOf(Array).isRequired,
  extraRequirementLocationsIDs: PropTypes.instanceOf(Array).isRequired,
  badgeIDs: PropTypes.instanceOf(Array).isRequired,
  currentVehicleType: PropTypes.shape({}),
  currentPopupIDActions: PropTypes.shape({}).isRequired,
  others: PropTypes.shape({}).isRequired,
  optionRef: PropTypes.shape({}),
  assignedDriver: PropTypes.shape({}),
  selectedAmountFullDay: PropTypes.number,
  estimatedWorkingTime: PropTypes.number,
  isShowPopup: PropTypes.bool,
  currentSelected: PropTypes.string,
  tmpSelectedDateAssignDriver: PropTypes.string,
  assignedPickupTime: PropTypes.string,
  drivers: PropTypes.instanceOf(Array),
  selectedVehicleTypeID: PropTypes.number,
  countryCode: PropTypes.string,
  timeType: PropTypes.string,
  tmpSelectedTimeAssignDriver: PropTypes.string,
  reimbursementIDs: PropTypes.instanceOf(Array),
  booking: PropTypes.shape({}).isRequired,
  recentDriver: PropTypes.shape({}),
  currentCustomer: PropTypes.shape({}).isRequired,
  calledAPIAfterSignIn: PropTypes.bool,
  getParamsForLoadFavorite: PropTypes.func.isRequired,
  isNeedCOD: PropTypes.bool,
  isNeedPOD: PropTypes.bool,
  isNewGenPod: PropTypes.bool,
}

ListAssignableDriversPopup.defaultProps = {
  assignedDriver: undefined,
  currentSelected: undefined,
  currentVehicleType: undefined,
  selectedAmountFullDay: undefined,
  tmpSelectedDateAssignDriver: undefined,
  estimatedWorkingTime: undefined,
  assignedPickupTime: undefined,
  isShowPopup: undefined,
  drivers: [],
  optionRef: {},
  selectedVehicleTypeID: undefined,
  countryCode: undefined,
  timeType: undefined,
  tmpSelectedTimeAssignDriver: undefined,
  reimbursementIDs: [],
  recentDriver: {},
  calledAPIAfterSignIn: false,
  isNeedCOD: false,
  isNeedPOD: false,
  isNewGenPod: false,
}

export default connect(mapStateToProps, mapDispatchToProps)(ListAssignableDriversPopup)
