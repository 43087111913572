import moment from 'moment'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import $ from 'jquery'
import _ from 'lodash-es'

// ASSETS
// API
// ACTIONS
// COMPONENTS
import CardRowInfo from './CardRowInfo'
// CONSTANTS
// UTILS
import ClosuresUtils from 'utils/booking/ClosuresUtils'
import { Utils } from 'utils/Utils'
import I18n from 'i18n/i18n'

const mapStateToProps = state => ({
  currentCustomer: state.currentCustomer
})

class TallyInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { booking } = this.props
    const isFullDayBooking = booking.time_type === 'full_day'
    const tallyInfo = booking.tally_info
    if (_.isEmpty(tallyInfo)) { return }
    const currentEstimateStatus = tallyInfo.current_estimate_status
    const originalEstimateValues = tallyInfo.original_estimate_values
    if (isFullDayBooking) {
      let time = Math.abs(_.toInteger(moment(originalEstimateValues[0])) - _.toInteger(moment())) / 1000
      if (currentEstimateStatus === 'over_time') {
        const timer = setInterval(() => {
          time += 60
          if (time < 0) {
            clearInterval(timer)
          } else {
            const hours = _.round(time / 3600)
            const minutes = Math.abs(_.round(time / 60) - hours * 60)
            $(`#count_up_${booking.id}`).html(`${hours}h ${minutes}m`)
          }
        }, 60000)
      } else {
        const timer = setInterval(() => {
          time -= 60
          if (time < 0) {
            clearInterval(timer)
          } else {
            const hours = _.round(time / 3600)
            const minutes = Math.abs(_.round(time / 60) - hours * 60)
            $(`#count_down_${booking.id}`).html(`${hours}h ${minutes}m`)
          }
        }, 60000)
      }
    }
  }

  renderFullDayTally() {
    const { booking } = this.props
    const tallyInfo = booking.tally_info
    if (_.isEmpty(tallyInfo)) { return null }
    const originalEstimateValues = tallyInfo.original_estimate_values
    let rowInfo = {}
    switch (tallyInfo.current_estimate_status) {
      case 'remaining': {
        const timeDiffToSeconds = Math.abs(moment(originalEstimateValues[0]).diff(Date.now(), 'seconds'))
        rowInfo = {
          hasSpan: true,
          isShow: true,
          label: I18n.t('webapp.tally.time_remaining'),
          spanClass: 'transit-time-status green',
          spanID: `count_down_${booking.id}`,
          value: Utils.convertSecondsToTimeString(timeDiffToSeconds)
        }
        break
      }
      case 'over_time': {
        const timeDiffToSeconds = Math.abs(moment(originalEstimateValues[0]).diff(Date.now(), 'seconds'))
        rowInfo = {
          hasSpan: true,
          isShow: true,
          label: I18n.t('webapp.tally.overtime'),
          spanClass: 'transit-time-status red',
          spanID: `count_up_${booking.id}`,
          value: Utils.convertSecondsToTimeString(timeDiffToSeconds)
        }
        break
      }
      case 'waiting_for_pickup':
        rowInfo = {
          hasSpan: true,
          isShow: true,
          label: I18n.t('webapp.tally.status'),
          value: I18n.t('webapp.tally.waiting_for_pickup')
        }
        break
      default:
        rowInfo = {}
    }
    if (!_.isEmpty(rowInfo)) { return (<CardRowInfo rowInfo={rowInfo} key="Full-Day-Tally" />) }
    return null
  }

  renderNormalTally() {
    const { booking } = this.props
    const tallyInfo = booking.tally_info
    if (_.isEmpty(tallyInfo)) { return null }
    const currentEstimateStatus = tallyInfo.current_estimate_status
    const currentEstimateValues = tallyInfo.current_estimate_values
    const lastDropOffArrivedAt = tallyInfo.last_drop_off_arrived_at
    const originalEstimateValues = tallyInfo.original_estimate_values
    let arrCardRowTallyInfos = []
    if (currentEstimateStatus === 'early' && !_.isEmpty(currentEstimateValues)) {
      arrCardRowTallyInfos = ClosuresUtils.getTallyEarlyInfo(currentEstimateValues, originalEstimateValues)
    } else if (currentEstimateStatus === 'on_schedule') {
      arrCardRowTallyInfos = ClosuresUtils.getTallyOnScheduleInfo(originalEstimateValues)
    } else if (currentEstimateStatus === 'late' && !_.isEmpty(currentEstimateValues)) {
      arrCardRowTallyInfos = ClosuresUtils.getTallyLateInfo(currentEstimateValues, originalEstimateValues)
    } else if (currentEstimateStatus === 'now' && (_.toInteger(lastDropOffArrivedAt) > 0)) {
      arrCardRowTallyInfos = ClosuresUtils.getTallyNowInfo(lastDropOffArrivedAt, originalEstimateValues)
    } else if (currentEstimateStatus === 'waiting_for_pickup') {
      arrCardRowTallyInfos = ClosuresUtils.getTallyWaitingForPickupInfo(originalEstimateValues)
    }
    if (!_.isEmpty(arrCardRowTallyInfos)) {
      return arrCardRowTallyInfos.map(
        (item, index) => (<CardRowInfo rowInfo={item} key={`Normal-Tally-${index + 1}`} />)
      )
    }
    return null
  }

  render() {
    const { booking } = this.props
    return (
      <div className="Closure-List__transit-time">
        {booking.time_type === 'full_day' ? this.renderFullDayTally() : this.renderNormalTally()}
      </div>
    )
  }
}

TallyInfo.propTypes = {
  booking: PropTypes.shape({}).isRequired
}

export default connect(mapStateToProps, null)(TallyInfo)
