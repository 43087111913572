import $ from 'jquery'
import _ from 'lodash'
// UTILS
import * as utilCommon from '../common'
import contactUtils from '../../common/contact'
import phoneNumberUtils from '../../common/phoneNumber'
// CONSTANTS
import { GOOGLE_GEOCODER_URL } from 'constants/appConstants'
import { SCHEDULE, FULL_DAY } from 'constants/bookingConstants'
import { CUSTOMER_VAR_NONBP, CUSTOMER_VAR_BP } from 'constants/common/customerConstants'
import { MIN_AVAILABLE_LOCATION } from 'constants/ezSpreadSheetConstants'
import { setDefaultMaxLocation } from 'utils/booking/common'
import store from 'store/store'

const combileAddressImport = (excelRow, batchTemplate) => {
  if (_.isEmpty(excelRow)) {
    return undefined
  }
  const mapping = batchTemplate.mapping
  const arrAddress = []
  if (mapping.address_01 !== -1) {
    arrAddress.push(_.trim(excelRow[mapping.address_01]))
  }
  if (mapping.address_02 !== -1) {
    arrAddress.push(_.trim(excelRow[mapping.address_02]))
  }
  if (mapping.district !== -1) {
    arrAddress.push(_.trim(excelRow[mapping.district]))
  }
  if (mapping.city !== -1) {
    arrAddress.push(_.trim(excelRow[mapping.city]))
  }
  if (mapping.state_province !== -1) {
    arrAddress.push(_.trim(excelRow[mapping.state_province]))
  }
  const textCompact = _.compact(arrAddress)
  const textCombile = _.join(textCompact, ', ')
  return _.replace(textCombile, ',', ' ')
}

export const buildLocationAddress = (location, excelRow, batchTemplate) => {
  const locationTemp = { ...location }
  locationTemp.name = combileAddressImport(excelRow, batchTemplate)
  locationTemp.lat = excelRow[batchTemplate.mapping.latitude]
  locationTemp.lng = excelRow[batchTemplate.mapping.longitude]
  return locationTemp
}

const buildLocationContact = (location, excelRow, mapping, countryCode) => {
  const result = phoneNumberUtils.formatPhoneNumber(excelRow[mapping.phone], countryCode, true)
  const locationTemp = { ...location }
  locationTemp.recipient_name = excelRow[mapping.name]
  locationTemp.recipient_phone = result.phoneNumber || ''
  locationTemp.location_note = excelRow[mapping.location_note]
  return locationTemp
}

export const checkAutoSettingCOPPOD = (location, currentCustomer, extraInfos) => {
  let newLocation = { ...location }
  const strNeedPOD = newLocation.need_pod
  const strNeedCOD = newLocation.need_cod
  const arrTrueValues = ['yes', 'y', 'true', 't', '1', 1, '']
  let bNeedPOD = false
  let bNeedCOD = false
  let attrs = {}
  if (_.isEmpty(strNeedPOD)) {
    bNeedPOD = currentCustomer.check_pod_by_default || false
  } else {
    bNeedPOD = _.includes(arrTrueValues, strNeedPOD)
  }
  arrTrueValues.pop()
  if (bNeedPOD) {
    bNeedCOD = _.includes(arrTrueValues, strNeedCOD)
    if (!bNeedCOD) {
      attrs = { cod_note: undefined, cod_invoice_fees: undefined }
    }
  } else {
    attrs = { pod_note: undefined }
    if (extraInfos.enable_cod_pod_new_rule) {
      attrs = { ...attrs, cod_note: undefined, cod_invoice_fees: undefined }
    }
  }
  newLocation = {
    ...newLocation,
    ...attrs,
    need_pod: bNeedPOD,
    can_toggle_need_pod: currentCustomer.can_toggle_pod,
    need_cod: bNeedCOD,
  }
  return newLocation
}

const validationCodAmount = (location, requirePOD) => {
  if (requirePOD && location.need_cod) {
    if (!_.isEmpty(location.cod_invoice_fees) && _.toInteger(location.cod_invoice_fees) !== 0) {
      return true
    }
    return false
  }
  return true
}

const buildLocationAvoiceFee = (location, extraInfos, excelRow, mapping) => {
  const tempLocation = { ...location }
  if (extraInfos.enable_cod_pod_new_rule) {
    tempLocation.cod_invoice_fees = _.trim(excelRow[mapping.cod_invoice_fees])
  } else {
    tempLocation.cod_invoice_fees = undefined
  }
  return tempLocation
}

const buildPODCODInfo = (location, locationParams) => {
  let locationTemp = { ...location }
  const currentCustomer = locationParams.currentCustomer
  const extraInfos = locationParams.extraInfos
  const excelRow = locationParams.excelRow
  const batchTemplate = locationParams.batchTemplate
  // const strNeedPOD = _.trim(_.lowerCase(excelRow[mapping.need_pod]))
  // const strNeedCOD = _.trim(_.lowerCase(excelRow[mapping.need_cod]))

  if (!locationParams.excludePCODColumn) {
    locationTemp.need_pod = _.trim(_.lowerCase(excelRow[batchTemplate.mapping.need_pod]))
    locationTemp.need_cod = _.trim(_.lowerCase(excelRow[batchTemplate.mapping.need_cod]))
    locationTemp.pod_note = _.trim(_.lowerCase(excelRow[batchTemplate.mapping.pod_note]))
    locationTemp.cod_note = _.trim(_.lowerCase(excelRow[batchTemplate.mapping.cod_note]))
  }
  locationTemp = buildLocationAvoiceFee(locationTemp, extraInfos, excelRow, batchTemplate.mapping)
  locationTemp = checkAutoSettingCOPPOD(locationTemp, currentCustomer, extraInfos)
  const requirePOD = extraInfos.enable_cod_pod_new_rule ? locationTemp.need_pod : false
  if (requirePOD && !locationParams.excludePCODColumn) {
    locationTemp.verify = { ...locationTemp.verify, cod_invoice_fees: validationCodAmount(locationTemp, requirePOD) }
  }
  return locationTemp
}

export const matrixRowToLocationObject = (locationParams, countryCode) => {
  const excelRow = locationParams.excelRow
  const rowIndex = locationParams.rowIndex
  const batchTemplate = locationParams.batchTemplate
  const newLocation = locationParams.newLocation
  const mapping = batchTemplate.mapping
  if (_.isEmpty(excelRow)) {
    return {}
  }
  let location = { ...newLocation }
  location.order = rowIndex
  location.temp_id = [Math.random().toString(36).slice(2), rowIndex].join('-')
  if (!_.isEmpty(excelRow[mapping.consolidates])) {
    location.consolidates = excelRow[mapping.consolidates]
  }
  location = buildLocationAddress(location, excelRow, batchTemplate)
  location = buildLocationContact(location, excelRow, mapping, countryCode)
  location = buildPODCODInfo(location, locationParams)
  return location
}

export const calculateAndCheckSumCODAmount = (locations, currentCustomer) => {
  if (_.isUndefined(currentCustomer.currentCompanyId)) {
    return true
  }
  let totalInputedCODAmount = 0
  const limitCODAmount = _.toInteger(currentCustomer.cod_amount_limit_alert)
  _.forEach(locations, (location) => {
    const codFee = parseFloat(location.cod_invoice_fees) || 0
    totalInputedCODAmount += codFee
  })
  return !(limitCODAmount > 0 && totalInputedCODAmount > limitCODAmount)
}

const totalBookingCODAmount = (booking, extraInfos) => {
  if (extraInfos.enable_cod_pod_new_rule) {
    let total = 0
    _.forEach(booking.locations, (location) => {
      if (location.need_pod && location.need_cod) {
        total += parseFloat(location.cod_invoice_fees)
      }
    })
    return total
  }
  return 0
}

export const checkBookingCODLimitAmount = (booking, currentCustomer, extraInfos) => {
  const bookingTemp = { ...booking, verify: { ...booking.verify } }
  const limitAmount = _.toInteger(currentCustomer.cod_amount_limit_alert)
  const totalCODAmount = totalBookingCODAmount(bookingTemp, extraInfos)
  const limitStatus = !(limitAmount > 0 && totalCODAmount > limitAmount)
  bookingTemp.verify.codLimitAmount = limitStatus
  return bookingTemp
}

export const checkCodInvoiceFeeError = (booking, location) => {
  let amount = location.cod_invoice_fees

  if (_.isUndefined(amount)) {
    return true
  }
  amount = $.trim(amount)
  if (!location.verify.cod_invoice_fees) {
    return false
  }
  if (!_.isUndefined(booking.verify.codLimitAmount) && booking.verify.codLimitAmount === false) {
    return false
  }
  return true
}

export const getPickupLocation = (booking) => {
  const sizeLocation = _.size(booking.locations)
  const breakPoint = sizeLocation === 1 ? sizeLocation : sizeLocation - 1
  for (let i = 0; i < breakPoint; i += 1) {
    if (booking.locations[i].order === 0) {
      return booking.locations[i]
    }
  }
  return undefined
}

export const getSelectedLocation = (booking) => {
  for (let i = 0; i < _.size(booking.locations); i += 1) {
    const location = booking.locations[i]
    if (location.is_selected) {
      return location
    }
  }
  return {}
}

export const addCurrentUserToLocation = (currentCustomer, location) => {
  const locationTemp = { ...location, verify: { ...location.verify } }
  if (!_.isEmpty(locationTemp)) {
    locationTemp.recipient_phone = currentCustomer.phone
    locationTemp.recipient_name = currentCustomer.name
    locationTemp.company_name = ''
    locationTemp.verify.recipient_name = true
    locationTemp.verify.recipient_phone = true
    locationTemp.phone_mask = location.phone_mask
    locationTemp.contact_id = location.contact_id
  }
  return locationTemp
}

export const getContactLocation = (location, contact) => {
  const newLocation = { ...location, verify: { ...location.verify } }
  newLocation.lat = contact.latitude
  newLocation.lng = contact.longitude
  newLocation.address_components = contact.address_components
  if (!_.isUndefined(contact.name) && contact.name != null) {
    const contactName = contactUtils.showNameAndCompanyName(contact.name, contact.company_name)
    newLocation.recipient_name = utilCommon.replaceSpecialCharacters(contactName)
    newLocation.verify.recipient_name = true
  }
  if (!_.isUndefined(contact.company_name) && contact.company_name != null) {
    newLocation.company_name = utilCommon.replaceSpecialCharacters(contact.company_name)
    newLocation.verify.company_name = true
  }
  if (!_.isUndefined(contact.phone) && contact.phone != null) {
    newLocation.recipient_phone = utilCommon.replaceSpecialCharacters(contact.phone, undefined, 'recipient_phone')
    newLocation.verify.recipient_phone = true
  }
  if (!_.isUndefined(contact.address_detail) && !_.isEmpty(contact.address_detail)) {
    newLocation.location_note = utilCommon.replaceSpecialCharacters(contact.address_detail)
  }
  if (!_.isUndefined(contact.id)) {
    newLocation.contact_id = contact.id
  }
  if (!_.isUndefined(contact.address) && contact.address != null) {
    newLocation.name = utilCommon.replaceSpecialCharacters(contact.address)
    newLocation.formatted_address = utilCommon.replaceSpecialCharacters(contact.address)
    newLocation.verify.name = true
  }
  if (!newLocation.lat || !newLocation.lng) {
    if (contact.address) {
      const geocoderURL = `${GOOGLE_GEOCODER_URL}&address=${contact.address}&&channel=3`
      Promise.resolve(fetch(geocoderURL))
        .then((response) => response.json().then((body) => ({ response, body })))
        .then(({ response, body }) => {
          if (response.ok) {
            const results = body.results
            if (!_.isEmpty(results)) {
              newLocation.lat = results[0].geometry.location.lat
              newLocation.lng = results[0].geometry.location.lng
              newLocation.name = utilCommon.replaceSpecialCharacters(contact.address)
              newLocation.formatted_address = utilCommon.replaceSpecialCharacters(contact.address)
              newLocation.address_components = results[0].address_components
              newLocation.verify.name = true
            } else {
              newLocation.verify.name = false
            }
          } else {
            newLocation.verify.name = false
          }
        })
    }
  }
  return newLocation
}

export const syncContactToRoundTripDiscount = (locations, pickup) => {
  const destination = _.last(locations)
  return { ...destination, lat: pickup.lat, lng: pickup.lng, name: pickup.name }
}

export const checkEmptyLocation = (location, timeType) => {
  const locationVerify = { ...location.verify }
  if (timeType !== FULL_DAY) {
    if (_.isEmpty(_.trim(location.name)) || !location.lat || !location.lng) {
      locationVerify.name = false
    }
    if (_.isEmpty(_.trim(location.recipient_name))) {
      locationVerify.recipient_name = false
    }
    if (_.isEmpty(_.trim(location.recipient_phone))) {
      locationVerify.recipient_phone = false
    }
  }
  return { ...location, verify: locationVerify }
}

export const updateIsSelectedBookingLocations = (bookings, booking, location) => {
  const bookingsTemp = bookings.map((bookingItem) => {
    if (bookingItem.temp_id === booking.temp_id) {
      const updatedLocations = bookingItem.locations.map((locationItem) => ({
        ...locationItem,
        is_selected: locationItem.temp_id === location.temp_id,
      }))
      return {
        ...bookingItem,
        locations: updatedLocations,
      }
    } else {
      const updatedLocations = bookingItem.locations.map((locationItem) => ({
        ...locationItem,
        is_selected: false,
      }))
      return {
        ...bookingItem,
        locations: updatedLocations,
      }
    }
  })
  return bookingsTemp
}

export const orderLocationWhenOptimize = (location, locationIndex) => {
  const locationTemp = { ...location }
  locationTemp.order = _.parseInt(locationIndex)
  return locationTemp
}

const isEmptyLocation = (location) => {
  if (location.lat && location.lng && location.name) {
    return false
  }
  return true
}

export const addRoundTripDiscount = (booking, location) => {
  const tempBooking = { ...booking }
  const roundTripDisCountLocation = location
  let tempLocations = tempBooking.locations
  tempLocations = _.concat(tempLocations, roundTripDisCountLocation)
  tempBooking.round_trip_discount = true
  tempBooking.locations = tempLocations
  return tempBooking
}

export const removeRoundTripDiscount = (booking, roundTripDisCountLocation) => {
  const tempBooking = { ...booking, locations: [...booking.locations] }
  if (booking.round_trip_discount) {
    if (!_.isEmpty(roundTripDisCountLocation)) {
      _.remove(tempBooking.locations, { temp_id: roundTripDisCountLocation.temp_id })
    } else {
      tempBooking.locations = _.dropRight(tempBooking.locations)
    }
    tempBooking.round_trip_discount = false
  }
  return tempBooking
}

export const isSamePickupDestination = (pickup, destination) =>
  pickup.lng === destination.lng && pickup.lat === destination.lat

export const availableRoundTripDiscount = (locations, vehicleType, timeType, currentCustomer) => {
  const { extraInfos } = store.getState()
  const companyID = currentCustomer.currentCompanyId
  const maxNumberOfLocation = setDefaultMaxLocation(currentCustomer, extraInfos)
  const pKup = _.head(locations)
  if (_.isUndefined(vehicleType)) {
    return false
  }
  if (_.isUndefined(timeType)) {
    return false
  }
  if (timeType !== SCHEDULE) {
    return false
  }
  if (isEmptyLocation(pKup)) {
    return false
  }
  const enableRountTrip = vehicleType.settings.enable_round_trip
  const customerType = !companyID ? CUSTOMER_VAR_NONBP : CUSTOMER_VAR_BP
  if (!_.includes(enableRountTrip, customerType)) {
    return false
  }
  let numOfDropOff = 0
  _.forEach(locations, (location) => {
    if (!isEmptyLocation(location)) {
      numOfDropOff += 1
    }
  })
  if (_.size(locations) === maxNumberOfLocation && isSamePickupDestination(locations[0], _.last(locations))) {
    return true
  }
  if (isEmptyLocation(_.last(locations)) && _.size(locations) === maxNumberOfLocation) {
    return true
  }
  if (numOfDropOff < MIN_AVAILABLE_LOCATION || _.size(locations) >= maxNumberOfLocation) {
    return false
  }
  return true
}

export const isRoundTripDiscountLocation = (booking, location) => {
  const destination = _.last(booking.locations)
  if (booking.round_trip_discount && location.temp_id === destination.temp_id) {
    return true
  }
  return false
}

export const needResetRoundTripDiscount = (booking, locaton) => {
  if (booking.round_trip_discount) {
    const locationsTemp = _.filter(booking.locations, (l) => !_.isUndefined(l.lat) && !_.isUndefined(l.lng))
    const finalLocations = _.filter(locationsTemp, (l) => l.temp_id !== locaton.temp_id)
    const limitAvailable = booking.round_trip_discount ? 3 : 2
    if (_.size(finalLocations) < limitAvailable) {
      return true
    }
  }
  return false
}

export const checkRoundTripDiscountBooking = (booking, currentCustomer) => {
  let bookingTemp = booking
  const timeType = !_.isEmpty(bookingTemp.time_type_option) ? bookingTemp.time_type_option.type_key : undefined
  const isAvailable = availableRoundTripDiscount(
    bookingTemp.locations,
    bookingTemp.vehicle_type,
    timeType,
    currentCustomer
  )
  if (!isAvailable && bookingTemp.round_trip_discount) {
    const destination = _.last(bookingTemp.locations)
    bookingTemp = removeRoundTripDiscount(bookingTemp, destination)
  }
  return bookingTemp
}
