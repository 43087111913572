import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import Promise from 'bluebird'
import moment from 'moment'
import $ from 'jquery'

// UTILS
import * as UtilsCommon from 'utils/new_booking/common'
import { Utils } from 'utils/Utils'
import { TallyUtils } from 'utils/booking/TallyUtils'
import * as bookingUtils from 'utils/booking/common'
import I18n from 'i18n/i18n'
// API
import LocationAPI from 'api/locations'
import SettingAPI from 'api/settings'
// ACTIONS
import * as extraServiceActionCreators from 'store/actions/new_booking/extraServiceActionCreators'
import * as locationActionCreators from 'store/actions/new_booking/locationActionCreators'
import * as bookingActionCreators from 'store/actions/common/bookingActionCreators'
import * as customerActionCreators from 'store/actions/common/customerActionCreators'
import * as popupActionCreators from 'store/actions/common/popupActionCreators'
import * as timeTypeActionCreators from 'store/actions/new_booking/timeTypeActionCreators'
import * as roundTripDiscountActionCreators from 'store/actions/new_booking/roundTripDiscountActionCreators'
import * as serviceTypeActionCreators from 'store/actions/new_booking/serviceTypeActionCreators'
import * as assignDriverActionCreators from 'store/actions/new_booking/assignDriverActionCreators'

// COMPONENTS
import Attachment from 'components/new_booking/attachment/Attachment'
import Badge from 'components/new_booking/extra_service/Badge'
import BadgeForCompany from 'components/new_booking/extra_service/BadgeForCompany'
import CODAndPOD from 'components/new_booking/cod_and_pod/CODAndPOD'
import CODAndPODPopover from 'components/new_booking/cod_and_pod/CODAndPODPopover'
import SelectionPerLocationPopover from 'components/new_booking/extra_service/SelectionPerLocationPopover'
import ExtraRequirementPopups from 'components/new_booking/extra_service/ExtraRequirementPopups'
import EstimatedTransitTimePopup from 'components/common/popup/EstimatedTransitTimePopup'
import JobOrderNumber from 'components/new_booking/job_order_number/JobOrderNumber'
import LocationContacts from 'components/new_booking/locations/LocationContacts'
import OutServiceAreaPopup from 'components/new_booking/locations/OutServiceAreaPopup'
import Map from 'components/new_booking/map/Map'
import Note from 'components/new_booking/note/Note'
import ExtraRequirementForFullDay from 'components/new_booking/extra_service/ExtraRequirementForFullDay'
import ServiceTypeComingSoonPopup from 'components/common/popup/service_type/ServiceTypeComingSoonPopup'
import StepButton from 'components/new_booking/step_button/StepButton'
import StepsHeader from 'components/common/steps_header/StepsHeader'
import Summary from 'components/new_booking/summary/Summary'
import VehicleTypePopups from 'components/new_booking/vehicle_type/VehicleTypePopups'
import Reimbursements from 'components/new_booking/extra_service/Reimbursements'
import ReimbursementPopups from 'components/new_booking/extra_service/ReimbursementPopups'
import CustomReimbursementPopups from 'components/new_booking/extra_service/CustomReimbursementPopups'
import EventNotificationPopup from 'components/new_booking/event/EventNotificationPopup'
import PickupTimePopup from 'components/new_booking/pickup_time/PickupTimePopup'
import DriverPreferences from 'components/new_booking/driver_preferences/DriverPreferences'
import AssignDriverChangeBookingDetailsPopup from 'components/common/popup/assign_driver/AssignDriverChangeBookingDetailsPopup'
import ShowRoute from 'components/common/ShowRoute'
import NumberDrivers from 'components/common/NumberDrivers'
import ContainerSize from 'components/new_booking/container_size/ContainerSize'
import RecentDriversPopup from 'components/common/driver_preference/RecentDriversPopup'
import MoreGroupExtraServices from 'components/new_booking/extra_service/MoreGroupExtraServices'
import RequireSignatures from 'components/common/RequireSignatures'
import TextEditMode from 'components/new_booking/edit_mode/TextEditMode'
import BookingCalendar from 'components/common/BookingCalendar'
import ColorBanner from 'components/new_booking/color_banner/ColorBanner'
import StepOneContent from 'components/new_booking/Step1Content/StepOneContent'
import MiniTooltip from 'components/common/tooltip/MiniTooltip'
import ChangeTimeTypePopup from 'components/new_booking/locations/ChangeTimeTypePopup'
import { UtilsHTML } from 'utils/html'
import CashbackInfo from 'components/common/CashbackInfo'
import CashBackBar from 'components/common/CashBackBar'
import CashBackExplain from 'components/common/popup/CashBackExplain'

// CONSTANTS
import {
  LONG_HAUL,
  FULL_DAY,
  FAV_DRIVER_ID,
  WEB_MULTIPLE,
  WEB_SINGLE,
  DEFAULT_ACCEPTABLE_TIME_TYPES,
  NOW,
  SECTION_TYPE_PAYMENT_LIST,
  CUSTOMER_SPECIAL_SETTINGS,
} from 'constants/bookingConstants'
import {
  POPUP_ID_CHANGE_INFO_AFTER_ASSIGNED_DRIVER,
  POPUP_ID_YOUR_RECENT_DRIVERS,
  POPUP_CASHBACK_EXPLAIN
} from 'constants/common/popupConstants'
import { BP_ACCOUNT } from 'constants/imageConstants'

// API
import TallySummary from 'components/common/TallySummary'
import LongHaulInvalidDatePopup from 'components/new_booking/pickup_time/LongHaulInvalidDatePopup'
import BookingAPI from '../api/bookings'

import { withNavigate } from 'hocs/withNavigate'
import { checkLocationsActionsCreator } from 'store/toolkit/newBooking/checkLocations.reducer'
import { documentReturnActionsCreator } from 'store/toolkit/newBooking/documentReturn.reducer'
import { bookingActionsCreator } from 'store/toolkit/bookings/booking.reducer'
import { hideLoading } from 'assets/javascripts/webapp-v2/common'
import { crossStorageUtils } from 'utils/crossStorage';
import { extraInfosActionsCreator } from 'store/toolkit/extraInfos/extraInfos.reducer'
// ASSETS
import FrontPage from 'components/FrontPage'
import CustomerAPI from 'api/customers'
import mapUtils from 'utils/common/map'
import CommonUtils from 'utils/common'
import { DRIVER_PREFERENCES } from 'constants/newBookingConstants'
import SwitchAccount from 'view/AccountManagementView/SwitchAccount'
import { SIGN_IN_MODAL } from 'components/new_booking/guest_flow/constants'
import { accountManageActions } from 'store/toolkit/accountManage/accountManage.reducer'

const mapStateToProps = state => ({
  currentStep: state.currentStep,
  locations: state.locations,
  extraInfos: state.extraInfos,
  bookAgainDetails: state.bookAgainDetails,
  timeType: state.timeType,
  timeTypeUI: state.timeTypeUI,
  selectedVehicleTypeID: state.selectedVehicleTypeID,
  selectedServiceTypeID: state.selectedServiceTypeID,
  currentPopupID: state.currentPopupID,
  isNewDriver: state.isNewDriver,
  extraServices: state.extraServices,
  currentCustomer: state.currentCustomer,
  dynamicTexts: state.extraInfos?.dynamicTexts,
  requireSignatures: state.requireSignatures,
  booking: state.booking,
  autoSwitchLongHaul: state.autoSwitchLongHaul,
  showDatePicker: state.showDatePicker,
  popupContentManagement: state.popupContentManagement || [],
  numberDrivers: state.countNumberOfOnlineDrivers,
  pickupTime: state.pickupTime,
  favDriverId: state.favDriverId,
  isCheckedSubAccount: state.checkSubAccount,
  subAccountTagPicked: state.subAccountTagPicked,
  isLoginStep3: state.isLoginStep3,
  serviceTypes: state.serviceTypes,
  stepActions: state.stepActions,
})

function mapDispatchToProps(dispatch) {
  return {
    extraServiceActions: bindActionCreators(extraServiceActionCreators, dispatch),
    locationActions: bindActionCreators(locationActionCreators, dispatch),
    assignDriverActions: bindActionCreators(assignDriverActionCreators, dispatch),
    bookingActions: bindActionCreators(bookingActionCreators, dispatch),
    customerActions: bindActionCreators(customerActionCreators, dispatch),
    popupActions: bindActionCreators(popupActionCreators, dispatch),
    checkLocationActions: bindActionCreators(checkLocationsActionsCreator, dispatch),
    documentActions: bindActionCreators(documentReturnActionsCreator, dispatch),
    timeTypeActions: bindActionCreators(timeTypeActionCreators, dispatch),
    roundTripDiscountActions: bindActionCreators(roundTripDiscountActionCreators, dispatch),
    serviceTypeActions: bindActionCreators(serviceTypeActionCreators, dispatch),
    bookingActionsCreator: bindActionCreators(bookingActionsCreator, dispatch),
    extraInfosActions: bindActionCreators(extraInfosActionsCreator, dispatch),
    accountManageActionsProps: bindActionCreators(accountManageActions, dispatch),
  }
}

class NewBookingContainer extends React.Component {
  static checkAfterSignedIn(object) {
    if (_.isNull(object) || _.isEmpty(object.authentication_token)) {
      crossStorageUtils.setAccessToken('')
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      loadDriverPreferences: false,
      finishedStep1: false,
      handlingUnitValue: null,
      isValidLH: false,
      outsideList: [],
      errorDistanceList: [],
      isHaveLHAddress: false,
      isErrorPickupLocation: false,
      isShowPopupChangePickupLocation: false,
      extendedList: [],
      isShowChangeTimeType: false,
      isShowChangeTimeTypeClick: false,
      comingSoonServiceTypeName: '',
      isShowComingService: false,
      iconComingSoonService: '',
      isShowPickupTimePopup: false,
      isDateFetched: false,
      initDatePickup: null,
      isWaitingSetData: false,
      frontPageMenu: {},
    }
    this.handleClickOnStep = this.handleClickOnStep.bind(this)
    this.driverPreferencesPopup = createRef()
    this.refStep3Container = createRef(null)
    this.isLoadedAttachment = createRef(false)

    this.stepButton = null
    // debounce 300ms to avoid calling api so many times
    this.triggerGetTallyDataAction = _.debounce(this.triggerGetTallyDataAction, 300)
    this.cashbackUpdateValueForService = _.debounce(this.cashbackUpdateValueForService, 300)
    this.triggerGetTallyTransitTimeAction = _.debounce(this.triggerGetTallyTransitTimeAction, 300)
    this.triggerLoadEtaPopupDataIfNeeded = _.debounce(this.triggerLoadEtaPopupDataIfNeeded, 300)
    this.closePicker = this.closePicker.bind(this)
    this.handleViewStepOne = this.handleViewStepOne.bind(this)
    this.handleHandlingUnitValue = this.handleHandlingUnitValue.bind(this)
    this.setOutsideList = this.setOutsideList.bind(this)
    this.setListErrorDistance = this.setListErrorDistance.bind(this)
    this.handleErrorPickupLocation = this.handleErrorPickupLocation.bind(this)
    this.handleShowChangePickupLocation = this.handleShowChangePickupLocation.bind(this)
    this.setIsShowChangeTimeType = this.setIsShowChangeTimeType.bind(this)
    this.setIsShowChangeTimeTypeClick = this.setIsShowChangeTimeTypeClick.bind(this)
    this.handleCancelChangeTimeTypePopup = this.handleCancelChangeTimeTypePopup.bind(this)
    this.checkTokenToSignIn = this.checkTokenToSignIn.bind(this)
    this.handleUpdateAuthenticationToken = this.handleUpdateAuthenticationToken.bind(this)
    this.setComingSoonServiceTypeName = this.setComingSoonServiceTypeName.bind(this)
  }

  componentDidMount() {
    const {
      currentCustomer, bookAgainDetails, bookingActions, customerActions,
      selectedServiceTypeID, timeType, assignDriverActions, locations,
      selectedVehicleTypeID, stepActions, isNewDriver,
      extraInfos, documentActions, area, setIsWaitingCalculate,
    } = this.props
    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get('token')
    const bookingIDParam = urlParams.get('booking_id')
    const isMKT = urlParams.get('is_mkt')

    if (bookingIDParam && !_.isNaN(Number(bookingIDParam))) {
      window.location.href = `/bookings/${bookingIDParam}`
    }

    const getDataFrontPageMenu = async (countryCode, areaId) => {
      const frontPageMenuData = await CustomerAPI.getFrontPageMainMenu(countryCode, areaId);
      const { lcl_display_new_badge, turn_on_lcl_on_front_page } = extraInfos
      const newList = frontPageMenuData?.data?.main_services?.body?.reduce((prevItem, item) => {
        if (item.data_type === 'LCL') {
          if (turn_on_lcl_on_front_page) {
            if (lcl_display_new_badge) prevItem.push(item)
            else prevItem.push({ ...item, label: '' })
          }
        } else prevItem.push(item)
        return prevItem
      }, [])
      const mainServices = {
        ...frontPageMenuData?.data?.main_services,
        body: newList || []
      }
      const finalData = {
        ...frontPageMenuData?.data,
        main_services: mainServices || {}
      }

      this.setState({ frontPageMenu: finalData || {} })
    }

    getDataFrontPageMenu(area.country_code, area.id)

    if (isMKT && TallyUtils.validateDataForRecalculatingTally({
      timeType, vehicleTypeID: selectedServiceTypeID, locations
    })) {
      this.triggerGetTallyDataAction()
    }

    this.checkTokenToSignIn()

    customerActions.getCustomerCreditAmount()

    if (extraInfos.document_return_address) {
      const bookingTracking =
        bookAgainDetails && bookAgainDetails.booking_tracking
          ? bookAgainDetails.booking_tracking
          : extraInfos.document_return_address;
      documentActions.updateDocumentReturn(bookingTracking)
    }

    // Get Favorite Amount number at step 2
    if (currentCustomer.authentication_token) {
      const params = {
        include_fleet_driver: true,
        vehicle_type_id: selectedVehicleTypeID,
        area_id: currentCustomer.area_id,
        country_code: extraInfos.country_code,
        company_id: currentCustomer.currentCompanyId === 0 ? undefined : currentCustomer.currentCompanyId,
      }
      bookingActions.getFavoriteAmount(params)
    }

    if (bookingUtils.isEditBooking() && !UtilsCommon.isCustomerEditBooking()) {
      // Get assigned driver for edit flow
      if (bookingUtils.hasAssignDriver()) {
        const params = CommonUtils.getParamsForLoadFavoriteCommon(this.props, false)
        bookingActions.loadFavoriteDrivers(params, stepActions, (response) => {
          const assignableDrivers = _.isEmpty(response?.data.data) ? [] : response.data.data
          if (assignableDrivers.length > 0) {
            const driverId = +bookingUtils.getQueryVariable('driver_id')
            const assignableDriver = assignableDrivers.find(driver => driver.id === driverId
              || driver.fleet_partner_id === driverId)
            assignDriverActions.changeAssignDriver(assignableDriver)
          }
        })
      } else {
        // Get sendToFavorite default from booking when edit first load
        const { send_first_to_favorite: sendFirstToFavorite } = bookAgainDetails
        let defaultFav = sendFirstToFavorite ? DRIVER_PREFERENCES.favorite : DRIVER_PREFERENCES.all
        if (isNewDriver) defaultFav = DRIVER_PREFERENCES.assign
        bookingActions.updateBookingAttributes({
          driverPreferencesTab: defaultFav
        })
      }
    }

    // Noted: window.cancelToEdit is set by Rails
    if (bookingUtils.isEditBooking()) {
      this.setState({ isWaitingSetData: true })
      const {
        extraServiceActions, extraInfos,
        timeType, locationActions, checkLocationActions
      } = this.props
      const tasks = []
      tasks.push(
        new Promise((resolve) => {
          extraServiceActions.getExtraServicesSingle(() => {
            // recalculate price for tally(step2Price) when the customer edit booking
            if(UtilsCommon.isCustomerEditBooking()) {
              bookingActions.calculateExtraServicesPrice()
            }

            this.setState({
              loadDriverPreferences: true
            }, () => {
              if (UtilsCommon.isCancelToEdit()) {
                const bookingId = window.location.pathname.split('/')[2]
                if (!bookingUtils.isPaymentBooking(bookAgainDetails.payment_method_for_non_bp)) {
                  bookingActions.getBookingPaymentSettingAction(bookingId, SECTION_TYPE_PAYMENT_LIST[CUSTOMER_SPECIAL_SETTINGS])
                }
              }
            })

            resolve()
          })
        })
      )

      // update COD & POD
      const fees = bookAgainDetails.cod_pod_fees || 0
      if (timeType === LONG_HAUL) {
        $('#cod-pod-fees').html(`${I18n.t('webapp.pricing_display.free')}`)
      } else {
        $('#cod-pod-fees').html(`${Utils.formatFee(fees, extraInfos.country_language, extraInfos.currency)}`)
      }

      // Should call check location when edit booking to verify New gen POD
      const {
        locations,
        service_type_id: serviceTypeID,
        vehicle_type_id: vehicleTypeID,
        pickup_time: pickupTime
      } = bookAgainDetails

      const isEnableGoogleMap = mapUtils.isEnableGoogleMap(extraInfos)
      tasks.push(
        LocationAPI.getCheckLocations(
          locations,
          { timeType, serviceTypeID, vehicleTypeID, pickupTime },
          {
            countryCode: currentCustomer.country_code || extraInfos?.country_code,
            companyId: currentCustomer.currentCompanyId,
            isEnableGoogleMap
          },
          (resultApi) => {
            if (resultApi.status !== 400) {
              checkLocationActions.updateCheckLocationInfos(resultApi)
            }
          }
        )
      )

      tasks.push(locationActions.initTmpLocations())
      // when editing booking, all booking data is set in bookAgainDetails
      // Some logic reply on state.booking to calculate data and show info
      // We have to copy data from bookAgainDetails to booking.
      tasks.push(this.prepareTallyDataWhenEditing())
      tasks.push(this.triggerLoadEtaPopupDataIfNeeded())

      Promise.all(tasks)
        .then(() => Utils.sleep(2000))
        .then(() => (
          // Notes: During PickupTime init it trigger actionChangeTimeType and cause state.dataChange being set true
          // We need to reset it for 'cancel to edit' case,
          //    because we actually apply from model directly, not change by user
          // This is not good approach, but we don't have time to refactor code to handing dataChange better
          bookingActions.clearDataChangeForEditBooking()
        ))
        .then(() => (
          bookingActions.turnBookingAgainDataIntoState(bookAgainDetails)
        )).then(() => (this.setState({ isWaitingSetData: false })))
        .then(() => {
          if (UtilsCommon.isCancelToEdit()) {
            bookingActions.calculate(() => {
              setIsWaitingCalculate(false)
              hideLoading()
            })
          } else hideLoading()
        })
    }

    $('body').addClass('Step-Route')

    const redirectTarget = urlParams.get('target')
    if (redirectTarget) {
      setTimeout(() => {
        return window.location.replace(`/${redirectTarget}&lang=${urlParams.get('lang')}`)
      }, 500);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      currentStep
    } = this.props
    if (nextProps.currentStep && currentStep !== nextProps.currentStep && currentStep === 1) {
      this.setState({
        finishedStep1: true,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      timeType,
      locationActions,
    } = this.props
    if (timeType !== prevProps.timeType) {
      locationActions.resetDropOffZones()
    }

    const detectedChanges = this.detectBookingDataChanges(prevProps)
    this.triggerRecountCashback(detectedChanges, prevProps, prevState)

    if (detectedChanges.isServiceVehicleTimeTypeChanged && this.isEnabledTally()) {
      this.triggerLoadEtaPopupDataIfNeeded()
    }
    this.invalidateChangeForTally(prevProps, detectedChanges)
  }

  /**
   * Set value for isHaveLHAddress state
   * @param {Boolean} isHaveLHAddress
   */
  setIsHaveLHAddress(isHaveLHAddress) {
    this.setState({ isHaveLHAddress })
  }

  setOutsideList(outsideList) {
    this.setState({
      outsideList,
    })
  }

  setExtendedList(extendedList) {
    this.setState({ extendedList })
  }

  setIsShowChangeTimeType(isShowChangeTimeType) {
    this.setState({ isShowChangeTimeType })
  }

  setIsShowChangeTimeTypeClick(isShowChangeTimeTypeClick) {
    this.setState({ isShowChangeTimeTypeClick })
  }

  setListErrorDistance(errorDistanceList) {
    this.setState({
      errorDistanceList
    })
  }

  setComingSoonServiceTypeName(comingSoonServiceTypeName) {
    this.setState({
      comingSoonServiceTypeName,
    })
  }

  setShowComingSoonService = (isShowComingService) => {
    this.setState({
      isShowComingService
    })
  }

  setIconComingSoonService = (iconComingSoonService) => {
    this.setState({
      iconComingSoonService
    })
  }

  setIsShowPickupTimePopup = (isShowPickupTimePopup) => {
    this.setState({
      isShowPickupTimePopup
    })
  }
  // if we use selector we can optimize performance
  getEtaPopupData() {
    const {
      area,
      timeType,
      popupContentManagement,
    } = this.props
    const popupType = bookingUtils.getETAPopupTypeByTimeType(timeType)
    const popupData = popupContentManagement.find(
      item => (item.area_id === area.id && item.popup_type === popupType)
    )

    return popupData && popupData.data
  }

  checkTokenToSignIn = async () => {
    const { currentCustomer } = this.props
    const urlParams = new URLSearchParams(window.location.search)
    const jwtToken = urlParams.get('token')
    const companyId = urlParams.get('companyId')

    let isLoginWithJwtToken = false
    let isSwitchAccount = false
    if (jwtToken) {
      urlParams.delete('token')
      urlParams.delete('companyId')
      const oldToken = window.localStorage.getItem('access_token')
      isLoginWithJwtToken = !oldToken
      isSwitchAccount = oldToken && oldToken !== jwtToken && companyId
      crossStorageUtils.setAccessToken(jwtToken)

      const newUrl = `${window.location.pathname}?${urlParams.toString()}`
      window.history.pushState(null, '', newUrl)
    }

    if (isSwitchAccount) {
      this.handleUpdateAuthenticationToken()
    } else if (_.isEmpty(currentCustomer.authentication_token) || isLoginWithJwtToken) {
      this.handleUpdateAuthenticationToken()
    }
  }

  handleUpdateAuthenticationToken() {
    this.calculateAfterSignIn()
  }

  handleCancelChangeTimeTypePopup() {
    const { outsideList, extendedList } = this.state
    const newList = _.union(outsideList, extendedList)
    this.setOutsideList(newList)
    this.setIsShowChangeTimeType(false)
    const element = document.getElementById(`booking_locations_attributes_${newList[0]}_name`)
    element.click()
  }

  triggerLoadEtaPopupDataIfNeeded() {
    const {
      popupActions,
      currentCustomer,
      area,
      timeType,
      popupContentManagement,
    } = this.props
    const popupType = bookingUtils.getETAPopupTypeByTimeType(timeType)
    const popupData = popupContentManagement.find(
      item => (item.area_id === area.id && item.popup_type === popupType)
    )

    if (!popupData) {
      popupActions.fetchPopupContent(area.id, timeType, currentCustomer)
    }
  }

  prepareTallyDataWhenEditing() {
    const {
      bookAgainDetails,
    } = this.props

    const { isValidLH } = this.state

    if (this.isEnabledTally()) {
      const {
        bookingActions,
        locationActions,
      } = this.props

      const bookingData = TallyUtils.extractTallyDataFromBookingAgainDetail(bookAgainDetails)

      return Promise
        .resolve(bookingActions.updateBookingAttributes(bookingData))
        .then(() => (
          // trigger this action to setup location and put into state.
          // atm this logic is specific for tally, if we have similar feature please refactor it.
          locationActions.setUpLocations()
        ))
        .then(() => {
          // refetch tally data for step1
          const params = {
            specificStep: 1,
            isLocationChanged: false,
            isValidLH,
          }

          return bookingActions.getTallyData(params)
        })
        .then(() => {
          const tasks = []
          if (bookingData.time_type === FULL_DAY) {
            tasks.push(bookingActions.getTallyTransitTime({
              specificStep: 1,
              isLocationChanged: false,
              isValidLH,
              callback: () => undefined,
            }))
          }
          return Promise.all(tasks)
        })
        .then(() => {
          this.setState({
            finishedStep1: true,
          })
        })
    }

    return Promise.resolve(true)
  }

  /**
   * We only trigger any additional api call only when tally setting is enabled
   *    otherwise keep the flow as it is without tally.
   */
  invalidateChangeForTally(prevProps, detectedChanges) {
    if (!this.isShowedTallybar()) {
      return
    }

    const {
      timeType,
      currentStep,
      bookingActions,
    } = this.props

    const {
      finishedStep1
    } = this.state

    // const detectedChanges = this.detectBookingDataChanges(prevProps)
    const dataChanged = Object.values(detectedChanges).some(v => (!!v))
    const isChangeStep = prevProps.currentStep !== currentStep

    let task
    if (dataChanged || isChangeStep) {
      const {
        selectedServiceTypeID: vehicleTypeID,
        locations,
      } = this.props

      if (TallyUtils.validateDataForRecalculatingTally({ timeType, vehicleTypeID, locations })) {
        this.triggerGetTallyTransitTimeAction(detectedChanges, isChangeStep, prevProps.currentStep)
        if (!(isChangeStep && currentStep === 1)) {
          task = Promise.promisify(this.triggerGetTallyDataAction, { context: this })(detectedChanges)
        }
      }
    }

    const isResetData = currentStep === 1
      && (detectedChanges.isServiceVehicleTimeTypeChanged || detectedChanges.isLocationChanged)
      && finishedStep1

    if (isResetData) {
      Promise.resolve(task).then(() => {
        this.setState({
          finishedStep1: false,
        })

        bookingActions.resetTallyDataStep2()
      })
    }
  }

  triggerRecountCashback(detectedChanges, prevProps, prevState) {
    const {
      currentStep
    } = this.props
    const {
      isHaveLHAddress
    } = this.state
    const dataChanged = Object.values(detectedChanges).some(v => (!!v))
    if (dataChanged
      || (prevProps.currentStep !== currentStep)
      || (prevState.isHaveLHAddress !== isHaveLHAddress)
    ) {
      this.cashbackUpdateValueForService(prevProps)
    }
  }

  detectBookingDataChanges(prevProps) {
    /**
     * We don't need to detect change for optimize route and return discount (round_trip_discount)
     *    because change of optimize route or return discount will make change to locations
     */
    const oldValue = {
      timeType: prevProps.timeType,
      vehicleTypeID: prevProps.selectedVehicleTypeID,
      serviceTypeID: prevProps.selectedServiceTypeID,
      locations: prevProps.locations,
      extraServices: prevProps.extraServices,
      codPodFees: prevProps.booking && prevProps.booking.cod_pod_fees,
      pickupTime: prevProps.pickupTime,
      currentCompanyId: prevProps.currentCustomer.currentCompanyId
    }

    const {
      timeType,
      selectedVehicleTypeID: vehicleTypeID,
      selectedServiceTypeID: serviceTypeID,
      locations,
      extraServices = {},
      booking: { cod_pod_fees: codPodFees },
      pickupTime,
      currentCustomer: { currentCompanyId: currentCompanyId }
    } = this.props

    const newValue = {
      serviceTypeID,
      timeType,
      vehicleTypeID,
      locations,
      extraServices,
      codPodFees,
      pickupTime,
      currentCompanyId
    }

    return bookingUtils.detectBookingDataChanges(oldValue, newValue)
  }

  cashbackUpdateValueForService(prevProps) {
    const {
      currentStep,
      bookingActions,
      customerActions,
      locations,
      timeType,
      selectedVehicleTypeID,
    } = this.props
    const {
      isHaveLHAddress
    } = this.state
    if (currentStep === 1) {
      const data = {
        locations_attributes: locations,
        time_type: timeType,
        vehicle_type_id: selectedVehicleTypeID
      }
      customerActions.calculateCustomerCashbackPercent(data, isHaveLHAddress)
    }
    if (currentStep === 2) {
      bookingActions.calculateCashback(null, prevProps.currentStep === currentStep)
    }
  }

  triggerGetTallyTransitTimeAction(changes, isChangeStep, prevStep) {
    const {
      currentStep,
      bookingActions,
      timeType,
    } = this.props

    const {
      isLocationChanged,
      isTransitTimeChanged,
    } = changes

    const { isValidLH } = this.state

    const params = {
      specificStep: currentStep,
      isLocationChanged,
      isValidLH,
      callback: () => undefined
    }

    // for full-day we call separate api to recalculate transit time
    //    but other time type still use the same calculate api
    if ((isTransitTimeChanged || isChangeStep) && prevStep !== 3 && currentStep === 2 && timeType === FULL_DAY) {
      bookingActions.getTallyTransitTime(params)
    }
  }
  triggerGetTallyDataAction(changes = {}, callback) {
    const {
      currentStep,
      bookingActions,
      locations,
      selectedServiceTypeID,
      selectedVehicleTypeID,
      currentCustomer,
      timeType,
      area,
      bookingActionsCreator,
      extraInfos,
      pickupTime
    } = this.props
    if (currentStep === 2) return Promise.resolve(bookingActions.calculateExtraServicesPrice())
    const { isLocationChanged } = changes

    const params = {
      specificStep: currentStep,
      isLocationChanged,
    }
    const isSimpleData = true
    const { locationCount } = bookingUtils.calculateAndFormatLocationList(locations)
    if (locationCount === 0) return null
    const isEnableGoogleMap = mapUtils.isEnableGoogleMap(extraInfos)
    return Promise.resolve(LocationAPI.getCheckLocations(
      locations,
      {
        timeType,
        serviceTypeID: selectedServiceTypeID,
        vehicleTypeID: selectedVehicleTypeID,
        isSimpleData,
        pickupTime
      },
      {
        countryCode: area.country_code,
        companyId: currentCustomer.currentCompanyId,
        isEnableGoogleMap
      },
      (res) => {
        if (res?.status !== 400) {
          const isValidLH = res?.long_haul_address_valid && res?.long_haul_pickup?.is_valid
          this.setIsHaveLHAddress(!!isValidLH)
          const indexList = bookingUtils.getIndexListOfValidAddress(locations)
          const outsideList = res?.outside_list ?? []
          const extendedList = res?.extended_list ?? []
          const {
            formatOutSideList, formatExtendList, normalOSAList,
          } = bookingUtils.getOSAAndESAList(locations, indexList, outsideList, extendedList)
          let finalOSAList = formatOutSideList
          let finalExtendList = formatExtendList
          bookingActions.getTallyData({ ...params, isValidLH }).then(() => (callback && callback())).then(() => {
            if (timeType !== NOW) {
              finalOSAList = normalOSAList
              finalExtendList = []
            }
            bookingActionsCreator.updateOutSideList({ payload: finalOSAList })
            if (_.includes(DEFAULT_ACCEPTABLE_TIME_TYPES, timeType)) {
              this.setOutsideList(finalOSAList)
              this.setExtendedList(finalExtendList)
            }
          })
        }
      }
    ))
  }

  displayStep(step) {
    const { currentStep } = this.props
    if (step === currentStep) {
      return { display: 'block' }
    }
    return { display: 'none' }
  }

  calculateAfterSignIn() {
    const { bookingActions, currentStep } = this.props
    if (currentStep === 3) {
      bookingActions.calculate(() => undefined)
    }
  }

  handleClickOnStep(step, currentStep) {
    const paramStep = currentStep - step
    this.stepButton.prevStep(paramStep)
  }

  /**
   * To Handle Tabview on Step 1
   * @param {boolean} isShow value will show current view on step 1
   */
  handleViewStepOne(isShow) {
    const {
      locationActions,
      locations,
      roundTripDiscountActions,
      timeType,
      extraInfos
    } = this.props

    // Reset Data of Each View
    if (timeType === FULL_DAY && locations.length === 1) {
      locationActions.resetLocations()
    } else {
      Promise.resolve(
        locationActions.resetOptimizeLocations()
      ).then(() => {
        roundTripDiscountActions.update(false)
        locationActions.resetLocations()
      })
    }
    const isEnableGoogleMap = mapUtils.isEnableGoogleMap(extraInfos)
    locations.forEach((location) => {
      if (location.marker) {
        if (isEnableGoogleMap) {
          location.marker.setMap(null)
          window.google.maps.event.clearListeners(location.marker, 'dragend')
        } else {
          location.marker.remove();
        }
      }
    })
  }

  /**
   * Handle event click for each handling unit
   * @param {string} id of handling unit
  */
  handleHandlingUnitValue(newItem) {
    this.setState({ handlingUnitValue: newItem })
  }

  isEnabledTally() {
    const {
      extraInfos,
      serviceTypes,
      selectedServiceTypeID,
      selectedVehicleTypeID,
    } = this.props

    const selectedServiceType = _.filter(serviceTypes, e => e.id === selectedServiceTypeID)[0] || {}
    const vehicleType = _.find(selectedServiceType.vehicle_types, item => (+item.id === +selectedVehicleTypeID))

    return TallyUtils.isEnabledTally({ extraInfos, vehicleType })
  }

  isShowedTallybar() {
    const {
      currentStep,
    } = this.props

    return this.isEnabledTally() && (currentStep === 1 || currentStep === 2)
  }


  closePicker(cancel, selectedDateTime) {
    const { bookingActions } = this.props || {}
    if (!cancel) {
      const { timeTypeActions } = this.props
      timeTypeActions.changePickupTime(selectedDateTime)
      timeTypeActions.showDatePicker(false)
      CommonUtils.moengageTrackEvent('Select Pickup Time', {
        'Pickup Time': selectedDateTime,
      })
      $('#loading-modal').addClass('visible')
      bookingActions.calculate(() => {
        this.stepButton.stepChecking()
        $('#loading-modal').removeClass('visible')
      })
    }
  }

  handleShowChangePickupLocation(value) {
    this.setState({ isShowPopupChangePickupLocation: value })
  }

  handleErrorPickupLocation(value) {
    this.setState({ isErrorPickupLocation: value })
  }

  static renderAddNewBookingToBatch() {
    const urlParams = new URLSearchParams(window.location.search)
    const batchId = urlParams.get('batch_id')
    if (!Number(batchId)) {
      return null
    }

    const urlDirectsBachToBatchManage = `/batches?batch_id=${batchId}`

    return (
      <div className="booking-edit-mode__container">
        <div className="booking-edit-mode_block">
          <div className="booking-edit-mode">
            {I18n.t('webapp.new_booking.booking_added_to_batch')}
            :
            {' '}
            <a href={urlDirectsBachToBatchManage} target="_blank" rel="noopener noreferrer" type="button">
              {batchId}
            </a>
          </div>
        </div>
      </div>
    )
  }

  renderCashBackInfo() {
    const {
      booking,
      extraInfos,
      currentStep
    } = this.props
    const displayPrice = Utils.formatFee(booking.cashBack?.cash_back_amount, extraInfos.country_language, booking.currency)

    if (currentStep !== 3 || !booking.cashBack?.cash_back_amount) return null

    return (
      <CashbackInfo cashbackAmount={displayPrice} />
    )
  }

  render() {
    const {
      area, currentStep, timeType, serviceTypes,
      selectedVehicleTypeID, selectedServiceTypeID,
      currentPopupID, isNewDriver, assignDriverActions, extraInfos,
      currentCustomer, dynamicTexts,
      locations, booking, autoSwitchLongHaul, showDatePicker, pickupTime,
      numberDrivers, vehicleInfos, subAccountTagPicked, isCheckedSubAccount,
      accountManageActionsProps, isWaitingCalculate, setIsWaitingCalculate,
    } = this.props
    const {
      loadDriverPreferences,
      handlingUnitValue,
      outsideList,
      errorDistanceList,
      isHaveLHAddress,
      isErrorPickupLocation,
      isShowPopupChangePickupLocation,
      extendedList,
      isShowChangeTimeType,
      isShowChangeTimeTypeClick,
      comingSoonServiceTypeName,
      isShowComingService,
      iconComingSoonService,
      isShowPickupTimePopup,
      initDatePickup,
      isWaitingSetData,
      frontPageMenu,
    } = this.state
    const showDriverPreference = (isNewDriver ? loadDriverPreferences : true) && currentStep === 2
    const isBusiness = !!currentCustomer?.currentCompanyId
    const defaultService = _.filter(serviceTypes, e => e.id === selectedServiceTypeID)?.[0] || {}
    const vehicleType = _.find(defaultService?.vehicle_types, { id: selectedVehicleTypeID })
    const tallyData = TallyUtils.calculateTallyData({
      locations,
      vehicleTypeID: selectedVehicleTypeID,
      timeType,
    }, { booking, specificStep: currentStep, vehicleType })
    const tallyBarVisibility = {
      price: true,
      distance: true,
      transitTime: true,
    }
    tallyBarVisibility.transitTime = true
    const isShowChangePopup = !_.isEmpty(extendedList) && timeType === NOW && isShowChangeTimeType
    const selectedServiceType = defaultService
    const selectedVehicleType = _.find(selectedServiceType?.vehicle_types, item => (+item.id === +selectedVehicleTypeID))
      || selectedServiceType?.vehicle_types?.[0]
    const isEnabledTally = this.isEnabledTally()

    const etaPopupData = this.getEtaPopupData()
    const etaNotesIconVisible = !!etaPopupData
    const numberOfDrivers = (numberDrivers && numberDrivers.number_of_drivers)
      || (vehicleInfos && vehicleInfos.number_of_drivers)
    const unitName = (!_.isEmpty(numberDrivers) && numberDrivers.unit)
      || (!_.isEmpty(vehicleInfos) && vehicleInfos.unit)
    const totalFees = (!currentCustomer.currentCompanyId && !booking.use_credit)
      ? booking.display_total_fees
      : booking.total_fees
    const showResponsiblePayment = totalFees > 0
    const isCeb = UtilsCommon.isCustomerEditBooking()
    const createdBy = _.get(booking, 'created_by', WEB_SINGLE)
    const isEditWebMultipleBooking = (createdBy === WEB_MULTIPLE && isCeb)
    // Config  BookingCalendar on step 3
    const brandIco = extraInfos.country_code === 'PH' ? 'transportify' : 'deliveree'
    const minMinutes = UtilsCommon.minuMumBufferMinutes(vehicleType, timeType, 0, true)
    /* eslint-disable react/no-danger */

    const curUrl = window.location.href
    const url = new URL(curUrl)
    const driverId = url.searchParams.get(FAV_DRIVER_ID)
    if (currentCustomer && _.isEmpty(currentCustomer.authentication_token)) {
      if (!_.isEmpty(driverId)) {
        window.localStorage.setItem(FAV_DRIVER_ID, driverId)
        accountManageActionsProps.updateModalAccountManage(SIGN_IN_MODAL)
      }
      window.localStorage.setItem(FAV_DRIVER_ID, '')
    } else if (!_.isEmpty(driverId)) {
      window.localStorage.setItem(FAV_DRIVER_ID, driverId)
    }

    const cashBackPercent = booking.cash_back_reward
    const urlParams = new URLSearchParams(window.location.search)
    const isHideFrontPage =
      urlParams.get('ftl') ||
      urlParams.get('frontPage') ||
      urlParams.get('is_switch_area') ||
      urlParams.get('is_mkt') ||
      urlParams.get('location[0][name]') ||
      urlParams.get('location[1][name]') ||
      urlParams.get('mk_booking[locations]') ||
      urlParams.get('batch_id') || 
      urlParams.get('skip') === 'fp'
      
    const frontPageBg = {
      backgroundImage: `url(${frontPageMenu.background_image || ''})`,
      backgroundSize: 'cover' 
    }

    const isShowFrontPage = !isHideFrontPage && extraInfos.turn_on_front_page && (window.location.pathname === '/' || window.location.pathname === '/bookings/new')
    const isEnableGoogleMap = mapUtils.isEnableGoogleMap(extraInfos)
    return (
      <div>
        <div className="Booking">
          <div className="SingleBookingLayout main-view" style={isShowFrontPage ? frontPageBg : {}}>
            {isShowFrontPage ? <FrontPage frontPageMenu={frontPageMenu} />
              : (<div id='step-wrapper' className="BookingWizard">
                {isCeb && (<TextEditMode />)}
                {NewBookingContainer.renderAddNewBookingToBatch()}
                {showDatePicker && (<ColorBanner timeType={timeType} />)}
                <StepsHeader
                  configTitle={[]}
                  currentStep={currentStep}
                  onClick={this.handleClickOnStep}
                />
                <div ref={this.refStep3Container} className="Content ContentUnsetPosition">
                  <div id="Step-01" style={this.displayStep(1)}>
                    <StepOneContent
                      errorDistanceList={errorDistanceList}
                      setListErrorDistance={this.setListErrorDistance}
                      defaultValue={defaultService}
                      area={area}
                      currentStep={currentStep}
                      currentCustomer={currentCustomer}
                      booking={booking}
                      handleViewStepOne={this.handleViewStepOne}
                      handlingUnitValue={handlingUnitValue}
                      handleHandlingUnitValue={this.handleHandlingUnitValue}
                      isEditWebMultipleBooking={isEditWebMultipleBooking}
                      outsideList={outsideList}
                      setOutsideList={this.setOutsideList}
                      countryCode={extraInfos.country_code}
                      extraInfos={extraInfos}
                      isHaveLHAddress={isHaveLHAddress}
                      isShowPopupChangePickupLocation={isShowPopupChangePickupLocation}
                      handleErrorPickupLocation={this.handleErrorPickupLocation}
                      handleShowChangePickupLocation={this.handleShowChangePickupLocation}
                      setIsShowChangeTimeTypeClick={this.setIsShowChangeTimeTypeClick}
                      isShowChangeTimeTypeClick={isShowChangeTimeTypeClick}
                      extendedList={extendedList}
                      setComingSoonServiceTypeName={this.setComingSoonServiceTypeName}
                      setShowComingSoonService={this.setShowComingSoonService}
                      setIconComingSoonService={this.setIconComingSoonService}
                      setIsShowPickupTimePopup={this.setIsShowPickupTimePopup}
                      initDatePickup={initDatePickup}
                      isEnableGoogleMap={isEnableGoogleMap}
                    />
                  </div>
                  <div id="Step-02" style={this.displayStep(2)}>
                    {showDriverPreference && (
                      <DriverPreferences
                        area={area}
                        selectedVehicleTypeID={selectedVehicleTypeID}
                        refNode={this.driverPreferencesPopup}
                      />
                    )
                    }
                    <ExtraRequirementForFullDay />
                    <MoreGroupExtraServices
                      expanded={false}
                      content={() => (
                        <div className="flex flex-start White-bg Radius-Top-Default pt20 pr15 pb20 pl15" key="dynamic-texts">
                          {!!currentCustomer.current_company_id && <img src={BP_ACCOUNT} alt="Business account" width="35" className="mr10" />}
                          {dynamicTexts && <div className="default-smaller-font default-smaller-font-color italic" dangerouslySetInnerHTML={{ __html: dynamicTexts.show_more_text }} />}
                        </div>
                      )}
                      isBusiness={isBusiness}
                      extraRender={() => [
                        <ContainerSize key="containerSize-options" />,
                        <CODAndPOD key="cod-pod-options" />,
                      ]}
                    >
                      <Badge />
                      <BadgeForCompany />
                      {currentStep === 2 && currentCustomer.id !== 0
                        && ([
                          <Reimbursements key="reimbursement" />,
                          <ReimbursementPopups key="reimbursementPopup" />,
                          <CustomReimbursementPopups key="customReimbursementPopup" />
                        ])
                      }
                      <div className="mt15 pb15">
                        {UtilsHTML.renderOptionTitle(I18n.t('webapp.new_booking.step_2.notes_and_attachments_title'))}
                      </div>
                      <Note />
                      <JobOrderNumber />
                      {currentStep === 2 && <Attachment
                        isLoadedAttachment={this.isLoadedAttachment}
                        setIsLoadedAttachment={(isLoadedAttachment) => { this.isLoadedAttachment.current = isLoadedAttachment }}
                      />}
                    </MoreGroupExtraServices>
                  </div>
                  {currentStep === 3
                    && (
                      <div id="Step-03" style={this.displayStep(3)}>
                        {this.isEnabledTally() && (
                          <TallySummary
                            serviceType={selectedServiceType}
                            vehicleType={selectedVehicleType}
                            timeType={timeType}
                            pickupTime={+booking.pickup_time * 1000}
                            distance={booking.total_distance}
                            transitTime={booking.transit_time}
                            worstTransitTime={booking.worst_transit_time}
                            countryCode={extraInfos.country_code}
                            etaNotesIconVisible={etaNotesIconVisible}
                          />
                        )}
                        <Summary
                          currentCustomer={currentCustomer}
                          isBusiness={isBusiness}
                          refStep3Container={this.refStep3Container}
                          setIsWaitingCalculate={setIsWaitingCalculate}
                          isWaitingCalculate={isWaitingCalculate}
                        />
                        {locations.length && (
                          <LocationContacts
                          showResponsibleForPayment={showResponsiblePayment}
                          currentCustomer={currentCustomer}
                          isBusiness={isBusiness}
                          requireSignaturesComponent={
                            <RequireSignatures
                              fieldId="booking[require_signatures]"
                              fieldName="booking_require_signatures"
                            />
                          }
                        />
                        )}
                        {(!_.isEmpty(currentCustomer.employs)) ? (
                          <SwitchAccount
                            currentCustomer={currentCustomer}
                            subAccountTagPicked={subAccountTagPicked}
                            isCheckedSubAccount={isCheckedSubAccount}
                          />
                        ) : null}

                        {showDatePicker
                          && (
                            <BookingCalendar
                              isShowOkBtn
                              brandIcon={brandIco}
                              selectedDateTime={pickupTime}
                              minMinutes={minMinutes}
                              closePicker={this.closePicker}
                              maximumPickupDays={extraInfos.maximum_pickup_time_days}
                              modalCssClassName="PickupTime-DatePicker-Custom"
                              countryCode={extraInfos.country_code}
                              isShowCancelBtn={false}
                            />
                          )
                        }
                      </div>
                    )
                  }
                </div>
                {this.renderCashBackInfo()}
                {
                  (currentStep !== 3) && (
                    <CashBackBar
                      tallyData={tallyData}
                      tallyBarVisibility={tallyBarVisibility}
                      showTallybar={this.isShowedTallybar()}
                      timeType={timeType}
                      isSingle
                      currentStep={currentStep}
                      isHaveLHAddress={isHaveLHAddress}
                    />
                  )
                }
                <StepButton
                  onRef={(ref) => { this.stepButton = ref }}
                  outsideList={outsideList}
                  errorDistanceList={errorDistanceList}
                  isErrorPickupLocation={isErrorPickupLocation}
                  handleShowChangePickupLocation={this.handleShowChangePickupLocation}
                  extendedList={extendedList}
                  setIsShowChangeTimeType={this.setIsShowChangeTimeType}
                  setShowComingSoonService={this.setShowComingSoonService}
                  setIconComingSoonService={this.setIconComingSoonService}
                  isWaitingSetData={isWaitingSetData}
                  isWaitingCalculate={isWaitingCalculate}
                />
                <CODAndPODPopover area={area} />
                {isShowComingService && (
                  <ServiceTypeComingSoonPopup
                    comingSoonServiceTypeName={comingSoonServiceTypeName}
                    countryCode={extraInfos.country_code}
                    iconComingSoonService={iconComingSoonService}
                    setShowComingSoonService={this.setShowComingSoonService}
                  />
                )}
                <SelectionPerLocationPopover />
                <VehicleTypePopups />
                <ExtraRequirementPopups />
                <OutServiceAreaPopup />
                {
                  isEnabledTally && (
                    <EstimatedTransitTimePopup
                      currentCustomer={currentCustomer}
                      areaId={area.id}
                      timeType={timeType}
                      popupIdPrefix="single-booking"
                      popupData={etaPopupData}
                    />
                  )
                }
                {isShowPickupTimePopup
                  && (
                    <PickupTimePopup
                      setIsShowPickupTimePopup={this.setIsShowPickupTimePopup}
                    />
                  )}
                {autoSwitchLongHaul && showDatePicker && <LongHaulInvalidDatePopup />}
                {isShowChangePopup && (
                  <ChangeTimeTypePopup
                    setIsShowChangeTimeTypeClick={this.setIsShowChangeTimeTypeClick}
                    setIsShowChangeTimeType={this.setIsShowChangeTimeType}
                    handleCancelChangeTimeTypePopup={this.handleCancelChangeTimeTypePopup}
                  />
                )}
                <div id="BookingPopupEye" />
                <div id="CommonPopup" />
                <div id="DefaultPopup" className="DynamicPopupWrapper" />
                <div id="DimensionPopupEye" className="DynamicPopupWrapper" />
                <div
                  id="DimensionPopupDefault"
                  className="DynamicPopupWrapper"
                  ref={this.driverPreferencesPopup}
                />
                {currentPopupID === POPUP_ID_CHANGE_INFO_AFTER_ASSIGNED_DRIVER && (
                  <AssignDriverChangeBookingDetailsPopup
                    assignDriverActions={assignDriverActions}
                  />
                )
                }
                {currentPopupID === POPUP_CASHBACK_EXPLAIN && (
                  <CashBackExplain
                    cashBackPercent={cashBackPercent}
                  />
                )}
              </div>)
            }
            {!isShowFrontPage
              && (
              <Map
                area={area}
                serviceTypes={serviceTypes}
                selectedVehicleTypeID={selectedVehicleTypeID}
                selectedServiceTypeID={selectedServiceTypeID}
                isEnableGoogleMap={isEnableGoogleMap}
              />)}
            {currentPopupID === POPUP_ID_YOUR_RECENT_DRIVERS && (
              <RecentDriversPopup />)
            }
            {/* always show MiniTooltip not depend on setting field extraInfos.turn_on_front_page */}
            <MiniTooltip />
            {
              numberOfDrivers > 0
              && <NumberDrivers numberOfOnlineDrivers={numberOfDrivers} unitNameOfVehicleType={unitName} />
            }
          </div>
          <div id="CebChangeConfirmedPopup" />
        </div>
        <EventNotificationPopup area={area}/>
        {!isShowFrontPage && isEnableGoogleMap && <ShowRoute area={area} extraInfos={extraInfos} />}
        <div id="PopupExistAccount" className="DynamicPopupWrapper" />
      </div>
    )
  }
}

NewBookingContainer.defaultProps = {
  currentPopupID: null,
  bookAgainDetails: null,
  selectedVehicleTypeID: null,
  selectedServiceTypeID: null,
  isNewDriver: false,
  currentCustomer: undefined,
  dynamicTexts: undefined,
  timeType: undefined,
  locations: [],
  booking: {},
  extraServices: {},
  autoSwitchLongHaul: false,
  showDatePicker: false,
  popupContentManagement: [],
  roundTripDiscountActions: {},
  subAccountTagPicked: {},
  isCheckedSubAccount: false,
  isLoginStep3: false,
  isWaitingCalculate: false,
  setIsWaitingCalculate: () => undefined,
}

NewBookingContainer.propTypes = {
  currentStep: PropTypes.number.isRequired,
  serviceTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  extraInfos: PropTypes.shape({
    area_id: PropTypes.number,
    lcl_display_new_badge: PropTypes.bool,
    turn_on_lcl_on_front_page: PropTypes.bool,
  }).isRequired,
  area: PropTypes.shape({}).isRequired,
  extraServiceActions: PropTypes.shape({}).isRequired,
  locationActions: PropTypes.shape({}).isRequired,
  assignDriverActions: PropTypes.shape({}).isRequired,
  authenticationActions: PropTypes.shape({}),
  bookingActions: PropTypes.shape({}).isRequired,
  customerActions: PropTypes.shape({}).isRequired,
  checkLocationActions: PropTypes.shape({}).isRequired,
  isNewDriver: PropTypes.bool,
  currentPopupID: PropTypes.string,
  bookAgainDetails: PropTypes.shape({}),
  selectedVehicleTypeID: PropTypes.number,
  selectedServiceTypeID: PropTypes.number,
  currentCustomer: PropTypes.shape({}),
  dynamicTexts: PropTypes.shape({}),
  timeType: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.object),
  booking: PropTypes.shape({}),
  extraServices: PropTypes.shape({}),
  autoSwitchLongHaul: PropTypes.bool,
  showDatePicker: PropTypes.bool,
  popupActions: PropTypes.shape({}).isRequired,
  popupContentManagement: PropTypes.arrayOf(PropTypes.object),
  documentActions: PropTypes.shape({}).isRequired,
  numberDrivers: PropTypes.shape({}),
  pickupTime: PropTypes.string.isRequired,
  timeTypeActions: PropTypes.shape({}).isRequired,
  vehicleInfos: PropTypes.shape({}),
  roundTripDiscountActions: PropTypes.shape({}),
  subAccountTagPicked: PropTypes.shape({}),
  isCheckedSubAccount: PropTypes.bool,
  serviceTypeActions: PropTypes.shape({}).isRequired,
  isLoginStep3: PropTypes.bool,
  isWaitingCalculate: PropTypes.bool,
  setIsWaitingCalculate: PropTypes.func,
}


export default connect(mapStateToProps, mapDispatchToProps)(withNavigate(NewBookingContainer))
