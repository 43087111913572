import { API_GATEWAY, DELIVEREE_AUTHENTICATION_SERVICE_URL } from 'constants/appConstants'
import apiClient from 'services/axiosApp'
import store from 'store/store'
import CommonUtils from 'utils/common'
import { getAppsFlyerUID, getDeviceId } from 'utils/cookie'

const CustomerAPI = {
  signIn: (params) =>
    apiClient.post(`${DELIVEREE_AUTHENTICATION_SERVICE_URL}/api/v2/customers/sign_in`, {
      country_code: params.countryCode,
      login: params.login,
      password: params.password,
    }),
  switchAccount: (companyId) =>
    apiClient.post(`${DELIVEREE_AUTHENTICATION_SERVICE_URL}/api/v1/customers/switch`, {
      account_id: companyId,
    }),
  resetPassword: (params) =>
    apiClient.post('/api/v3/customers/password', {
      login: params.login,
    }),
  signUp: (params) => apiClient.post('/api/v3/customers/sign_up', params),
  trackDevice: async (deviceToken) => {
    const config = {
      headers: {
        'Fcm-Token': deviceToken,
        'Appsflyer-Uid': getAppsFlyerUID(),
        'App-Name': CommonUtils.appName(),
        'Device-Id': getDeviceId(),
      },
      params: {
        only: ['language'],
      },
    }
    try {
      const { extraInfos } = store.getState()
      const url = extraInfos?.enable_catalog_service
        ? `${API_GATEWAY}/v1/catalog/customers/settings`
        : '/api/v3/settings/customers'
      const res = await apiClient.get(url, config)
      return res
    } catch (err) {
      throw new Error(err)
    }
  },
  getTwilioChatToken: async (deviceID) => {
    const { extraInfos } = store.getState()
    const url = extraInfos?.enable_catalog_service
      ? `${API_GATEWAY}/v1/catalog/customers/me/chat_token`
      : '/api/v3/chat/customers/token'
    return apiClient.get(url, {
      headers: {
        'Device-Id': deviceID,
      },
    })
  },
  loadBookings: async (authenticationToken, params, callback) => {
    try {
      const res = await apiClient.get('/api/v3/customers/me/bookings', { params })
      return callback(res.data)
    } catch (err) {
      throw new Error(err)
    }
  },
  favoriteDriversAmount: async (AuthenticationToken, params, callback) => {
    try {
      const res = await apiClient.get('/api/v3/assign_driver/favorite_driver_amount', { params })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  getAssignableDrivers: async (AuthenticationToken, params, callback) => {
    try {
      const res = await apiClient.get('/api/v3/assign_driver/available_drivers', { params })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  getBlockedTimes: async (params, callback) => {
    try {
      const { extraInfos } = store.getState()
      const url = extraInfos?.enable_catalog_service
        ? `${API_GATEWAY}/v1/catalog/drivers/${params.driver_id}/blocked_times`
        : '/api/v3/assign_driver/blocked_times'
      const res = await apiClient.get(url, { params })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  getBlockedTimesNew: (params) => {
    const { extraInfos } = store.getState()
    const url = extraInfos?.enable_catalog_service
      ? `${API_GATEWAY}/v1/catalog/drivers/${params.driver_id}/blocked_times`
      : '/api/v3/assign_driver/blocked_times'
    return apiClient.get(url, { params })
  },
  searchDriverByReferralCode: async (params, callback) => {
    try {
      const { extraInfos } = store.getState()
      const url = extraInfos?.enable_catalog_service
        ? `${API_GATEWAY}/v1/catalog/drivers/search`
        : '/api/v3/assign_driver/search'
      const res = await apiClient.get(url, { params })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  searchDriverByReferralCodeNew: (params) => apiClient.get('/api/v3/assign_driver/search', { params }),
  validateSearchDriverToAssign: async (AuthenticationToken, driverID, params, callback) => {
    try {
      const res = await apiClient.post(`/api/v3/assign_driver/${driverID}/validate`, params)
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  addFavoriteDriver: async (AuthenticationToken, params) => {
    try {
      const res = await apiClient.put('/api/v3/preference_drivers', params)
      return res
    } catch (err) {
      throw new Error(err)
    }
  },
  addFavoriteDriverNew: (params) => apiClient.put('/api/v3/preference_drivers', params),
  addFavoriteFleetDriver: async (AuthenticationToken, params) => {
    try {
      const res = await apiClient.post('/api/v3/preference_drivers', params)
      return res
    } catch (err) {
      throw new Error(err)
    }
  },
  addFavoriteFleetDriverNew: (params) => {
    const { extraInfos } = store.getState()
    const url = extraInfos?.enable_catalog_service
      ? `${API_GATEWAY}/v1/catalog/customers/me/preference_drivers`
      : '/api/v3/preference_drivers'
    return apiClient.post(url, params)
  },
  verifySmsToken: (params) => apiClient.post(`/api/v3/customers/verify_sms_token?sms_token=${params.sms_token}`),
  resendSmsToken: () => apiClient.post('/api/v3/customers/resend_sms_token'),
  getFavoriteOrBannedDrivers: async (AuthenticationToken, params, callback) => {
    try {
      const res = await apiClient.get('/api/v3/preference_drivers', { params })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  getFavoriteOrBannedDriversNew: (params) => apiClient.get('/api/v3/preference_drivers', { params }),
  recentDrivers: async (params, callback) => {
    const { extraInfos } = store.getState()
    const url = extraInfos?.enable_catalog_service
      ? `${API_GATEWAY}/v1/catalog/customers/me/available_drivers`
      : '/api/v3/preference_drivers/available_drivers'
    try {
      const res = await apiClient.get(url, { params })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  updatePreferenceListDrivers: async (AuthenticationToken, params, callback) => {
    try {
      const res = await apiClient.put('/api/v3/preference_drivers/update_preference_list_drivers', params)
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  updateCustomer: async (params) => apiClient.put('/api/v3/customers/me', params),
  getCustomer: async (AuthenticationToken, params) => {
    const { extraInfos } = store.getState()
    const url = extraInfos?.enable_catalog_service ? `${API_GATEWAY}/v1/catalog/customers/me` : '/api/v3/customers/me'
    try {
      const res = await apiClient.get(url, {
        params,
        headers: {
          ...(AuthenticationToken ? { Authorization: AuthenticationToken } : {}),
        },
      })
      return { ...res?.data?.object, statusApi: res.status }
    } catch (err) {
      throw new Error(err)
    }
  },
  getFirebaseToken: async (AuthenticationToken, params, callback) => {
    try {
      const res = await apiClient.get('/api/v3/customers/get_firebase_token', { params })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  getBookings: async (AuthenticationToken, params, callback) => {
    try {
      const res = await apiClient.get('/api/v3/customers/booking_cards', { params })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  getFrontPageBooking: (params) => apiClient.get('/api/v3/customers/recent_bookings', { params }),
  getFrontPageMainMenu: async (countryCode, areaId) => {
    const { extraInfos } = store.getState()
    const url = extraInfos?.enable_catalog_service ? `${API_GATEWAY}/v1/catalog/areas/front_page` : '/api/v3/front_page'
    try {
      const res = await apiClient.get(url, {
        headers: {
          'Country-Code': countryCode,
          'Area-Id': areaId,
        },
      })
      return res
    } catch (err) {
      throw new Error(err)
    }
  },
  getDraftBookings: async (AuthenticationToken, params, callback) => {
    try {
      const res = await apiClient.get('/api/v3/customers/draft_booking_cards', { params })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  deleteDraftBooking: async (AuthenticationToken, draftID, callback) => {
    try {
      const res = await apiClient.delete(`/api/v3/draft_bookings/${draftID}`, {
        params: { id: draftID },
        headers: { 'Device-Type': 'Web' },
      })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  postJoinCustomerChat: async (bookingID, AuthenticationToken, callback) => {
    try {
      const res = await apiClient.post('/api/v3/chat/customers/member', { booking_id: bookingID })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  getRequirementNotMet: async (driverID, params, callback) => {
    try {
      const { extraInfos } = store.getState()
      const url = extraInfos?.enable_catalog_service
        ? `${API_GATEWAY}/v1/catalog/drivers/${driverID}/check_requirement`
        : `/api/v3/assign_driver/${driverID}/requirement_not_met`
      const res = await apiClient.post(url, params)
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  addMultipleFavoriteDriver: async (AuthenticationToken, params) => {
    try {
      const res = await apiClient.put('/api/v3/preference_drivers', params)
      return res
    } catch (err) {
      throw new Error(err)
    }
  },
  addMultipleFavoriteFleetDriver: async (AuthenticationToken, params) => {
    try {
      const res = await apiClient.post('/api/v3/preference_drivers', params)
      return res
    } catch (err) {
      throw new Error(err)
    }
  },
  socialLogin: (params) => {
    const query = {
      email: params.email,
      access_token: params.access_token,
      provider: params.provider,
    }
    return apiClient.post(`${DELIVEREE_AUTHENTICATION_SERVICE_URL}/api/v2/customers/login_via_social_account`, query)
  },
  getFavoriteAmount: async (params) => {
    const { extraInfos } = store.getState()
    const url = extraInfos?.enable_catalog_service
      ? `${API_GATEWAY}/v1/catalog/customers/me/favorite_driver_amount`
      : `/api/v3/preference_drivers/favorite_driver_amount`
    try {
      const res = await apiClient.get(url, { params })
      return res
    } catch (err) {
      throw new Error(err)
    }
  },
  updateNoteLocation: async (AuthenticationToken, locationID, description) => {
    try {
      await apiClient.put(`/api/v3/locations/${locationID}/note`, description)
    } catch (err) {
      throw new Error(err)
    }
  },
  getCreditAmount: (params) => apiClient.get('/api/v3/credit_accounts/cashback_credit_amount', { params }),
  getTransactions: (params) => apiClient.get('/api/v3/credit_accounts/transactions', { params }),
  getCurrentCustomerProps: (params) => apiClient.get('/api/v3/batches/current_customer_props', { params }),
  getBookingEarliest: async (params, callback) => {
    const { extraInfos } = store.getState()
    const url = extraInfos?.enable_catalog_service
      ? `${API_GATEWAY}/v1/catalog/customers/me/earliest_booking`
      : '/api/v3/customers/me/booking/earliest'
    try {
      const res = await apiClient.get(url, { params })
      return callback(res?.data?.object || {})
    } catch (err) {
      throw new Error(err)
    }
  },
  getCustomerBooking: async (bookingId, params) => {
    const res = await apiClient.get(`/api/v3/customers/bookings/${bookingId}`, {
      params,
    })
    return res?.data?.object
  },
  postCalculateCashbackPercent: (params) => {
    const { extraInfos } = store.getState()
    const url = extraInfos?.enable_catalog_service
      ? `${API_GATEWAY}/v1/catalog/customers/me/cashback_credit_earn`
      : '/api/v3/credit_accounts/cashback_credit_earn'
    return apiClient.post(url, params)
  },
  getHotlineCurrentCustomer: (params = {}) => apiClient.get('/api/v3/freshchat/hotline_info', { params }),
  updateFreshChatCustomer: (param) => apiClient.put('/api/v3/freshchat/customer', param),
  // is_BP and post payment then return casback else return credit
  getCreditBalance: (params) => {
    const { extraInfos } = store.getState()
    const newParams = extraInfos?.enable_catalog_service ? { country_code: extraInfos.country_code } : params
    if (extraInfos?.enable_catalog_service) params = { country_code: extraInfos.country_code }
    const url = extraInfos?.enable_catalog_service
      ? `${API_GATEWAY}/v1/catalog/customers/me/get_wallets`
      : `/api/v3/credit_accounts/get_wallets`

    return apiClient.get(url, { params: newParams })
  },
  getCredits: (params) => apiClient.get('/api/v3/credit_accounts/credit_amounts', { params }),

  getInfoLclBooking: (id) => apiClient.get(`${API_GATEWAY}/v1/lcl/booking/number/${id}`),
  getListBookings: (params) => apiClient.get('/api/v3/customers/me/bookings', { params }),

  // deletion account
  getDeleteMessage: () =>
    apiClient.get(`/api/v3/customers/me/delete_message`, {
      headers: {
        'Appsflyer-Uid': getAppsFlyerUID(),
        'App-Name': CommonUtils.appName(),
        'Device-Id': getDeviceId(),
      },
    }),
  getDeleteReason: () => apiClient.get(`/api/v3/customers/me/delete_reason_list`),
  /**
   * @param
    - delete_reason_ids (Array)
    - other_reason : String
    - password : String
  */
  deleteAccount: (params) =>
    apiClient.delete(`/api/v3/customers/me/delete_account`, {
      params,
      headers: {
        'Appsflyer-Uid': getAppsFlyerUID(),
        'App-Name': CommonUtils.appName(),
        'Device-Id': getDeviceId(),
      },
    }),
  submitRating: (params) => apiClient.put(`/bookings/customer_rate_driver_booking`, '', { params }),
  checkEventApprovedCompany: () => {
    const { extraInfos } = store.getState()
    const url = extraInfos?.enable_catalog_service
      ? `${API_GATEWAY}/v1/catalog/customers/check_company_approved`
      : '/api/v3/customer_events/check_company_approved'
    return apiClient.get(url)
  },
  cleanEventApprovedCompany: () => apiClient.put('/api/v3/customer_events/clean_company_approved'),
}

export default CustomerAPI
