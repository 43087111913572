import common from './common'
import paths from 'routers/paths'
import { LCL_CUSTOMER_URL } from 'constants/appConstants'
import { isNewBooking } from './booking/common'
import * as utilsCommon from 'utils/booking/common'
import CustomerAPI from 'api/customers'
import { accountManageActions } from 'store/toolkit/accountManage/accountManage.reducer'
import { SIGN_IN_MODAL } from 'components/new_booking/guest_flow/constants'
import jwt_decode from 'jwt-decode'
import store from 'store/store'
import { IDecodeToken, IUserInfo } from 'interface/IAccountManage'
import { currentCustomerActionsCreator } from 'store/toolkit/currentCustomer/currentCustomer.reducer'

export const initApp = {
  checkToRedirectToLCLTracking: (currentCustomer: IUserInfo, accessToken: string) => {
    if (!accessToken) return
    const lclBookingId = utilsCommon.getParamFromURL('spil_number')
    if (!lclBookingId) return
    const getLclInfo = async () => {
      const {
        data: { data },
      } = await CustomerAPI.getInfoLclBooking(lclBookingId)
      window.location.href = `${LCL_CUSTOMER_URL}/tracking/${data.id}`
    }
    const decodeToken = jwt_decode<IDecodeToken>(accessToken)
    const companyIdFromToken = +decodeToken?.company_id || 0
    const companyIdNow = currentCustomer.currentCompanyId || 0

    if (!currentCustomer.id) {
      store.dispatch(accountManageActions.updateModalAccountManage(SIGN_IN_MODAL))
    } else if (currentCustomer.id && companyIdFromToken === companyIdNow) {
      getLclInfo()
    }
  },
  checkParamLCL: () => {
    const urlParams = new URLSearchParams(window.location.search)
    const isLcl = urlParams.get('lcl')

    if (isLcl && isNewBooking) {
      window.location.replace(`${LCL_CUSTOMER_URL}`)
    }
  },
  updateCompanyFromToken: (currentCompanyId?: number) => {
    store.dispatch(currentCustomerActionsCreator.updateCustomer({ currentCompanyId: currentCompanyId }))
  },
  isShowHeaderAndChat: () => {
    const isSharePage = common.isShare()
    const isFollowPage = common.isFollow()
    const isSelectAreaPage = window.location.pathname === paths.SELECT_AREA
    return !isSharePage && !isFollowPage && !isSelectAreaPage
  },
}
