import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import { connect } from 'react-redux'

// UTILS
import firebaseInit from 'utils/firebaseInit'
import * as BookingHelpers from 'utils/ez_spread_sheet/common'
import { Utils } from 'utils/Utils'
import I18n from 'i18n/i18n'
import $ from 'jquery';

// CONTANSTS
import {
  BATCH_STATUS_CONFIRMED,
  BATCH_STATUS_UNCONFIRMED,
  BATCH_STATUS_ARRANGING,
  BATCH_STATUS_PARTIAL,
  BATCH_STATUS_PARTIALLY_CONFIRMED,
  BATCH_STATUS_COMPLETED,
  BATCH_STATUS_CANCELED,
  BATCH_STATUS_DRAFT,
} from 'constants/ezSpreadSheetConstants'

// ASSETS
import {
  ICON_WARNING_DEVINA,
  ICON_DONE_DEVINA,
  ICON_BATCH_NEED_CONFIRM,
} from 'constants/imageConstants'
// COMPONENTS
import CountDown from './CountDown'

function mapStateToProps(state) {
  return {
    currentCustomer: state.currentCustomer,
    isBooking: state.isBooking,
    batchesShow: state.batchesShow
  }
}

class BatchMenu extends React.Component {
  static goToBatchDetails(batch, isBooking = false) {
    let url = `/batches/${batch.id}`
    if (BookingHelpers.checkBatchNeedConfirm(batch)) {
      url += '?show_reimburse=true'
    }

    if (isBooking) {
      window.open(url)
    } else {
      $('#loading-modal').addClass('visible')
      window.location = url
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      batches: [],
    }
    this.isClickRetryAction = false
    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {
    this.listenFirebase()
  }

  componentWillUnmount() {
    const { firebaseClient } = this.state
    if (firebaseClient) {
      firebaseClient.off()
    }
  }

  async listenFirebase() {
    const { currentCustomer } = this.props
    const firebaseChannel = (currentCustomer.currentCompanyId > 0) ? `batch_status/companies/${currentCustomer.currentCompanyId}` : `batch_status/customers/${currentCustomer.id}`
    await firebaseInit.load()
    const firebaseClient = firebaseInit.listen(firebaseChannel)
    this.setState({ firebaseClient })
    firebaseClient.on('child_changed', (data) => {
      this.updateBatchesStatus(data)
    })
    firebaseClient.on('child_added', (data) => {
      this.updateBatchesStatus(data)
    })
    const currentClass = this
    firebaseClient.orderByChild('created_at').on('value', (snapshot) => {
      const newBatches = []
      snapshot.forEach((childSnapshot) => {
        if (childSnapshot.val() !== null) {
          const completedStatus = [
            BATCH_STATUS_CANCELED,
            BATCH_STATUS_DRAFT
          ]
          const childData = childSnapshot.val()
          if (!_.isEmpty(childData) && !_.includes(completedStatus, childData.status)
            && currentClass.showShowBatch(childData)) {
            newBatches.push(childData)
          }
        }
      })
      this.setState({ batches: newBatches.reverse() })
      currentClass.checkLocalStorage(newBatches[0])
    })
  }

  updateBatchesStatus(data) {
    const firebaseBatch = data.val()
    const { batches } = this.state
    const batchIndex = batches.findIndex(batch => batch.id === firebaseBatch.id)
    if (batchIndex !== -1 && this.showShowBatch(firebaseBatch)) {
      const newList = [...batches]
      newList[batchIndex].status = firebaseBatch.status
      newList[batchIndex].arranging_timeout_at = firebaseBatch.arranging_timeout_at
      this.setState({ batches: newList })
      this.addLocalStorage(newList[batchIndex])
      const batchLabel = document.getElementById(`Batch-Label-${firebaseBatch.id}`)
      let firebaseBatchStatus = firebaseBatch.status
      if (firebaseBatchStatus === BATCH_STATUS_PARTIALLY_CONFIRMED) {
        firebaseBatchStatus = BATCH_STATUS_PARTIAL
      }
      if (batchLabel) {
        batchLabel.innerText = _.startCase(I18n.t(`batches.status.${firebaseBatchStatus}`))
        batchLabel.className = `Batch-Status ${BookingHelpers.BADGE_COLOR_MAP[firebaseBatchStatus]}`
      }
    }
  }

  showShowBatch(batch) {
    const { batchesShow } = this.props
    const daysToShow = moment().subtract(batchesShow, 'd')
    const createdAt = batch.created_at || 0
    const batchCreatedAt = moment(createdAt, 'YYYY-MM-DDTHH:mm:ss.SSSSZ')
    return _.isUndefined(batchesShow) || batchesShow === 0 || batchCreatedAt > daysToShow
  }

  showConfirmed() {
    const { batches } = this.state
    const latestBatch = batches[0]
    if (latestBatch.status !== BATCH_STATUS_CONFIRMED) {
      return true
    }
    return moment().add(24, 'hours').unix() >= latestBatch.soonest_pickup_time
  }

  addLocalStorage(batches) {
    if (!batches) return
    this.isClickRetryAction = true
    Utils.addLocalStorage(batches, BATCH_STATUS_PARTIALLY_CONFIRMED, BATCH_STATUS_UNCONFIRMED)
  }

  handleClick() {
    const {
      batches,
    } = this.state
    const batch = batches[0]
    this.isClickRetryAction = false
    Promise.resolve(
      localStorage.setItem('batch_status', batch.status),
      localStorage.setItem('batch_id', batch.id),
      localStorage.setItem('batch_retry', false)
    ).then(() => {
      this.checkLocalStorage(batch)
    })
  }

  checkLocalStorage(batches) {
    if (!batches || this.isClickRetryAction) return
    Utils.checkLocalStorage(batches, BATCH_STATUS_PARTIALLY_CONFIRMED, BATCH_STATUS_UNCONFIRMED)
  }

  static renderCountDown(batch) {
    switch (batch.status) {
      case BATCH_STATUS_ARRANGING: {
        return (
          <div className="Dropdown-Devina-Time">
            <CountDown eventTime={batch.arranging_timeout_at || 0} />
          </div>
        )
      }
      case BATCH_STATUS_CONFIRMED: {
        return (
          <div className="Dropdown-Devina-Time">
            <img src={ICON_DONE_DEVINA} alt="" />
          </div>
        )
      }
      case BATCH_STATUS_PARTIALLY_CONFIRMED:
      case BATCH_STATUS_UNCONFIRMED: {
        return (
          <div className="Dropdown-Devina-Time">
            <img src={ICON_WARNING_DEVINA} alt="" />
          </div>
        )
      }
      case BATCH_STATUS_COMPLETED: {
        let iconBatchStatusCompleted
        if (batch.need_confirmation === true) {
          iconBatchStatusCompleted = ICON_BATCH_NEED_CONFIRM
        } else {
          iconBatchStatusCompleted = ICON_DONE_DEVINA
        }
        return (
          <div className="Dropdown-Devina-Time">
            <img src={iconBatchStatusCompleted} alt="" />
          </div>
        )
      }
      default:
        return (
          <div />
        )
    }
  }

  static renderStatus(batch) {
    if (BookingHelpers.checkBatchNeedConfirm(batch)) {
      return (
        <span>
          {I18n.t('batches.status.need_confirm_reimburse')}
        </span>
      )
    }
    return (
      <span>
        {I18n.t(`batches.status.${batch.status}`)}
      </span>
    )
  }

  render() {
    const { isBooking } = this.props
    const { batches } = this.state

    if (_.isEmpty(batches)) {
      return (<div />)
    }
    return (
      this.showConfirmed()
      && (
        <div className="Dropdown Dropdown-Batch">
          <div className="Dropdown-Close" />
          <div className="Dropdown-Head" onClick={this.handleClick}>
            <div className="TitleSubtitle">
              <div className="larger-font">
                {I18n.t('batches.label.batch_alert')}
              </div>
            </div>
            <div className="Dropdown-Head-Caret">
              <i className="xb material-icons Icon">
                arrow_drop_down
              </i>
            </div>
          </div>
          <div className="right top Dropdown-Menu">
            {batches.map(batch => (
              <div className="Dropdown-Menu-Item" key={batch.id} onClick={() => BatchMenu.goToBatchDetails(batch, isBooking)} ref={`Batch${batch.id}`}>
                <div className="Dropdown-Devina-Group">
                  <b>
                    {`${batch.name} #${batch.id}`}
                  </b>
                  {BatchMenu.renderStatus(batch)}
                </div>
                {BatchMenu.renderCountDown(batch)}
              </div>
            ))
            }
          </div>
        </div>
      )
    )
  }
}

BatchMenu.propTypes = {
  currentCustomer: PropTypes.shape({}).isRequired,
  batchesShow: PropTypes.number,
  isBooking: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
}

BatchMenu.defaultProps = {
  isBooking: undefined,
  batchesShow: 0
}

export default connect(mapStateToProps, null)(BatchMenu)
