import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

// ASSETS
import {
  BUSINESS_DEFAULT_LOGO,
  IMAGE_PLACE_HOLDER
} from 'constants/imageConstants'
import _ from 'lodash'
import { isEmpty } from 'lodash-es'
import { showLoading } from 'assets/javascripts/webapp-v2/common'
import accountAction from 'store/actions/common/accountAction'
import updateCurrentPopupID from 'store/actions/common/currentPopupIDActionCreators'
import { POPUP_CHANGE_CONFIRM } from 'constants/common/popupConstants'
import updateSwitchAccount from 'store/actions/common/switchAccountActionCreators'
import CommonUtils from 'utils/common'
import ReloadModal from 'view/AccountManagementView/ReloadModal'

const isActiveItem = (accountID, userInfo) => (userInfo.currentCompanyId && +userInfo.currentCompanyId === +accountID)
  || (!userInfo.currentCompanyId && +userInfo.id === +accountID)

const getId = (accountID, userInfo) => {
  if (userInfo.currentCompanyId) {
    return {
      oldId: `${userInfo.id}_${userInfo.currentCompanyId}`,
      newId: userInfo.id === accountID ? accountID : `${userInfo.id}_${accountID}`
    }
  }
  return {
    oldId: userInfo.id,
    newId: `${userInfo.id}_${accountID}`
  }
}

const handleAfterSwitchAccount = async (accountID, userInfo) => {
    const { oldId, newId } = getId(accountID, userInfo)
    window.Moengage.track_event('Switch Account', {
      'From Account': oldId,
      'To Account': newId,
    })
    localStorage.setItem('switch_account', newId)
    await CommonUtils.moengageDestroySession()
    window.location.href = '/'
}

const BusinessAccountsList = ({ userInfo }) => {
  const dispatch = useDispatch()
  const [selectedAccount, setSelectedAccount] = useState({})
  const { employs: BpAccounts } = userInfo
  const accountsList = [
    {
      ...userInfo,
      logo_url: userInfo.logo_url || userInfo.avatar_square,
    },
    ..._.map(BpAccounts, acc => ({
      company_id: acc.company_id,
      description: _.get(acc, 'company.description', ''),
      email: acc.invited_email,
      id: acc.company_id,
      logo_url: _.get(acc, 'company.logo_url', ''),
      name: _.get(acc, 'company.name', ''),
      phone: _.get(acc, 'company.phone', ''),
      photo_url: _.get(acc, 'company.photo_url', ''),
    })),
  ]

  if (_.isEmpty(accountsList)) return null

  const handleSwitchAccount = async (event, account) => {
    try {
      event?.preventDefault()
      const { isMultiple, isBatchEZ, isSmartLoad } = CommonUtils
      if (isMultiple() || isBatchEZ() || isSmartLoad())  {
        dispatch(updateSwitchAccount(account))
        dispatch(updateCurrentPopupID(POPUP_CHANGE_CONFIRM))
        return
      }
      showLoading()
      const newCurrentCustomer = await accountAction.switchAccount(account.id)(dispatch)
      if (!isEmpty(newCurrentCustomer)) {
        if (!isMultiple() && !isBatchEZ() && !isSmartLoad()) {
          handleAfterSwitchAccount(account.id, userInfo)
        }
      }
    } catch (err) {
      console.debug(err)
    }
  }

  const handleSetSelectedAccount = (event, account) => {
    const { isMultiple, isBatchEZ, isSmartLoad } = CommonUtils
    if (isMultiple() || isBatchEZ() || isSmartLoad()) handleSwitchAccount(event, account)
    else setSelectedAccount(account)
  }

  return (
    <div>
      {
        _.map(accountsList, (account) => (
          <div
            className={`Box-List-Item change-account expression_link_choose
            ${isActiveItem(account.id, userInfo) ? 'active' : ''}`}
            key={account.id}
            onClick={(event) => handleSetSelectedAccount(event, account)}
          >
            <div className="rounded Avatar__header">
              <img
                src={account.company_id ? account.photo_url : account.avatar_square}
                onError={(e) => {
                  e.target.src = account.company_id ? BUSINESS_DEFAULT_LOGO : IMAGE_PLACE_HOLDER
                }}
                alt="avatar_customer"
              />
            </div>
            <div className="TitleSubtitle">
              <div className="TitleSubtitle-title">
                {account.name}
              </div>
              <div className="TitleSubtitle-subtitle">
                {account.email}
              </div>
            </div>
          </div>
        ))
      }
      {!isEmpty(selectedAccount) && <ReloadModal confirmSwitchAccount={() => handleSwitchAccount(null, selectedAccount)} />}
    </div>
  )
}

BusinessAccountsList.propTypes = {
  userInfo: PropTypes.shape({}).isRequired
}

export default BusinessAccountsList
