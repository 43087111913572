import { API_GATEWAY } from 'constants/appConstants'
import StorageKeys from 'constants/storage-keys'
import apiClient from 'services/axiosApp'
import store from 'store/store'

const PopupAPI = {
  getAreaTypePopup: async (authenticationToken, params, callback) => {
    try {
      const { extraInfos } = store.getState()
      const areaIdLocal = window.localStorage.getItem(StorageKeys.AREA_ID)
      const url = extraInfos?.enable_catalog_service
        ? `${API_GATEWAY}/v1/catalog/areas/${areaIdLocal}/popups`
        : '/api/v3/popups/area'
      const res = await apiClient.get(url, {
        params,
        headers: {
          'Device-Type': 'Web',
        },
      })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  getAreaTypePopupNew: async (params) => {
    const { extraInfos } = store.getState()
    const areaIdLocal = window.localStorage.getItem(StorageKeys.AREA_ID)
    const url = extraInfos?.enable_catalog_service
      ? `${API_GATEWAY}/v1/catalog/areas/${areaIdLocal}/popups`
      : '/api/v3/popups/area'
    return apiClient.get(url, {
      params,
      headers: {
        'Device-Type': 'Web',
      },
    })
  },
  getVehicleTypePopup: async (authenticationToken, params, callback) => {
    try {
      const res = await apiClient.get('/api/v3/popups/vehicle_type', {
        params,
        headers: {
          'Device-Type': 'Web',
        },
      })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  getServiceTypePopup: async (authenticationToken, params, callback) => {
    try {
      const res = await apiClient.get('/api/v3/popups/service_type', {
        params,
        headers: {
          'Device-Type': 'Web',
        },
      })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
}

export default PopupAPI
